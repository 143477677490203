//COMMON TRANSLATE
export const LOGIN = ["Login", "تسجيل الدخول "]
export const LOGIN_ZYDA_SUBTITLE = [
  "Enjoy managing your store from one place.",
  "استمتع بإدارة متجرك من مكان واحد.",
]
export const LOGIN_ZYDA = ["Login to your store", "تسجيل الدخول إلى متجرك"]
export const REBRANDING_TITLE = [
  "Process orders smoothly. Update your products quickly. Create vouchers and launch campaigns easily.",
  "سلاسة تنفيذ الطلبات . سرعة تحديث المنتجات . سهولة إطلاق الحملات و إنشاء قسائم الشراء",
]
export const SELECT_YOUR_STORE = ['Select your store', 'اختر متجرك'];
export const CHOOSE_YOUR_STORE_FOR_FACEBOOK = [
  'Pick which of your stores you want to connect to Facebook',
  'اختر أي من متاجرك التي تريد ربطها بالفيسبوك',
];
export const FACEBOOB_UNINSTALLED = ['Facebook is now uninstalled', 'Facebook is now uninstalled'];
export const SWITCH_STORE = ['Switch Store', 'بدل المتجر'];
export const NO_STORES_MATCHING = ['No matching stores','لا توجد متاجر مطابقة']
export const SWITCH_STORE_DESCRIPTION = ["You're now logged in to", 'أنت الآن مسجل الدخول إلى'];
export const PLACEHOLDER_SEARCH_STORES = ['Search using store name', 'ابحث باستخدام اسم المتجر'];
export const BACK_TO_LOGIN = ["Back to login", "العودة لتسجيل الدخول"]
export const EMAIL_ADDRESS = ["Email Address", "البريد الإلكتروني"]
export const YOUR_EMAIL_ADDRESS = [
  "Your Email Address",
  "عنوان بريدك الإلكتروني",
]
export const PASSWORD = ["Password", "كلمة السر"]
export const ENTER_PASSWORD = ["Enter your password", "ادخل كلمة السر"]
export const PASSWORD_CONFIRMATION = [
  "Password Confirmation",
  "تأكيد كلمة السر",
]
export const FORGET_PASSWORD = ["Forgot password?", "نسيت كلمة السر؟"]
export const NOT_MEMBER = ["Don’t have an account?", "ليس لديك حساب؟"]
export const REGISTER_NOW = ["Register Now", "سجّل الآن"]
export const REGISTER_ZYDA = ["Register in Zyda", "التسجيل في زيـدا"]
export const FIRST_NAME = ["First Name", "الاسم الأول"]
export const LAST_NAME = ["Last Name", "اسم العائلة"]

export const ALREADY_HAVE_ACCOUNT = [
  "Already have an account?",
  "لديك حساب بالفعل؟",
]
export const CREATE_NEW_ACCOUNT = ["Create a new account.", "إنشاء حساب جديد."]
export const THIS_EMAIL_NOT_REGISTERED = [
  "This email is not registered! Please try again.",
  "هذا البريد الإلكتروني غير مسجل! يرجى المحاولة مرة أخرى .",
]
export const RESET_TOKEN_HAS_EXPIRED = [
  "The password reset token has expired! Please create a new password reset request.",
  "انتهت صلاحية رمز إعادة تعيين كلمة المرور! يرجى إنشاء طلب جديد لإعادة تعيين كلمة المرور.",
];
export const RETURN_TO_RESET_PASSWORD = [
  "Return to Reset Password",
  "العودة إلى إعادة تعيين كلمة المرور",
];
export const THIS_EMAIL_IS_REGISTERED = [
  "This email is already registered. Try to Login or",
  "هذا البريد الإلكتروني مسجل بالفعل. حاول تسجيل الدخول أو",
]
export const YOUR_EMAIL_PASS_INCORRECT = [
  "Your email or password is incorrect. Please try to login again or",
  "البريد الإلكتروني أو كلمة السر غير صحيحة. برجاء محاولة الدخول مرة أخرى أو",
]
export const RESET_YOUR_PASSWORD = [
  "Reset your password",
  "تغيير كلمة السر الخاصة بك",
]

export const RESET_PASSWORD = ["Change Password", "تغيير كلمة السر"]
export const PLEASE_ENTER_NEW_PASSWORD = [
  "Enter your new password for your Zyda account.",
  "برجاء إدخال كلمة السر الجديدة",
]

export const REGISTER = ["Register", "تسجيل"]
export const NEW_PASSWORD = ["Your New password", "كلمة السر الجديدة"]
export const REPEAT_NEW_PASSWORD = [
  "Confirm New Password",
  "تأكيد كلمة السر الجديدة",
]

export const CHANGE_PASSWORD_SUCESS = [
  "Password changed successfully",
  "تم تغيير كلمة المرور بنجاح",
]

export const OLD_PASSWORD_INCORRECT = [
  "Old password is incorrect",
  "كلمة المرور القديمة غير صحيحة",
]

export const ENTER_THE_EMAIL = [
  "Enter your email address and We’ll send a password reset link.",
  "أدخل عنوان بريدك الإلكتروني وسنرسل لك إرشادات تغيير كلمة السر.",
]
export const FOR_SECURITY = [
  "For security reasons, we do not store your password. So rest assured that we will never send your password via email.",
  "أدخل عنوان بريدك الإلكتروني وسنرسل رابط إعادة تعيين كلمة السر.",
]

export const LEARN_MORE_HERE = ['Learn more here', 'تعلم المزيد هنا'];

export const SEND_RESET_INSTRUCTIONS = [
  "Reset password",
  "إعادة تعيين كلمة السر",
]

export const FIRST_NAME_REQUIRED = [
  "First name is required",
  "الاسم الأول مطلوب",
]
export const LAST_NAME_REQUIRED = ["Last name is required", "اسم العائلة مطلوب"]
export const LAST_NAME_MINIMUM = [
  "Must contain at least 3 characters",
  "يجب أن يكون اسم العائلة من ٣ أحرف على الأقل",
]
export const FIRST_NAME_MINIMUM = [
  "Must contain at least 3 characters",
  "يجب أن يكون الاسم الأول من ٣ أحرف على الأقل",
]
export const DESCRIPTION_REQUIRED = ["Description is required", "الوصف مطلوب"]
export const INVALID_EMAIL_FORMAT = [
  "Invalid email format",
  "البريد الإلكتروني غير صحيح",
]
export const EMAIL_IS_REQ = ["Email is required", "البريد الإلكتروني مطلوب"]
export const PASSWORD_8_CHARACTERS = [
  "Password must contain at least 8 characters",
  "يجب أن تكون كلمة السر من 8 أحرف على الأقل",
]
export const PASSWORD_MIN_CHARACTERS = [
  "Password must contain at least 3 characters",
  "يجب أن تكون كلمة السر من 3 أحرف على الأقل",
]
export const PASSWORD_MAX_CHARACTERS = [
  "Password must contain at last 15 characters",
  "يجب أن تكون كلمة السر من 15 أحرف على الاكتر",
]
export const PASSWORD_REQUIRED = ["Password is required", "كلمة السر مطلوبة"]
export const PASSWORD_MINIMUM_8_DIGITS = [
  "Password should be minimum 8 digits length",
  "يجب أن تكون كلمة السر مكونة من 8 أرقام على الأقل",
]
export const PASSWORDS_MUST_MATCH = [
  "Passwords must match",
  "يجب أن تتطابق كلمات السر",
]
export const PASSWORD_CONF_REQUIRED = [
  "Password Confirmation is required",
  "تأكيد كلمة السر مطلوب",
]

export const TITLE_EN_IS_REQ = [
  "English title is required",
  "أدخل الاسم باللغة الإنجليزية",
]

export const ADDRESS_IN_ENGLISH_IS_REQ = [
  "Address in english is required",
  "العنوان باللغة الإنجليزية مطلوب",
]

export const ADDRESS_IN_ARABIC_IS_REQ = [
  "Address in arabic is required",
  "العنوان باللغة العربية مطلوب",
]

export const BUILDING_NUMBER_IS_REQ = [
  "Building number is required",
  "رقم المبنى مطلوب",
]

export const STREET_NAME_IS_REQ = [
  "Street name is required",
  "اسم الشارع مطلوب"
]

export const CITY_IS_REQ = [
  "City is required",
  "المدينة مطلوبة"
]

export const TITLE_AR_IS_REQ = [
  "Arabic title is required",
  "أدخل الاسم باللغة العربية",
]

export const BUSINESS_LOCATIONS_IS_REQ = [
  "Business Locations is required",
  "مطلوب اختيار فروع",
]

export const ENABLE_DELIVERY_ON_PRIVATE_BRANCH = [
  "Delivery must be enabled in non public location",
  "يجب بتفعيل التسليم في مكان غير عام",
]

export const LOCATION_IS_REQ = ["Location is required", "مطلوب اختيار موقع"]
export const PLEASE_SELECT = ["Please select", "يرجى الإختيار"]

export const SIGN_OUT = ["Log out", "خروج"]

export const STORE_HAS_NO_BRANCHES = [
  "Store has no branches",
  "المتجر ليس له فروع",
]
export const CURRENT_STORE = ["Current store", "المتجر الحالي"]
export const SWITCH_OTHER_STORES = [
  "Switch to other stores",
  "الذهاب إلى المتاجر الأخرى",
]
export const HOME_PAGE = ["Home page", "الصفحة الرئيسية"]
export const KWD = ["KWD", "د.ك"]
export const FILS = ["Fils", "فلس"]
export const EGP = ["EGP", "ج.م"]
export const ENABLE = ["Enable", "تفعيل"]
export const DISABLE = ["Disable", "تعطيل"]
export const SAVE_CHANGE = ["Save Changes", "حفظ التغييرات"]
export const ENGLISH = ["English", "إنجليزي"]
export const ARABIC = ["Arabic", "عربي"]
export const IMAGE = ["Image", "صورة"]
export const COVER_IMAGE = ["Category Image", "صورة الغلاف"]

export const TITLE = ["Title", "العنوان"]
export const CANCEL = ["Cancel", "إلغاء"]
export const CLEAR = ["Clear", "إلغاء"]

export const SKIP = ["Skip", "تخطي"]
export const CONFIRM = ["Confirm", "تأكيد"]
export const SET_AS_BUSY = ["Set as busy", "تغيير الحالة إلى غير متاح"]
export const SET_BRANCH_BUSY = name => [`Set ${name} branch as busy`, `تعيين فرع ${name} على أنه مشغول`]
export const DISMISS = ["Dismiss", "رفض"]
export const ADD = ["Add", "إضافة"]
export const EDIT = ["Edit", "تعديل"]
export const DELETE = ["Delete", "حذف"]
export const REQUIRED = ["Required", "مطلوب"]
export const OPTIONAL = ["Optional", "اختياري"]
export const MINUTES = ["Mins", "دقائق"]
export const HOURS = ["Hrs", "ساعات"]
export const ENABLED = ["Enabled", "مفعّل"]
export const DISABLED = ["Disabled", "معطّل"]
export const CREATE = ["Create", "إنشاء"]
export const LOCATIONS = ["Locations", "فروع"]
export const BACK = ["Back", "رجوع"]
export const NEXT = ["Next", "التاليه"]
export const FINISH = ["Finish", "انهاء"]
export const APPLY_FILTERS = ["Apply", "عرض النتائج"]
export const ORDERS = ["Orders", "طلبات"]
export const FILTER_BY = ["Filter by", "تصنيف بواسطة"]
export const LAUNCH_CAMPAIGN = ['Launch Campaign', 'إطلاق الحملة']
export const BLOCKED_CAMPAIGN_NOTE = ['SMS Campaigns are under maintenance. It will be back shortly.', 'حملات الرسائل القصيرة تحت الصيانة. وستعود قريبا.']

//ORDERS

export const EXPORTـALL_FILTERED_ORDERS = ["Filtered Orders", "الطلبات المصنفة"]
export const EXPORT_ALL_ORDERS = ["All Orders", "جميع الطلبات"]
export const EXPORT_PENDING_ORDERS = [
  "All Pending orders",
  "كل الطلبات المعلّقة",
]
export const EXPORT_ACCEPTED_ORDERS = [
  "All Accepted orders",
  "كل الطلبات التى تم قبولها",
]
export const EXPORT_READY_ORDERS = ["All Ready orders", "كل الطلبات الجاهزة"]
export const EXPORT_DISPATCHED_ORDERS = [
  "All Dispatched orders",
  "كل الطلبات التى تم إرسالها",
]
export const EXPORT_COMPLETED_ORDERS = [
  "All Completed orders",
  "كل الطلبات مكتملة",
]
export const EXPORT_CANCELED_ORDERS = [
  "All Canceled orders",
  "كل الطلبات الملغية",
]
export const EXPORT_WAITING_FOR_PAYMENT_ORDERS = [
  "All Waiting for payment orders",
  "كل الطلبات المنتظر دفعها",
]
export const EXPORT_PAYMENT_FAILED_ORDERS = [
  "All Payment attempts orders",
  "كل الطلبات ذات محاولات الدفع ",
]
export const EXPORT_PUSH_FAILED_ORDERS = [
  "All Push failed orders",
  "كل الطلبات التي فشل توصيلها",
]
export const EXPORT_ORDERS = ["Export Orders", "إصدار تقرير الطلبات"]
export const INCLUDE_DETAILS = [
  "Include the products' details in the exported sheet.",
  "تضمين تفاصيل المنتجات في التقرير المصدر.",
]
export const CANT_INCLUDE_DETAILS_TOOLTIP = [
  'This option is not used for "All" and "Completed" orders.',
  '."لا يتم استخدام هذا الخيار لطلبات "الكل" و "المكتملة',
]
export const CANT_EXPORT_FILTERED = [
  "This option is used only if you have applied filters.",
  ".يستخدم هذا الخيار فقط إذا قمت بتطبيق عوامل التصفية",
]
export const NEW_ORDER = ["New order!", "طلب جديد!"]
export const SET_AVAILABILITY = [
  "Edit availability for branches",
  "تعيين وقت الإتاحة",
]
export const EDIT_AVALIABILITY = [
  "Edit availability",
  "تعيين وقت الإتاحة",
]
export const BRANCHES_BUSY = number => [
  `${number} branches set as busy`,
  `تم تعيين الفروع ${number} على أنها مشغولة`,
]
export const BRANCH_BUSY = number => [
  `${number} branch set as busy`,
  `تم تعيين الفرع ${number} على أنه مشغول`,
]
export const BRANCH_AVAILABILITY = branchName => [
  `${branchName?.titleEn} availability`,
  `إتاحة ${branchName?.titleAr}`,
]
export const SET_AS_AVAILABLE = ["Set as Available", "تغيير الحالة إلى متاح"]
export const REMOVE = ["Remove", "حذف"]
export const STORE = ["Store", "المتجر"]
export const THE_DELIVERY = ["Delivery", "التوصيل"]
export const THE_PICKUP = ["Pickup", "الإستلام"]
export const IS_BUSY_UNTIL = ["is busy until", "غير متاح حتى"]
export const WILL_SET_TO_BUSY = ["will be set to busy on", "غير متاح من"]
export const IS_BUSY = ["is busy", "غير متاح"]
export const BUSY = ["BUSY", "غير متاح"]
export const EDIT_BUSY_MODE = ["Edit busy mode", "تعديل وضع الإتاحة"]
export const PHONE = ["Phone", "الهاتف"]
export const ORDER_NUMBER = ["Order number", "رقم الطلب"]
export const CREATION_TIME = ["Creation time", "وقت الطلب"]
export const SCHEDULED_TIME = ["Scheduled time", "مدة الطلب المسبّق"]
export const TOTAL = ["Total", "الإجمالي"]
export const REFUND = ["Refund", "إرجاع"]
export const NOT_NOW = ["Not Now", "ليس الأن"]
export const LAST_90_DAYS = ["Last 90 days", "آخر 90 يوماً"]
export const LAST_30_DAYS = ["Last 30 days", "آخر 30 يوماً"]
export const LAST_24_DAYS = ["Last 24 days", "آخر 24 يوماً"]
export const LAST_24_HOURS = ["Last 24 hours", "آخر 24 ساعة"]
export const LAST_7_DAYS = ["Last 7 days", "آخر 7 أيام"]
export const ALL_ORDERS = ["All", "كل الطلبات"]
export const ALL_TIME = ["All time", "كل الأوقات"]
export const ALL_BRANCHES = ["All", "كل الفروع"]
export const ALL_BRANCHES_TEXT = ["All Branches", "كل الفروع"]
export const PENDING_ORDERS = ["Pending", "معلّق"]
export const SUBMITTED_ORDERS = ["Submitted", "تم"]
export const ACCEPTED_ORDERS = ["Accepted", "تم قبول"]
export const READY_ORDERS = ["Ready", "تم تجهيز"]
export const DISPATCHED_ORDERS = ["Dispatched", "تم إرسال"]
export const PICKEDUP_ORDERS = ["Picked Up", "تم استلام"]
export const DELIVERED_ORDERS = ["Delivered", "تم توصيل"]
export const PUSHED_ORDERS = ["Pushed", "تم ارسال"]
export const FULFILLED_ORDERS = ["Fulfilled", "تم استكمال"]
export const COMPLETED_ORDERS = ["Completed", "طلبات مكتملة"]
export const CANCELED_ORDERS = ["Canceled", "تم الغاء"]
export const DECLINED_ORDERS = ["Declined", "تم رفض"]
export const CURRENT_ORDERS = ["Current Orders", "الطلبات الحالية"]
export const PAST_ORDERS = ["Past Orders", "الطلبات السابقة"]
export const REASSIGN = ['Reassign', 'اعادة التعيين']
export const READY_FOR_PICKUP = ['Ready for pickup', 'جاهز للإستلام']
export const DRIVER_ACCEPTED = riderName => [`Pending pickup by ${riderName}`, `في انتظار الاستلام من قبل ${riderName}`]
export const COURIER_EN_ROUTE_PICKUP = courierName => [`${courierName} en route to pickup`, `${courierName} في طريقه للاستلام`]
export const PENDING_ACCEPTANCE_BY = courierName => [`Pending Acceptance by ${courierName}`, `قيد التأكيد من قبل ${courierName}`]
export const COURIER_DECLINED_REQUEST = courierName => [`Request declined by ${courierName}`, `${courierName} رفض الطلب`]
export const REFUNDED_ORDERS = ["Refunded", "تم استرداد المبلغ"]
export const OUT_FOR_DELIVERY = courierName =>  [`${courierName} Out for delivery`, `${courierName} خارج للتوصيل`]
export const WAITING_FOR_PAYMENT_ORDERS = [
  "Waiting for payment",
  "بانتظار الدفع",
]
export const UNPAID_ORDER = ['UNPAID', 'غير مدفوع'] 
export const PAYMENT_FAILED_ORDERS = ["Payment attempts", "محاولات دفع"]
export const PAYMENT_FAILED_ORDER = ["Payment attempt", "محاولة دفع"]
export const PUSH_FAILED_ORDERS = ["Push failed", "فشل توصيل"]
export const PAYMENT_PAID_ORDERS = ["Paid", "تم الدفع"]
export const WILL_BE_ASSIGNED = ["Will be assigned at", "سيتم التعيين"]
export const ORDER_IS_READY = number => [
  `Order #${number} is ready`,
  `الطلب #${number} جاهز`,
]
export const ORDER_IS_PENDING = number => [
  `Order #${number} is pending`,
  `الطلب #${number} قيد التنفيذ`,
]
export const ORDER_IS_SUBMITTED = number => [
  `Order #${number} is submitted`,
  `الطلب #${number} تم الطلب`,
]
export const ORDER_IS_ACCEPTED = number => [
  `Order #${number} is accepted`,
  `الطلب #${number} مقبول`,
]
export const ORDER_ACCEPTED_WILL_BE_PUSHED_AT = (number, firingTime) => [
  `Order #${number} is accepted and scheduled to be pushed at ${firingTime}`,
  `قبول الطلب رقم ${number} و سيتم إرساله للتوصيل ${firingTime}`,
]
export const ORDER_IS_PUSHED = number => [
  `Order #${number} is pushed`,
  `الطلب #${number} تم إرساله`,
]
export const ORDER_IS_FAILED_TO_PUSH = (number, courierName) => [
  `Order #${number} has failed to be pushed to ${courierName} for delivery, please go to your dashboard and push it manually from orders.`,
  `فشلت محاولة إرسال الطلب #${number} الطلب ل${courierName} للتوصيل. يرجى إعادة الإرسال يدوياً من تفاصيل الطلب`,
]
export const ORDER_IS_DISPATCHED = number => [
  `Order #${number} is dispatched`,
  `الطلب #${number} تم إرساله`,
]
export const ORDER_IS_FULFILLED = number => [
  `Order #${number} is fulfilled`,
  `#${number} تم استكمال الطلب`,
]
export const ORDER_IS_PICKEDUP = number => [
  `Order #${number} is picked up`,
  `الطلب #${number} تم استلامه`,
]
export const ORDER_IS_DELIVERED = number => [
  `Order #${number} is delivered`,
  `الطلب #${number} تم توصيله`,
]
export const ORDER_IS_CANCELED = number => [
  `Order #${number} is canceled`,
  `الطلب #${number} تم إلغائه`,
]
export const ORDER_IS_REFUNDED = number => [
  `Order #${number} is refunded`,
  `الطلب #${number} تم استرداده`,
]
export const REFUND_STATMENT = ["Refunds take 3 to 15 days to appear on your customer's statement.","يستغرق استرداد الأموال من 3 إلى 15 يوم عمل" ]
export const REFUND_TO_BOTH_WALLET_AND_CREDIT_CARD = ["Refund the amount for each method as is.", "رد المبلغ لكل طريقة كما هي."];
export const BENEFIT = ["BENEFIT", "فائدة"]
export const CASH = ["CASH", "كاش"]
export const CAPITAL_CASH = ["Cash", "كاش"]
export const COD = ["COD", "بطاقة عند الاستلام"]
export const KNET_NO_DASH = ["KNET", "كي نت"]
export const CARDONDELIVERY = ["Card on Delivery", "بطاقة عند الاستلام"]
export const CREDIT_CARD = ["Credit Card", "بطاقة الائتمان"]
export const CREDITCARD = ["Credit Card", "بطاقة الائتمان"]
export const MORE_DETAILS = ["More Details", "تفاصيل اكتر"]
export const DETAILS = ['Details', 'تفاصيل'];
export const REVIEW = ['Review', 'إعادة النظر'];
export const RECIPIENTS = ['Recipients', 'المستلمون'];
export const MADA = ["MADA", "مدى"]
export const MEEZA = ["MEEZA", "ميزة"]
export const MASTERCARD = ["MASTERCARD", "ماستر كارد"]
export const ONLINE = ["ONLINE", "اون لاين"]
export const APPLE_PAY_ANALYTICS = ["Apple Pay", "Apple Pay"]

export const ARE_YOU_SURE_TO_CANCEL = [
  "Are you sure you want to cancel this order",
  "هل أنت متأكد أنك تريد إلغاء هذا الطلب",
]
export const PROCESSING_TIME = ["Processing time", "زمن تنفيذ العملية"]
export const KNET = ["KNET", "كي-نت"]
export const APPLE_PAY = ["APPLE PAY", "APPLE PAY"]
export const VISA = ["Visa", "فيزا"]
export const MASTER_CARD = ["Mastercard", "ماستركارد"]
export const BUSINESS_DAYS_2_5 = ["2-5 business days", "من 2 إلى 5 أيام عمل"]
export const BUSINESS_DAYS_3_15 = ["3-15 business days", "من 3 إلى 15 يوم عمل"]
export const CONFIRM_REFUND = ["Confirm Refund", "تأكيد الاسترداد"]
export const REFUND_PAYMENT = ["Refund payment", "الاسترداد القيمة"]
export const REFUND_FULL = number =>[`Refund the full amount (${number})`, `استرداد لقيمة (${number})`]

export const EXPORT_MESSAGE = [
  "We are now generating your orders sales report. You will receive it by email shortly.",
  "نقوم الآن بإصدار تقرير مبيعات طلباتك. سيتم إرسال التقرير إلى بريدك الإلكتروني خلال لحظات",
]

export const SET_AS_BUSY_FOR = [
  "Set as busy for",
  "تغيير الحالة إلى غير متاح لمدة",
]

export const SCHEDULE_AS_BUSY_FOR = [
  "Set as busy for specific date & time",
  "تغيير الحالة إلى غير متاح ليوم و وقت محدد",
]

export const BUSY_UNTIL = dateTime => [
  `is busy until ${dateTime}`,
  `غير متاح حتى ال${dateTime}`,
]

export const BUSY_FROM_TO = dateTimeRange => [
  `is busy from ${dateTimeRange[0]} to ${dateTimeRange[1]}`,
  `غير متاح من ال${dateTimeRange[0]} إلى ال${dateTimeRange[1]}`,
]

export const DELIVERY_IS_SET = name => [
  `${name} business location delivery is set as busy, and it will not receive new delivery orders!`,
  `تم تغيير ${name} حالة التوصيل إلى غير متاح و لن تستقبل أي طلبات توصيل جديدة`,
]

export const PICKUP_IS_SET = name => [
  `${name} business location pickup is set as busy, and it will not receive new pickup orders!`,
  `تم تغيير ${name} حالة الإستلام إلى غير متاح و لن تستقبل أي طلبات إستلام جديدة`,
]

export const STORE_IS_SET = name => [
  `${name} business location is set as busy, and it will not receive new orders!`,
  `تم تغيير ${name}حالة الفرع إلى غير متاح و لن تستقبل أي طلبات جديدة`,
]

export const totalAwaitingBalance = [
  "Total Awaiting Balance",
  "إجمالي الرصيد المنتظر",
]
export const totalDepositedAmount = [
  "Total Deposited Amount",
  "إجمالي المبلغ المودع",
]
export const totalSupplierInvoiceShare = [
  "Total Invoice Share",
  "إجمالي حصة الفاتورة",
]
export const totalNumberOfTransactions = [
  "Total Number Of Transactions",
  "إجمالي عدد المعاملات",
]

export const MAX_TIME = [
  "Maximum time is 48 hours",
  "الحد الأقصى للوقت هو 48 ساعة",
]
export const TODAY = ["Today", "اليوم"]
export const YESTERDAY = ["Yesterday", "الأمس"]
export const NO_ORDER = ["No", "رقم الطلب"]
export const TIME_ORDER = ["Time", "وقت الطلب"]
export const PHONE_NUMBER_ORDER = ["Phone Number", "رقم الهاتف"]
export const PAYMENT_ORDER = ["Payment", "طريقة الدفع"]
export const PAYMENT_NOT_AVAILABLE = [
  "Payment methods are not available",
  "لا يوجد طرق دفع",
]
export const PAYMENT_TYPE_ORDER = ["Payment Type", "نوع طريقة الدفع"]
export const TOTAL_ORDER = ["Total", "مجموع الطلب"]
export const STATUS_ORDER = ["Status", "الحالة"]
export const SCHEDULED = ["Scheduled", "طلب مسبّق"]
export const INSTRUCTIONS = ["Instructions :", "تعليمات :"]
export const SUBTOTAL = ["Subtotal", "الإجمالي"]
export const SUBTOTAL_AFTER_VOUCHER = [
  "Subtotal after coupon",
  "الإجمالي بعد قسيمة خصم الكوبون",
]
export const DISCOUNT = ["Discount", "خصم"]
export const COUPON_DISCOUNT = ["Coupon Discount", "خصم الكوبون"]

export const REFUND_INFO = ["Refund Info", "بيانات الاسترداد"]
export const CHANGE_TO = ['Change to', 'تغيير الى']
export const CALL_SUPPORT = ["Call Support:", "الاتصال بالدعم:"]
export const PAYMENT_REFERENCE = ["Payment reference", "الرقم المرجعي للعملية"]
export const INITIATED_AT = ["Initiated at", "وقت العملية"]
export const REFUNDS_WILL_TAKE = [
  "Mastercard refunds take 3-15 business days.",
  "يستغرق استرداد الأموال من ماستركارد من 3 إلى 15 يوم عمل.",
]
export const PRINT = ["Print", "طباعة"]
export const PRINT_ORDER = ["Print Order", "طباعة الطلب"]
export const DELIVERY_INFO = ["Delivery info", "بيانات التوصيل"]
export const DELIVERY_NOTE = ["Delivery notes", "ملاحظات عند التوصيل"]
export const DELIVERY_PROVIDER = ["Delivery Provider", "مقدم خدمة التوصيل"]
export const DELIVER_WITH = ["Deliver With", "التوصيل عن طريق"]
export const SELECT_RIDER = ["-- Select Rider", "-- اختر سائق"]
export const MASHKOR = ["Mashkor", "مشكور"]
export const OTHER = ["Other", "آخر"]
export const VALIDATING_DELIVERY_COURIER = [
  "Validating delivery courier...",
  "جاري التحقق من مقدم خدمة التوصيل...",
]
export const UNABLE_TO_VERIFY_COURIER = [
  "Sorry, we are unable to verify delivery courier",
  "نأسف، لم نتمكن من التحقق من مقدم خدمة التوصيل",
]
export const COURIER_FAIL = [
  "Sorry, courier do not currently deliver to this area",
  "نأسف، لا يقوم مقدم الخدمة بالتوصيل لهذه المنطقة",
]
export const COURIER_CHANGE_DISCLAIMER = [
  "Handling delivery cost differences is your responsibility.",
  "التعامل مع الفرق في تكلفة خدمة التوصيل هو مسؤوليتكم الخاصة.",
]
export const REQUEST_ID = ["Request ID", "رقم الطلب"]
export const SEARCHING_FOR_A_DRIVER = [
  "Searching for a driver",
  "البحث عن سائق",
]
export const NO_DRIVER_AVAILABLE = [
  "No driver available.",
  "لا يوجد سائق متاح.",
]
export const TRACK_RIDER = ["Track Rider", "تتبع السائق"]
export const ASSIGNED_TO_DRIVER = ["Assigned to driver.", "تم اختيار سائق"]
export const DRIVER_NAME = ["Driver Name", "اسم السائق"]
export const ARRIVED = ["Arrived", "وصل"]
export const INVOICE = ["Invoice", "فاتورة"]
export const INVOICES = ["Invoices", "الفواتير"];
export const DATE = ["Date", "التاريخ"]
export const ORDER = ["Order", "رقم الطلب"]
export const BRANCH = ["Branch", "الفرع"]
export const RIDER = ["Rider", "السائق"]
export const WHEN = ["When", "الوقت"]
export const PICKUP_TIME = ["Pickup time", "موعد الاستلام"]
export const NAME = ["Name", "الاسم"]
export const MOBILE = ["Mobile", "الهاتف"]
export const EXPECTED_AT = ["Expected At", "الوقت المقدر"]
export const AREA = ["Area", "المنطقة"]
export const CITY = ["City", "المدينة"]
export const ALL_CITIES = ["All Cities", "كل المدن"]

export const CITY_GOVERNORATE = ["City/Governorate", "المدينة / المحافظة"]

export const AREAS_NOT_AVAILABLE = ["Areas are not available", "لا يوجد مناطق"]
export const NOTES = ["Notes", "ملاحظات"]
export const QTY = ["Qty.", "الكمية"]
export const ITEM = ["Item", "المنتج"]

export const CANCEL_ORDER = ["Cancel Order", "إلغاء الطلب"]
export const CANCELLATION_REASON = ["Cancellation reason:", "سبب الإلغاء:"]
export const ENTER_CANCELLATION_REASON = [
  "Please enter your cancellation reason",
  "برجاء إدخال سبب الإلغاء",
]
export const CANCELLATION_FEES = ['Cancellation fees', 'رسوم الإلغاء'];
export const CANCELLATION_FEES_MESSAGE = (couriers) => [
  `If you assigned ${couriers.courierToBeAssigned} you might be charged a cancellation fee from ${couriers.courierToBeCancelled}.`,
  `If you assigned ${couriers.courierToBeAssigned} you might be charged a cancellation fee from ${couriers.courierToBeCancelled}.`
];

export const CUSTOMER_CANCELLED = ['Customer cancelled','العميل الغى الطلب']
export const ITEM_UNAVAILABLE = ['item(s) unavailable','لا يوجدالمنتج']
export const UNREACHABLE = ['Customer unreachable/unavailable at address','لم يتم التوصل للعميل']
export const OUT_OF_DELIVERY = ['Out of delivery zone','خارج نطاق التوصيل']
export const NOT_ACCEPTING_ORDERS = ['Restaurant not accepting orders','لا نستقبل طلبات']
export const NOT_DELIVERED = ['Order was not delivered','لم يصل الطلب']
export const PAYMENT_ISSUES = ['Payment issues','مشاكل دفع']
export const NOT_ARRIVE = ["Driver didn't arrive",'السائق لم يصل']
export const REASON = ["Reason", "السبب"]
export const CONFIRM_CANCELLATION = ["Confirm Cancellation", "تأكيد الإلغاء"]
export const ACCEPT_ORDER = ["Accept Order", "قبول الطلب"]
export const ACCEPT_ONLY_ORDER = ["Accept Only", "قبول الطلب فقط"]
export const PUSH_ORDER = ["Push Order", "إرسال الطلب"]
export const ACCEPT_PUSH_ORDER = ["Accept & Push Order", "قبول وإرسال الطلب"]
export const ACCEPT_AND_ASSIGN = ["Accept & Assign", "قبول وتعيين"]
export const UNASSIGN = ["Unassign", "الغاء تعيين السائق"]
export const DELIVER_NOW = ["Deliver now", "توصيل الآن"]
export const SELECT = ["Select", "اختر السائق"]
export const RIDER_INFO = ['Rider info', 'معلومات السائق']
export const COURIER_INFO = ['Courier info', 'معلومات مقدم الخدمة']
export const SELECTED = ["Selected", "السائق مختار"]
export const SELECTED_COURIER = ["Selected", "مختار"]

export const FULFILL_ORDER = ["Fulfill Order", "اكمل الطلب"]
export const DISPATCH_ORDER = ["Dispatch Order", "إرسال الطلب"]
export const DELIVER_ORDER = ["Deliver Order", "تسليم الطلب"]
export const READY_ORDER = ["Ready", "الطلب جاهز"]
export const REFUND_ORDER = ["Refund Order", "استرداد قيمة الطلب"]
export const DELIVERY_ADDRESS = ["Delivery Address", "عنوان التسليم"]
export const DELIVERY_ADDRESSES = ["Delivery Addresses", "عناوين التسليم"]
export const ADDRESS_COPIED = ["Address copied", "تم نسخ العنوان"]
export const GET_DIRECTIONS = ["Get Directions", "وصف الطريق"]
export const COPY_ADDRESS = ["Copy Address", "نسخ العنوان"]
export const BUILDING_NUMBER = ["Building number", "رقم المبنى"]
export const STREET_NAME = ["Street name", "اسم الشارع"]
export const NUMBER_COPIED = ["Number copied", "تم نسخ الرقم"]
export const COPY_NUMBER = ["Copy Number", "نسخ الرقم"]
export const BLOCK = ["Block", "قطعة"]
export const AVENUE = ["Avenue", "جادّة"]
export const STREET = ["Street", "شارع"]
export const BUILDING = ["Building", "مبنى"]
export const FLOOR = ["Floor", "طابق"]
export const UNIT = ["Unit", "رقم الوحدة"]
export const UNIT_TYPE = ["Unit type", "نوع الوحدة"]
export const HOUSE = ["House", "منزل"]
export const APARTMENT = ["Apartment", "شقة"]
export const OFFICE = ["Office", "مقر عمل"]
export const SEND_VIA_WHATS_APP = ["Send via WhatsApp", "أرسل عبر الواتساب"]
export const VIEW_MAP = ["View Map", "عرض الخريطة"]
export const VIEW_ON_MAP = ["View on map", "عرض على الخريطة"]
export const CONTACT_INFORMATION = ["Contact Information", "معلومات الاتصال"]
export const UMBRELLA_DETAILS = ["Umbrella Details", "تفاصيل الشمسية"]
export const UMBRELLA_NUMBER = ["Umbrella No: ", "رقم الشمسية: "]
export const UMBRELLA_INSTRUCTION = ["Direction Instructions: ", "ارشادات الطريق: "]

export const CAR_INFO = ["Car Information", "وصف السيارة"]
export const CAR_MODEL = ["Car Model", "نوع السيارة"]
export const CAR_COLOR = ["Car Color", "لون السيارة"]
export const CAR_LICENSE = ["Car License", "رقم لوحة السيارة"]

export const ORDER_TIMELINE = ["Order Timeline", "حالة الطلب"]
export const ACTION_BY = (name) => [
  `By: ${name}`,
  `بقبل: ${name}`,
]

export const TIMELINE_ORDER_TYPE = ['Order', 'الطلب'];
export const TIMELINE_DELIVERY_TYPE = ['Request', 'طلب التوصيل'];
export const ASSIGNED_TO = ['Assigned to', 'تم تعيين']

export const NO_EMAIL_PROVIDED = ["No Email provided", "لا يوجد بريد إلكتروني"]
export const READY_FOR_ORDERS = ["Ready For Orders!", "جاهز للطلبات"]
export const RECEIVE_ORDERS = ["As soon as you receive an order, you'll see it here", "بمجرد أن تتلقى طلبًا ، ستراه هنا"]
export const ORDER_WILL_BE_MOVED = ['Please note any order that wasn\'t completed will move to the Past Orders tab automatically after 3 hours from the Expected Delivery Time','يرجى ملاحظة أن أي طلب لم يكتمل سينتقل إلى الطلبات السابقة تلقائيًا بعد 3 ساعات من وقت التسليم المتوقع']
export const NO_RESULTS = ["No Results Found!", "لا توجد نتائج"]
export const COULDNT_FIND_ORDER = [
  "We couldn’t find orders for your search, Please recheck it or try different words.",
  "لم نعثر على نتائج ضمن الطلبات، برجاء التأكد من صحة البيانات أو تجربة كلمات مختلفة.",
]
export const RECIPIENT_INFORMATION = [
  "Recipient Information",
  "معلومات المرسل إليه",
]
export const GIFT_NOTE = ["Gift Note", "رسالة للمرسل إليه"]
export const RECIPIENT_NAME = ["Recipient Name", "اسم المرسل إليه"]
export const RECIPIENT_PHONE = ["Recipient Phone", "هاتف المرسل إليه"]

export const REPORTS = ["Reports", "التقارير"]
//REPORTS
export const SALES = ["Sales", "المبيعات"]
export const EXPORT = ["Export", "إصدار تقرير"]
export const OVERVIEW = ["Overview", "نظرة عامة"]
export const HIGHERTHAN = ["Higher than last month", "أعلى من الشهر الماضي"]
export const LOWERTHAN = ["Lower than last month", "أقل من الشهر الماضي"]
export const NO_ORDERS = ["No. of Orders", "عدد الطلبات"]
export const AVG_ORDER = ["Avg. Order Size", "متوسط حجم الطلب"]
export const REPEAT_OREDERS = ["Repeat Orders", "طلبات مكررة"]
export const FILTER = ["Filter", "تصنيف"]
export const ORDER_AMOUNT = ["Order Amount", "قيمة الطلب"]
export const SHOWING = ["Showing", "عرض"]
export const OF = ["Of", "من"]
export const RESULTS = ["Results", "نتائج"]

export const CUSTOMERS = ["Customers", "العملاء"]
export const CUSTOMER_NAME = ["Customer Name", "اسم العميل"]
export const TOTAL_ORDERS = ["Total No. of Orders", "مجموع عدد الطلبات"]
export const TOTAL_CASHBACK = ["Total Customer's Balance", "إجمالي رصيد العملاء"]
export const DELIVERY_ORDERS = ["Delivery Orders", "طلبات توصيل"]
export const PICKUP_ORDERS = ["Pickup Orders", "طلبات استلام"]
export const TOTAL_VALUE = ["Total Value", "المبلغ الإجمالي"]
export const LAST_ORDER_DATE = ["Last Order Date", "تاريخ آخر طلب"]
export const FIRST_ORDER_DARE = ["First Order Date", "تاريخ أول طلب"]
export const SORT_BY = ["Sort By", "ترتيب حسب"]

export const SETUP = ["Setup", "الإعدادات"]

//BUSINESS INFORMATION
export const BUSINESS_INFORMATION = [
  "Business Information",
  "بيانات النشاط التجاري",
]
export const SETUP_BUSINESS_INFORMATION = [
  "Setup › Business Information",
  "الإعدادات > بيانات النشاط التجاري",
]
export const STORE_INFO = ["Store Info", "معلومات المتجر"]
export const STORE_TYPE = ["Store Type", "نوع المتجر"]
export const YOUR_STORE_INDUSTRY = [
  "Your store's industry",
  "مجال النشاط التجاري",
]
export const SELECT_YOUR_STORE_TYPE = [
  "Select your store's type",
  "اختيار نوع المتجر",
]
export const COUNTRY = ["Country", "الدولة"]
export const SELECT_COUNTRY = ["Select Country", "اختيار الدولة"]
export const PICK_THECOUNTRY_STORE = [
  "Pick the country your store operates in",
  "اختر الدولة التي يعمل فيها متجرك",
]
export const TAX = ["VAT", "الضريبة"]
export const TAXES = taxPercentage => [`VAT (${taxPercentage}%)`, `(٪ضريبة القيمة المضافة (${taxPercentage}`]
export const TAX_ARE_INCLUDED = taxPercentage => [`VAT (${taxPercentage}%) is inclusive`, `الاسعار تشمل (${taxPercentage}٪) ضريبة القيمة المضافة`]
export const VERD_FEES = ['Verd Fees', 'مصاريف خدمة ڤيرد'];
export const DYMA_FEES = ['Dyma Fees', ' مصاريف خدمة تطبيق فييست'];
export const ENGAGE_FEES = ['Engage Fees', 'مصاريف خدمة انجيچ'];
export const ENTER_TAX = ["Enter VAT here", "ادخل الضريبة هنا"]
export const TAX_EXCLUSIVE = ["Your menu prices are set without taxes", "أسعار منتجاتك ليست شاملة للضرائب"]
export const TAX_INCLUSIVE = ["Your menu prices already set with taxes included", "أسعار منتجاتك شاملة للضرائب"]
export const TAX_DESCRIPTION = [
  "Add VAT percentage to be applied on orders.",
  "اضافة الضريبة التي تضاف الي الاوردر",
]
export const ADD_TAX = ["Add VAT", "اضافة الضريبة"]
export const LANGUAGE = ["Language", "اللغة"]
export const CHOOSE_STORE_COUNTER = [
  "Pick the store's operating country.",
  "اختر البلد الاساسية لمتجرك",
]
export const NATIONAL_ID_NUMBER = ["National ID number", "رقم البطاقة المدنية"]
export const UPLOAD_NATIONAL_ID = [
  "Upload National ID",
  "رفع صورة البطاقة المدنية",
]
export const ENTER_VALID_ID_NUMBER = [
  "Enter your valid ID number",
  "ادخل رقم البطاقة المدنية",
]
export const COPY = ['Copy', 'انسخ'];
export const COPY_LINK = ['Copy Link', 'انسخ الرابط'];
export const COPIED = ["Copied", "تم النسخ"]
export const PASTE_YOUR_CODE_HERE = ['Paste your code here', 'الصق الكود الخاص بك هنا'];
export const FRONT_COPY = ["Front Copy", "صورة الوجه"]
export const BACK_COPY = ["Back Copy", "صورة الخلفية"]
export const LEGAL_INFO = ["Legal Info", "المعلومات القانونية"]
export const INTEGRATION_INFO = ["Integration Info", "معلومات الحساب"]
export const LICENSED_BUSINESS = ["Licensed Business", "متجر مرخّص"]
export const ENABLE_LICENCE = [
  "Enable it if your store operations is formally licensed.",
  "قم بتفعيل هذا الخيار إذا كان متجرك مرخصاً بشكل رسمي.",
]
export const COPY_VOUCHER_CODE = ['Copy voucher code', 'نسخ رمز القسيمة'];
export const VOUCHER_CODE_COPIED = ['Copied. You can share this link to automatically apply the voucher', 'تم النسخ. يمكنك مشاركة هذا الرابط مع عملائك لتطبيق القسيمة تلقائيًا'];

export const PRODUCT_TITLE_EN_REQ = [
  "English item title is required",
  "اسم المنتج باللغة الإنجليزية مطلوب",
]
export const PRODUCT_TITLE_AR_REQ = [
  "Arabic item title is required",
  "اسم المنتج باللغة العربية مطلوب",
]
export const PRODUCT_CATEGORIES_REQ = [
  "Product categories are required",
  "أقسام المنتج مطلوب",
]
export const PRODUCT_PRICE_REQ = [
  "Product price is required",
  "سعر المنتج مطلوب",
]
export const PRODUCT_BRANCHES_REQ = [
  "Product branches are required",
  "موقع النشاط التجاري مطلوب",
]
export const STORE_COUNTRY = ["Store country is required", "بلد المتجر مطلوب"]
export const STORE_NAME_REQ = ["Store name is required", "اسم المتجر مطلوب"]
export const WRONG_EMAIL_FORMAT = [
  "Wrong email format",
  "البريد الإلكتروني غير صحيح",
]
export const INVALID_PHONE = ["Invalid phone format", "رقم الهاتف غير صحيح"]
export const INVALID_NATIONAL_NUM = [
  "Invalid national Should be only numbers",
  "رقم البطاقة المدنية يجب أن يتكون من أرقام ",
]
export const STORE_INFORMATION_UPDATED = [
  "Store information updated successfully",
  "تم تحديث بيانات النشاط التجاري بنجاح",
]
export const ALL_CATEGORIES = ["All Categories", "جميع الاقسام"]
export const CATEGORIES = ["Categories", "الاقسام"]
export const CATEGORIES_COUNT = count => [
  `Categories (${count})`,
  `الاقسام (${count})`,
]
export const SEARCH_CATEGORIES = ["Search for categories", "ابحث عن الاقسام"]
export const CATEGORY_CREATED = name => [
  `${name} Category created successfully`,
  `تم إضافة القسم ${name} بنجاح`,
]
export const CATEGORY_UPDATED = name => [
  `${name} Category updated successfully`,
  `تم تحديث بيانات القسم ${name} بنجاح`,
]
export const DELET_CATEGORY = ["Delete Category", "حذف القسم"]

export const ARE_YOU_SURE_TO_DELETE_GATEGORY = [
  "Are you sure you want to delete this category?",
  "هل أنت متأكد أنك تريد حذف هذا القسم؟",
]
export const DELETE_GATEGORY_NOTE = [
  "Note: Deleting this category will cause all sales data for this category to disappear from the reports. Try to unpublish the category instead.",
  "ملحوظة: حذف هذا القسم سوف يؤدي إلي إختفائه من التقارير، تستطيع إخفاءه بدلاً من الحذف.",
]
export const CATEGORY_DELETED = name => [
  `${name} category deleted successfully`,
  `تم حذف قسم ${name} بنجاح`,
]

export const PRODUCT_DELETED = name => [
  `${name} product deleted successfully`,
  `تم حذف منتج ${name} بنجاح`,
]

export const SOMETHING_WENT_WRONG = ["Something went wrong!", "!هناك مشكلة ما"]
export const SOMETHING_WENT_WRONG_DESC = [
  "We’re trying to fix this problem, it might take a few seconds",
  "نعمل حالياً على حل هذه المشكلة، قد يستغرق الأمر بضع لحظات",
]
export const UNAUTHORIZED_PAGE = ["Unauthorized Page ", "صفحة غير مصرح بها"]
export const UNAUTHORIZED_PAGE_DESC = [
  "Sorry, You aren’t authorized to access this page",
  "عذرًا، غير مصرح لك بالدخول إلى هذه الصفحة",
]
export const GO_TO_HOMEPAGE = ["Go to Homepage", "الذهاب إلى الصفحة الرئيسية"]
export const COMPANY_NAME = ["Company name", "اسم الشركة"]
export const COMPANY_NAME_EN = ["Company name in English", "اسم الشركة باللغة الإنجليزية"]
export const COMPANY_NAME_AR = ["Company name in Arabic", "اسم الشركة باللغة العربية"]
export const ENTER_YOUR_OFFICIAL_EN = [
  "Enter your official company name in English",
  "أدخل الاسم الرسمي لشركتك باللغة الإنجليزية",
]
export const ENTER_YOUR_OFFICIAL_AR = [
  "Enter your official company name in Arabic",
  "أدخل الاسم الرسمي لشركتك باللغة العربية",
]
export const EX_341 = ["Ex: 3414314ertrteg342t", "مثال: 3414314ertrteg342t"]
export const EX_444 = ["Ex: 444233-332131", "مثال: 444233-332131"]
export const LICENCE_COPY = ["License copy", "صورة من الرخصة التجارية"]
export const VAT_ID = ["VAT ID", "رقم حساب ضريبة القيمة المضافة"]
export const VAT_ID_NUMBER = ["VAT ID Number", "رقم حساب ضريبة القيمة المضافة"]
export const LICENCE_NUM = ["License number", "رقم الرخصة التجارية"]
export const AUTHORIZED_SIGNATORY = [
  "Authorized Signatory",
  "التوقيع البنكي المعتمد",
]
export const PHONE_IS_REQ = ["Phone Number is required", "رقم الهاتف مطلوب"]
export const NUMBER_TYPE = ["Should be a number", "يجب أن يتكون من أرقام"]
export const JOB_TYPE_IS_REQ = ["Job Type is required", "نوع الوظيفة مطلوب"];
export const LANGUAGE_IS_REQ = ["Language is required", "اللغة  مطلوبة"];
export const ARABIC_NAME_MISSING = [
  "Arabic name is missing",
  "الاسم باللغة العربية مطلوب",
]

//BRANDING
export const BRANDING = ["Branding", "العلامة التجارية"]
export const SETUP_BRANDING_SETUP = [
  "Setup › Branding",
  "الإعدادات > العلامة التجارية",
]
export const ESSENTIAL_DETAILS = ["Essential Details", "التفاصيل الأساسية"]

export const STORE_NAME_LANG = [
  "Your store's name in Arabic & English",
  "اسم متجرك باللغة العربية واللغة الإنجليزية",
]
export const ADD_IMAGE = ["Add Image", "إضافة صورة"]
export const EDIT_IMAGE = ["Change Image", "تغيير الصورة"]
export const DESC_UPLOAD_YOUR_LOGO = [
  "Upload your store’s logo",
  "ارفع شعار متجرك",
]
export const STORE_NAME = ["Store name", "اسم المتجر"]
export const DESC_ENGLISH_STORE_NAME = [
  "Your store’s name in English",
  "اسم متجرك باللغة الإنجليزية",
]
export const DESC_ARABIC_STORE_NAME = [
  "Your store’s name in Arabic",
  "اسم متجرك باللغة العربية",
]
export const ADD_PAGE_TITLE = ["+ Add page title", "إضافة عنوان الصفحة +"]
export const PAGE_TITLE = ["Page title", "عنوان الصفحة"]
export const ADD_SLOGAN = ["+ Add slogan", "إضافة شعار مكتوب +"]
export const SLOGAN = ["Slogan", "الشعار المكتوب"]
export const STORE_THEME = ["Store theme", "تصميم المتجر"]
export const HIDE_STORE_NAME = [
  "Hide store’s name from your customers.",
  "إخفاء اسم متجرك عن زوار الموقع.",
]
export const ADD_COVER = ["+ Add cover", "إضافة غلاف +"]
export const PRIMARY_COLOR = ["Primary color", "اللون الأساسي"]
export const PICK_PRIMARY_COLOR = [
  "Pick the primary color of your store",
  "اختر اللون الأساسي لمتجرك",
]
export const HEADER_COLOR = ["Header color", "لون الصفحة الرئيسية"]
export const HEADER_COVER = ["Header cover", "غلاف الصفحة الرئيسية"]
export const UPLOAD_HEADER_COVER = [
  "Upload your store’s header cover",
  "ارفع صورة غلاف الصفحة الرئيسية",
]
export const UPLOAD_DRAG = [
  "or drop files to upload",
  "أو اسحب الملفات هنا لرفعها",
]
export const FAILED_UPLOAD = ["File has failed to upload", "فشل تحميل الملف"]
export const ARE_YOU_SURE = ["Are you sure?", "هل أنت متأكد؟"]
export const DISCLAMER = [
  "All the changes won't be saved",
  "لن يتم حفظ جميع التغييرات",
]

// MENU
export const MENU = ["Menu", "القائمة"]
export const GENERAL = ["Info", "المعلومات"]
export const MODIFIERS = ["Modifiers", "التعاريف"]
export const SELECT_ITEM = [
  "Select an item to edit details",
  "اختار عنصرًا لتغيير التفاصيل",
]
export const DAILY_CAPACITY = ["Daily Capacity", "الاستيعاب اليومي"]
export const REMAINING_TODAY = ["Remaining today", "متبقي اليوم"]
export const SEARCH_VARIANTS = (numberOfVariants = 0) => [
  `Search ${numberOfVariants} Variants`,
  `بحث ${numberOfVariants} متغيرات`,
]

export const TRACKING_STOCK = ["Tracking", "تتبع"]
export const CAPACITY = ["Capacity", "سعة"]
export const INVENTORY = ["Inventory", "مخزون"]
export const ENABLE_TRACKING = ["Enable Tracking","تمكين التتبع"]

export const ITEM_TRACKING_MODES = ["Item Tracking modes","أوضاع تتبع العنصر"]
export const ITEM_TRACKING_SETTINGS = ["Item tracking settings","أوضاع تتبع العنصر"]
export const TOOL_TIP_INVENTORY = [
  "Tracks item’s stock, gets reduced by sales. You can add and subtract items manually.",
  "يتتبع مخزون العنصر ، ويتم الطرح من خلال المبيعات. يمكنك إضافة العناصر وطرحها يدويًا",
]
export const TOOL_TIP_CAPACITY = [
  "Amount you produce of an item on a daily basis, if you sell out today, you can still accept scheduled orders for other days.",
  "تتعقب سعة العنصر اليومية ، وتتم إعادة تعيين الكمية  في نهاية اليوم",
]
export const TOOL_TIP_NONE = [
  "This is manual available/not available.",
  "هذا يدوي متوفر / غير متوفر",
]

// PRODUCTS
export const PRODUCTS = ["Items", "المنتجات"]
export const PRODUCTS_CATALOG = ["Product Catalog", "قائمة المنتجات"]
export const PRODUCTS_OPTION_GROUPS = ["Option Groups", "مجموعات الخيارات"]

export const PRODUCT_NAME = ["Item name", "اسم المنتج"]
export const BRANCH_PER_VARIANT = ["Variant availability per branch", "توفر المتغير لكل فرع"]
export const TOOLTIP = ["How you track the availability of your items.", "كيف يمكنك تتبع توافر العناصر الخاصة بك."]
export const NONE_DESCRIPTION = [
  "Change the availability of a variant, no quantity tracking.",
  "لم يتم تمكين تعقب كمية العنصر من قبل المسؤول ، يمكنك فقط تغيير حالة توفر العنصر",
]
export const INVENTORY_DESCRIPTION = [
  "Tracks items by stock quantity, adjust after any orders, and rejects all orders when the stock value is zero.",
  "يتتبع العناصر حسب كمية المخزون ، ويعدل بعد أي أوامر ، ويرفض جميع الطلبات عندما تكون قيمة المخزون صفرًا.",
]
export const CAPACITY_DESCRIPTION = [
  " This is the amount you produce of this variant/item on a daily basis, as opposed to inventory management if you sell out today, you can still accept scheduled orders for other days.",
  "يدير العلاقة بين الطلبات والإنتاج اليومي للعنصر. يرفض الطلبات تلقائيًا عندما تصل الكمية في أي يوم إلى الصفر",
]
export const TRACKING_NONE = ["None", "خالي"]
export const TRACKING_INVENTORY = ["Inventory", "مخزون"]
export const TRACKING_CAPACITY = ["Capacity", "اِستِيعَاب"]

export const BASIC_INFO = ["Basic information", "البيانات الرئيسية"]
export const ENTER_PRODUCT_NAME = ["E.g. Orange Juice", "مثال: عصير برتقال"]
export const SETUP_PRODUCTS_CATALOG = [
  "Setup › Product Catalog",
  "الإعدادات > قائمة المنتجات",
]
export const DELETE_PRODUCT_CONFIRMATION = [
  "Are you sure you want to delete this product?",
  "هل أنت متأكد من أنك تريد حذف هذا المنتج؟",
]
export const DELETE_PRODUCT_NOTE = [
  "Note: Deleting this item will cause all sales data for this item to disappear from the reports. Try to unpublish the item instead.",
  "ملحوظة: حذف هذا المنتج سوف يؤدي إلي إختفائه من التقارير، تستطيع إخفاءه بدلاً من الحذف.",
]
export const PUBLISH_PRODUCT_CONFIRMATION = [
  "Are you sure you want to publish this item?",
  "هل أنت متأكد من أنك تريد نشر هذا المنتج؟",
]
export const UNPUBLISH_PRODUCT_CONFIRMATION = [
  "Are you sure you want to unpublish this item?",
  "هل أنت متأكد من أنك تريد إخفاء هذا المنتج؟",
]
export const UNPUBLISH_PRODUCT_SUCCESS = [
  "Item unpublished successfully",
  "تم اخفاء المنتج بنجاح",
]

export const ADD_CATEGORY = ["Add Category", "إضافة قسم"]
export const EDIT_CATEGORY = ["Edit Category", "تعديل قسم"]
export const ITEMS_COUNT = number => [`Items (${number})`, `منتجات (${number})`]
export const EXPORT_CATALOG = ["Export catalog", "إصدار نسخة من قائمة المنتجات"]
export const EXPORT_CATALOG_MSG = [
  "Export in progress, you will receive a confirmation email with .xlsx file once it’s done.",
  "التصدير قيد التقدم ، ستتلقى رسالة تأكيد بالبريد الإلكتروني تحتوي على ملف .xlsx بمجرد الانتهاء.",
]
export const IMPORT_CATALOG = ["Import catalog", "استيراد قائمة المنتجات"]
export const IMPORT_FOODICS_MENU = ["Import Foodics Menu","قائمة استيراد فودكس"]
export const IMPORT_FOODICS_MENU_DESCRIPTION = [
  "Sync your menu from foodics",
  "مزامنة القائمة الخاصة بك من فودكس"
]
export const IMPORT_CATALOG_FROM_POS = (name) => [
  `Import catalog from ${name}`,
  `استيراد القائمة من ${name}`,
]
export const IMPORT_CATALOG_FROM_FOODICS = [
  "Import catalog from foodics",
  "استيراد القائمة من فودكس",
]
export const SUCCESS_IMPORT_CATALOG_FROM_FOODICS = [
  "An email will be sent for confirmation once the import from Foodics is complete",
  "سيتم إرسال بريد إلكتروني للتأكيد. بمجرد اكتمال الاستيراد من فودكس",
]
export const EXTERNAL_ID_REQUIRED = [
  'External id is required',
  'رقم التعريف الخارجي مطلوب'
]
export const LINK_BRANCHES = ["LINK BRANCHES", "ربط الفروع"]
export const SYNC_MENU = ["SYNC MENU", "مزامنة القائمة"]
export const CHOOSE_BRANCH_FOR_FOODICS = [
  "To import products into a branch, please select one of your branches",
  "لإستيراد المنتجات، من فضلك اختر فرع ليتم اضافتهم عليه",
]
export const FOODICS_BRANCHES_LINKING = [
  "Foodics Branches Linking",
  "ربط فروع فوديكس"
]
export const FOODICS_BRANCH = ["Foodics Branch", "فرع فوديكس"]
export const FOODICS_BRANCH_LINKING_DESCRIPTION = branchName => [
  `Select branch from foodics to sync with ${branchName} Branch`,
  `حدد فرعًا من فودكس للمزامنة مع فرع ${branchName}`
]
export const LINK_TO_FOODICS = ["Link to Foodics", "ربط مع فوديكس"]
export const BRANCH_ALREADY_LINKED = branch =>[
  `${branch} is already linked`,
  `${branch} مرتبط بالفعل`
]
export const IMPORT_FOOICS__DESCRIPTION = [
  "All your branches needs to be linked to foodics to make sure that menu items are synced correctly.",
  "يجب ربط جميع فروعك بـ فودكس للتأكد من مزامنة عناصر القائمة بشكل صحيح"
]
export const IMPORT_FOODICS_NO_BRANCHES_DESCRIPTION = [
  "Menu syncing needs to have branches created and linked with foodics. To get started you need to add business locations",
  "تحتاج مزامنة القائمة إلى إنشاء فروع وربطها بالطعام. للبدء، تحتاج إلى إضافة مواقع العمل"
]
export const SET_DEFAULT_COURIER = [
  "Orders delivery fees and time will be calculated based on this courier",
  "سيتم حساب رسوم و مدة التوصيل للطلبات بناءً على مقدم الخدمة."
]
export const IS_FEE_FROM_COURIER = courierName => [
  `Get delivery fees from ${courierName}`,
  `حساب رسوم التوصيل من ${courierName}`,
]
export const IS_TIME_FROM_COURIER = courierName => [
  `Get delivery time from ${courierName}`,
  `حساب مدة التوصيل من ${courierName}`,
]
export const DEFAULT_COURIER = [
  "Default courier",
  "مقدم الخدمة الرئيسي",
]
export const CHANGE = ["Change", "تغير"]
export const UNNAMED = ["Unnamed","غير مسمى"]
export const EXPORT_STOCK = ["Export stock", "تصدير المخزون"]
export const IMPORT_STOCK = ["Import stock", "استيراد المخزون"]
export const ITEMS = (numberOfItems) => [`${numberOfItems} ${numberOfItems === 1 ? 'Item' : 'Items'}`, `${numberOfItems} ${numberOfItems === 1 ? 'منتج' : 'منتجات'}`]
export const PLEASE_SELECT_LOCATION = [
  "Please select location",
  "برجاء اختيار فرع",
]
export const SEARCH_ORDERS = [
  "by order number, phone, customer name or vouchers",
  "ابحث باستخدام رقم الطلب، رقم التليفون، اسم العميل أو كود الخصم",
]
export const SEARCH_ORDERS_MOBILE = [
  "by order number, phone, customer",
  "ابحث باستخدام رقم الطلب، رقم التليفون، اسم العميل",
]
export const SEARCH_PRODUCT = [
  "Search Categories, Items",
  "بحث في الاقسام والمنتجات",
]
export const SEARCH_MENU = numbers => [
  `Search ${numbers[0]} Categories and ${numbers[1]} Items`,
  `ابحث في ${numbers[0]} فئة و ${numbers[1]} منتج`,
]
export const SEARCH_STORES = ["Search Stores", "بحث في المتاجر"]
export const SEARCH_CUSTOMERS = [
  "Search customers list...",
  "بحث في قائمة العملاء...",
]
export const ALL_LOCATION = ["All Locations", "كل الفروع"]
export const COLLAPSE_ALL = ["Collapse all", "اخفاء الكل"]
export const EXPAND_ALL = ["Expand all", "عرض الكل"]
export const VIEW_IN = ["View in", "عرض في"]
export const ADD_PRODUCT = ["Add item", "إضافة منتج"]
export const EDIT_PRODUCT = ["Edit item", "تعديل منتج"]
export const ADD_PRODUCT_EMPTY = ["+ Add product", " + إضافة منتج"]
export const ADD_OPTION_EMPTY = ["+ Add option", " + إضافة خيار"]
export const PUBLISHED = ["Published", "منشور"]
export const UNPUBLISHED = ["Unpublished", "مخفي"]
export const EDIT_PUBLISH = ["Manage Publishing", "تعديل النشر"]
export const PRICE_ON_SELECTION = ["Price on selection", "السعر عند الاختيار"]
export const TAKE_STORE_OFFLINE = [
  "Take your Store Offline?",
  "هل تريد إخفاء متجرك بأكمله؟",
]
export const ARE_YOU_SURE_UNPUBLISH = [
  "Are you sure you want to unpublish your store? This will make you 100% offline and thus inaccessible by your users.",
  "هل أنت متأكد من أنك تريد إخفاء متجرك؟ سيؤدي ذلك إلى إخفائه بالكامل ومنع المستخدمين من الوصول إليه.",
]
export const IF_YOU_WANT_JUST = [
  "If you want to just stop receiving orders, We advise you to set your business locations(s) as 'Busy'",
  "إذا كنت ترغب في التوقف عن تلقي الطلبات مؤقتاً، فيمكنك تغيير حالة الفرع (أو الفروع) إلى 'مشغول'",
]
export const UNPUBLISHED_IN_BUSINESS_LOCATION = count => [
  `Unpublished in ${count} business locations`,
  `مخفي من ${count} فرع`,
]

export const UNPUBLISHED_IN = ["Unpublished in", "مخفي من"]
export const PUBLISHED_IN = ["Published in", "منشور في"]
export const UNPUBLISH = ["Unpublish", "إخفاء"]
export const DUPLICATE = ["Duplicate", "نسخ"]
export const BASIC = ["Basic", "الأساسي"]
export const CUSTOMIZE_PRODUCT = ["Customize Item", "تخصيص المنتج"]
export const CUSTOMIZE = ["Customize", "تخصيص"]
export const PREVIEW = ["Preview", "معاينة"]

export const DESC_SELECT_LOCATION = [
  "Locations in which the category will be published in",
  "حدد الفروع التي سيظهر فيها هذا القسم ضمن القائمة.",
]
export const PUBLISH = ["Publish", "نشر"]
export const DESC_PUBLISH = [
  "Once a category is added, your customers will see it directly.",
  ".سيظهر القسم أمام العملاء بمجرد إضافته مباشرة",
]
export const CATEGORY_TITLE = ["Enter category title", "أدخل عنوان القسم"]
export const DELETE_CATEGORY = [" Delete Category", "حذف القسم"]
export const IMPORT = ["Import", "استيراد"]
export const DOWNLOAD = ["Download", "تحميل"]
export const DOWNLOAD_TEMPLATE = [
  "Download template",
  "تحميل قالب قائمة المنتجات",
]
export const UPLOAD_TEMPLATE = ["Upload Template", "رفع قالب قائمة المنتجات"]
export const DESC_UPLOAD_TEMPLATE = [
  "After filling the template you should upload it to add your item catalog items.",
  "بعد ملء القالب عليك تحميله لاضافة عناصر قائمة المنتجات الخاصة بمتجرك",
]
export const IMPORT_STOCK_TEMPLATE_DESC = [
  "After updating the details you should upload it to update your stock.",
  "بعد تحديث التفاصيل ، يجب تحميلها لتحديث مخزونك من المنتجات",
]

export const FILL_TEMPLATE = ["Fill Template", "املء القالب"]
export const DESC_FILL_TEMPLATE = [
  "Download the template and fill it with the details.",
  " قم بتحميل القالب واملئه بالتفاصيل",
]

export const CREATE_PRODUCT_CATALOG = [
  "Add your first item",
  "أضف المنتجات الخاصة بك",
]

export const CREACTE_PRODUCT_OPTIONS = [
  "Create your options",
  "أضف الخيارات الخاصة بك",
]

export const CREACTE_PRODUCT_OPTIONS_DESC = [
  "Add multiple options to this group and control your options easily",
  "",
]

export const START_ADDING_CATEGORIES_IMPORT_EXISTING_CATALOG = [
  "Add your first category in order to start building your menu.",
  "أضف فئتك الأولى لتتمكن من البدء في بناء قائمتك",
]

export const START_ADDING_PRODUCTS = [
  "You can add physical items and services or anything else you dream up",
  "يمكنك إضافة وإدارة منتجاتك من قائمة المنتجات",
]

export const PRODUCT_IMPORTED_SUCCESS = [
  "Your data is being processed, An email notification will be sent on completion.",
  "بياناتك قيد المعالجة ، سيتم إرسال إشعار بالبريد الإلكتروني عند الانتهاء.",
]

export const ADD_FILE = ["Add File", "أضف ملف"]

export const CLICK_DROP_FILE = [
  "Click or drop file to upload",
  "اضغط أو اترك الملف لرفعه",
]
export const PRICE = ["Price", "السعر"]
export const DESC_SKIP_PRICE = [
  "If you're adding variants to this item, you can skip adding the price.",
  "يمكنك تخطى إضافة السعر فى حالة إضافة متغيرات لهذا المنتج.",
]
export const CREATE_PRODUCT = ["Item has been created", " تم إنشاء المنتج "]
export const PRODUCT_CREATED = name => [
  `${name} item has been Created`,
  `تم إنشاء ${name} المنتج`,
]
export const PRODUCT_UPDATED = name => [
  `${name} item has been Updated`,
  `تم تحديث ${name} المنتج`,
]
export const MENU_ITEM_UPDATED = name => [
  `${name} item has been Updated.`,
  `تم تحديثه ${name} المنتج`,
]
export const VARIANT_UPDATED = ["Variant has been Updated", "تم تحديث المتغير"]
export const CATEGORY = ["Category", "القسم"]
export const EACH_ITEM_CAN_BE = [
  "You can assign the same item to multiple categories.",
  "يمكن إدراج كل منتج في أقسام متعددة",
]
export const CHOOSE_ALL_BUSINESS_ITEM = [
  "Choose all branches where this item will be available.",
  "اختر كل الفروع التي سيتوفر فيها هذا المنتج.",
]
export const PLEASE_SELECT_CATEGORY = [
  "Assign to category. e.g. Drinks",
  "اضف لقسم. مثال: المشروبات",
]
export const ITEM_TRACKING = ["Item tracking", "تتبع الأصناف"]
export const PUBLISH_IN = ["Publish in", "نشر في"]
export const SELECTED_BRANCHES = ["Selected Branches", "الفروع المختارة"]
export const DESCRIPTION = ["Description", "الوصف"]
export const ADD_DESCRIPTION = ["Add Description", "وصف المنتج"]
export const SAVE_CONTINUE = ["Save and continue", "حفظ ومتابعة"]
export const INTRODUCTION_VARIANTS = ["Introducing Variants", "المتغيرات"]
export const IF_THIS_ITEM = [
  "If this item comes in multiple sizes, colors or shapes.",
  " إذا كان هذا المنتج يأتي بأحجام أو ألوان أو أشكال متعددة",
]
export const YOU_CAN_ADD_DIFFERENT = [
  "You can add different variants of your item for the customers to choose from.",
  "يمكنك إضافة أنواع مختلفة من سلعتك للعملاء للاختيار من بينها",
]
export const ADD_VARIANT = ["New variant", "إضافة متغير"]
export const EDIT_VARIANT = ["Edit Variant", "تحديث متغير"]
export const VARIANTS = ["Variants", "المتغيرات"]
export const CUSTOMIZE_SECTION_TITLE = [
  "+ Customize Section Title",
  "+ تخصيص اسم القسم",
]
export const SECTION_TITLE = ["Section Title", "اسم القسم"]

export const VR_SECTION_TITLE_EN_REQ = [
  "Variants section title English is required",
  "مطلوب عنوان قسم المتغيرات بالإنجليزية",
]
export const VR_SECTION_TITLE_AR_REQ = [
  "Variants section title Arabic is required",
  "مطلوب عنوان قسم المتغيرات بالعربية",
]

export const TITLE_PLACEHOLDER = ["Title En ...", "عنوان بالعربية ..."]

export const YOU_CAN_OVERRIDE = [
  "You can override the section title",
  "يمكنك تجاوز عنوان القسم",
]
export const ARE_SURE_TO_DELETE_VARIANT = [
  "Are you sure you want to delete this variant?",
  "هل أنت متأكد أنك تريد حذف هذا المتغير؟",
]

export const VARIANT_NAME = ["Variant name", "اسم المتغير"]
export const PRICE_CURRENCY = CURRENCY => [
  `Price (${CURRENCY})`,
  `السعر (${CURRENCY})`,
]
export const EX_EXTERNAL_ID = ["Ex: UX-01"]
export const EX_LARGE = ["Ex: Large", "مثال: كبير"]
export const EX_1500 = ["Ex: 1.500", "مثال: 1.500"]
export const P_ADD_VARIANT = ["+ New Variant", "إضافة متغير +"]
export const CANCEL_PRODUCT = ["Cancel", "إلغاء"]
export const SAVE_CUSTOMIZE = ["Save & Customize", "حفظ و تخصيص"]

export const VARIANT_TITLE_EN_REQ = [
  "Please enter english variant name",
  "يرجى إدخال اسم متغير باللغة الإنجليزية",
]
export const VARIANT_TITLE_AR_REQ = [
  "Please enter arabic variant name",
  "يرجى إدخال اسم متغير باللغة العربية",
]
export const VARIANT_PRICE_REQ = [
  "Please enter variant price",
  "يرجى إدخال سعر المتغير",
]
export const VARIANT_EXTERNAL_ID_REQ = [
  "Please enter variant external Id",
  "يرجى إدخال رقم التعريف الخارجى للمتغير",
]

export const EXTERNAL_ID_REQ = [
  "External ID is required",
  "يرجى إدخال رقم التعريف الخارجى للمتغير",
]

export const PRODUCT_IMAGE = ["Item Images", "صور المنتج"]
export const UPLOAD_AND_SORT_IMAGES = [
  "Upload and sort images for each variant.",
  "قم بتحميل وفرز الصور لكل متغير.",
]
export const UPLOAD_IMAGES = ["Upload Images", "ارفع الصور"]

export const DELETE_IMAGE = ["Delete Image", "احذف صورة"]
export const DELETE_FILE = ["Delete Image", "احذف ملف"]
export const ARE_YOU_SURE_DELETE_IMAGE = [
  "Are you sure to delete this image?",
  "هل أنت متأكد من أنك تريد حذف هذه الصورة؟",
]
export const ARE_YOU_SURE_DELETE_FILE = [
  "Are you sure to delete this file?",
  "هل أنت متأكد من أنك تريد حذف هذا الملف؟",
]
export const ARE_YOU_SURE_THIS = [
  "Are you sure about this?",
  "هل أنت متأكد من هذا؟",
]
export const DELETE_GROUP = ["Delete option group ", "حذف مجموعة الخيارات"]
export const ARE_YOU_SURE_TO_DELETE_GROUP = [
  "Are you sure you want to delete the option group?",
  "هل أنت متأكد أنك تريد حذف مجموعة الخيارات؟",
]

export const INTRO_OPTION_GROUPS = [
  "Introducing Option Groups",
  "مجموعات الخيارات الإضافية",
]
export const IF_YOU_WANT_ADD_EXTRA = [
  "If you want to add extra options or add-ons.",
  "إذا كنت ترغب في إضافة خيارات إضافية أو إضافات",
]
export const YOU_CAN_ADD_EXTRA = [
  "You can add extras and add-ons to items so customers can add optional or complementary items.",
  "يمكنك إضافة زيادات وإضافات إلى العناصر حتى يتمكن العملاء من إضافة عناصر اختيارية أو تكميلية",
]
export const ADD_GROUP = ["Create group", "إنشاء مجموعة"]
export const ADD_OPTION = ["+ Add Option", "إضافة خيار"]
export const UPDATE_GROUP = ["+ Update group", "تحديث مجموعة"]
export const INSERT_GROUP = ["Insert Group", " إدراج مجموعة"]
export const INSERT = ["Insert", "إدراج"]
export const OPTIONS = ["Options", "الخيارات"]
export const NO_OPTION_GROUP = ["No option group", "لا توجد مجموعة خيارات"]
export const NO_OPTIONS = ["No Options", "لا توجد خيارات"]

export const OPTION_TITLE_EN = [
  "Option English title is required",
  " اسم الخيار باللغة الإنجليزية مطلوب",
]
export const OPTION_TITLE_AR = [
  "Option Arabic title is required",
  "اسم الخيار باللغة العربية مطلوب",
]
export const MIN_QTE_REQ = [
  "Min quantity is required",
  "الحد الأدنى من الكمية مطلوب",
]
export const MAX_QTE_REQ = [
  "Max quantity is required",
  "الحد الأقصى من الكمية مطلوب",
]
export const ADD_OPTION_EN_REQ = [
  "Please enter English option name",
  "يرجى إدخال اسم المتغير باللغة الإنجليزية",
]
export const ADD_OPTION_AR_REQ = [
  "Please enter Arabic variant name",
  "يرجى إدخال اسم المتغير باللغة العربية",
]

export const CHOOSE_VARIANT = ["Choose a variant", "اختر المتغير"]

export const SELECT_GROUP = ["Select Group", "اختر المجموعة"]
export const SEARCH_FOR_GROUP = [
  "Search for the group you want to insert",
  "ابحث عن المجموعة التي تريد إدراجها",
]
export const SELECT_GROUP_TO_INSERT = [
  "Select the group you want to insert",
  "حدد المجموعة التي تريد إدراجها",
]

export const OPTION_GROUPS_COUNT = length => [
  `Option Groups (${length})`,
  `مجموعات الخيارات (${length})`,
]

export const OPTION_GROUPS_ADD_OPTION = ["Add Option", "إضافة خيار"]
export const OPTION_GROUPS_ADD_GROUP = ["Add Group", "إضافة مجموعة"]
export const OPTION_GROUPS_STATUS = ["Status", "الحاله"]
export const STATUS_ALL = ["All", "الكل"]
export const AVALIABLE = ["Available", "متاح"]
export const UNAVALIABLE = ["Unavailable", "غير متاح"]
export const OFF_DUTY = ["Off Duty", "خارج الخدمة"]
export const ON_DUTY = ["On Duty", "في الخدمة"]
export const OPTION_GROUPS_EDIT_GROUP = ["Edit Group", "تحديث المجموعة"]
export const OPTION_GROUPS_DUPLICATE_GROUP = [
  "Duplicate Group",
  "تكرار المجموعة",
]
export const OPTION_GROUPS_DUPLICATE_GROUP_MODAL = [
  "Duplicate Option Group",
  "تكرار المجموعة الخيارات",
]
export const OPTION_GROUPS_EXTERNALID_TITLE = [
  "External ID",
  "رقم تعريفي خارجي",
]
export const OPTION_GROUPS_EXTERNALID_PLACEHOLDER = [
  "Enter External ID",
  "ادخل رقم تعريفي خارجي",
]
export const OPTION_GROUPS_EXTERNALID_DESC = [
  "If you have a code that identifies the option group within your business.",
  "إذا كان لديك رمز يحدد مجموعة الخيارات بمتجرك.",
]
export const OPTION_GROUPS_DUPLICATE_BTN = ["Duplicate", "تكرار"]

export const OPTION_GROUPS_ADD_GROUP_MODAL = [
  "Add Option Group",
  "إضافة مجموعة خيارات",
]
export const OPTION_GROUPS_EDIT_GROUP_MODAL = [
  "Edit Option Group",
  " تحديث مجموعة الخيارات",
]
export const OPTION_GROUPS_ADD_GROUP_EX = ["Ex. Drinks", "مثال: مشروبات"]
export const OPTION_GROUPS_DELETE_GROUP = ["Delete Group", "حذف مجموعة"]
export const OPTION_GROUPS_DELETE_GROUP_CONFIRMATION = [
  "Are you sure you want to delete this group?",
  "هل أنت متأكد من أنك تريد حذف هذه المجموعة؟",
]
export const OPTION_GROUPS_ADD_OPTION_GROUP_SUCCESS = [
  "Option Group is added successfully",
  "تم إضافة مجموعة الخيارات بنجاح",
]
export const OPTION_GROUPS_EDIT_OPTION_GROUP_SUCCESS = [
  "Option Group is edited successfully",
  "تم تحديث مجموعة الخيارات بنجاح",
]
export const OPTION_GROUPS_DELETE_OPTION_GROUP_SUCCESS = name => [
  `${name} option Group is deleted successfully`,
  `تم حذف مجموعة خيارات ${name} بنجاح`,
]
export const OPTION_GROUPS_EDIT_OPTION = ["Edit Option", "تحديث الخيار"]
export const OPTION_GROUPS_OPTION_NAME = ["Option Name", "اسم الخيار"]
export const OPTION_GROUPS_GROUP_NAME = [
  "Option Group Name",
  "اسم مجموعة الخيارات",
]
export const OPTION_GROUPS_PRICE_EX = ["Ex. 56.544", "مثال: ٥٦.٥٤٤"]
export const OPTION_GROUPS_PRICE_REQ = ["Price is required", "السعر مطلوب"]
export const OPTION_GROUPS_PRICE_NUMBER = [
  "Price must be a number",
  "يجب أن يكون السعر رقمًا",
]
export const OPTION_GROUPS_EDIT_OPTION_NOTE = [
  "Note: All items that has this option will be affected with the changes",
  "ستتأثر جميع المنتجات التي تحتوي على هذا الخيار بالتغييرات",
]
export const OPTION_GROUPS_GROUP_NAME_REQ = [
  "Option group name is required",
  "اسم مجموعة الخيارات مطلوب",
]
export const OPTION_GROUPS_EXTERNALID_REQ = [
  "External Id is Required",
  "الرقم التعريفي الخارجي مطلوب.",
]
export const OPTION_GROUPS_DELETE_OPTION = ["Delete Option", "حذف الخيار"]
export const OPTION_GROUPS_DELETE_OPTION_CONFIRMATION = [
  "Are you sure you want to delete this option?",
  "هل أنت متأكد من أنك تريد حذف هذا الخيار؟",
]
export const OPTION_GROUPS_ADD_OPTION_SUCCESS = [
  "Option is added successfully",
  "تم إضافة الخيار بنجاح",
]
export const OPTION_GROUPS_DUPLICATE_SUCCESS = name => [
  `${name} group is duplicated successfully`,
  `تم تكرار مجموعة ${name} بنجاح`,
]
export const OPTION_GROUPS_EDIT_OPTION_SUCCESS = [
  "Option is edited successfully",
  "تم تحديث الخيار بنجاح",
]
export const OPTION_GROUPS_DELETE_OPTION_SUCCESS = name => [
  `${name} option is deleted successfully`,
  `تم حذف خيار${name}  بنجاح`,
]
export const OPTION_GROUPS_YOUR_CHOISE = [
  "Enter Option Group Name",
  "إضافة اسم مجموعة الخيارات",
]

export const OPTION_GROUPS = ["Option Groups", "مجموعات الخيارات"]
export const SAVE_GROUPS = ["Save Group", "حفظ المجموعة"]

export const LINKED_VARIANTS = ["Linked variants", "المتغيرات المرتبطة"]

export const LINK_GROUP = ["Link group to variant", "ربط المجموعة بالمتغير"]
export const THIS_OPTION_SHARED = [
  "This option group is shared with ",
  " تمت مشاركة مجموعة الخيارات مع",
]
export const THIS_IS_SHARED = option => [
  `This ${option} is shared with `,
  `هذا ${option} تمت مشاركته مع `,
]
export const ETC = ["..etc", "إلخ..."]
export const AT_LEAST = ["MIN", "الحد الأدنى"]
export const AT_MOST = ["At most", "الحد الأقصى"]
export const UNLIMITED = ["Unlimited", " غير محدود"]
export const AT_MOST_UNLIMITED = [
  "MAX  (0 = Unlimited)",
  "الحد الأقصى ( 0 = غير محدود)",
]
export const MULTIPLE_ADD_ON_CHECKBOX = [
  "Allow customers to select a single option multiple times",
  "يمكن لعملائك إختيار أكثر من إضافة واحدة من نفس المجموعة",
]

export const AT_LEAST_AT_MOST = qte => [
  `At least: ${qte[0]}, At most: ${qte[1]}`,
  `الحد الأدنى :${qte[0]}, الحد الأقصى :${qte[1]}`,
]

export const OPTION_NAME = ["Option Name", "اسم الخيار"]

export const INTRO_INVENTORY = ["Introducing Inventory", "إدخال الجرد"]
export const YOU_CAN_CUSTOMIZE_VARIANT = [
  "You can customize preparation time per variant.",
  "يمكنك تخصيص وقت الإعداد لكل متغير من المنتج على حدة.",
]
export const PREPARATION_TIME = ["Preparation Time", "وقت التحضير"]
export const VARIANT = ["Variant", "المتغير"]
export const EXTRA_PREP_TIME = [
  "Extra Preparation Time (mins)",
  "وقت التحضير الإضافي (بالدقائق)",
]
export const EX_50 = ["Ex: 50", "مثال: 50"]

export const SET_DEFAULT_PREPTIME = ["Set to default", "وقت التحضير الأساسى"]

export const CUSTOMIZE_PREPTIME = time => [
  `Customize the preperation time if a variant requires more time, Your default preparation time is ${time} Mins.`,
  `قم بتخصيص وقت التحضير إذا كان تحضير المتغير يتطلب وقت أطول من ${time} دقيقة  وقت التحضير الأساسى.`,
]

export const YOU_CAN_CUSTOMIZE_INVENTORY = [
  "You can customize the available stock of each variant of your item.",
  "يمكنك تعديل المخزون المتوفر من كل متغيرات منتجك.",
]
export const SPECIAL_NOTES = ["Special Notes", "ملاحظات خاصة"]
export const GET_ADDITIONAL_NOTE = [
  "Get additional notes from your customers.",
  "احصل على ملاحظات إضافية من عملائك",
]
export const NOTE_LABEL = ["Note Label", "عنوان الملاحظة"]
export const NOTE_EN_REQ = [
  "Note in English is required",
  "الملاحظة باللغة الإنجليزية مطلوب",
]
export const NOTE_AR_REQ = [
  "Note in Arabic is required",
  "الملاحظة باللغة العربية مطلوب",
]
export const YOU_CAN_PREVIEW_PRODUCT = [
  "You can preview the item view that will appear to your customers",
  "يمكنك معاينة طريقة عرض المنتج أمام عملائك",
]

export const SETUP_PRODUCT_EDITION = [
  "Setup › Product catalog › Edit product",
  "الإعدادات > قائمة المنتجات > تعديل المنتج",
]

export const SETUP_ADD_PRODUCT = [
  "Setup › Product catalog › Add product",
  "الإعدادات > قائمة المنتجات > إضافة منتج",
]

//DELIVERY
export const DELIVERY = ["Delivery", "توصيل"]
export const ADD_DELIVERY_ZONE = ["Add delivery zone", "إضافة منطقة توصيل"]
export const ADD_DELIVERY_ZONES = ["Add delivery zones", "إضافة مناطق التوصيل"]
export const BULK_CREATE_ISSUE = ["There was an issue adding the following zones:", "حدثت مشكلة في إضافة المناطق التالية:"]
export const BULK_EDIT_ISSUE = ["There was an issue editing the following zones:", "حدثت مشكلة في تعديل المناطق التالية:"]
export const BULK_EDIT_SUCCESS_MSG = numb => ([`Successfully updated ${numb} zones`, `تم تحديث ${numb} مناطق بنجاح`])
export const BULK_DELETE_SUCCESS_MSG = numb => ([`Successfully deleted ${numb} zones`, `تم حذف ${numb} مناطق بنجاح`]);
export const BULK_DELETE_ERROR_MESSAGE = ["There was an issue deleting the following zones:", "حدثت مشكلة في حذف المناطق التالية:"];
export const CONSIDER_ADDING = [
  "Consider adding more delivery zones",
  "حاول إضافة المزيد من مناطق التوصيل",
]
export const BULK_CREATE_WAITING_MSG = ["Your request is being processed and may take up to 10 minutes. Please do not close or refresh this page. This popup will automatically close when complete.", "يتم معالجة طلبك. قد يستغرق الأمر ما يصل إلى 10 دقائق.الرجاء عدم إغلاق أو تحديث هذه الصفحة. سيتم إغلاق هذه الرسالة تلقائيًا عند اكتمالها"];

export const BEACH = ["by Beach", "على الشاطئ"]
export const beachOrdering = ["Beach Ordering", "الطلب من على الشاطئ"]
export const beachOrderingDescription = [
  "Allow your customers to order from the beach and receive their orders to their numbered umbrella", 
  "سيتمكن العملاء من الطلب من على الشاطئ واستلام طلباتهم من الشمسية المرقمة الخاصة بهم"
]
export const YOU_CAN_ADD_MORE_DELEVERY = [
  "You can add more delivery zones to cover delivery areas around your business locations",
  "يمكنك إضافة المزيد من مناطق التوصيل لتغطية نطاق أوسع حول الفروع",
]
export const UPDATED_DELIVERY_FEES = [
  "Updated delivery fees",
  "رسوم التوصيل المحدثة",
]
export const UPDATED_DELIVERY_TIME = [
  "Updated delivery time",
  "موعد التوصيل المحدث",
]
export const ARE_YOU_SURE_UNPUBLISH_ZONE = [
  "Are you sure you want to unpublish this zone?",
  "هل أنت متأكد من أنك تريد إخفاء منطقة توصيل؟",
]
export const CASHBACK_AMOUNT = ["Paid by wallet", "مدفوعة بالمحفظة"]
export const DELIVERY_FEES = ["Delivery fees", "رسوم التوصيل"]
export const DELIVERY_FEES_PLACEHOLDER = ["Enter delivery fees", "ادخل رسوم التوصيل"]
export const DELIVERY_TIME = ["Delivery time", "موعد التوصيل"]
export const INCLUDE_PREP_TIME = time => [`Delivery time (including your ${time} min prep. time)`, ` موعد التوصيل (شامل ${time} دقيقة وفت التحضير)`]
export const DELIVERY_HOURS = ["Delivery hours", "ساعات التوصيل"]
export const MAXIMUM_SCHEDULE_TIME = [
  "Maximum Schedule Time",
  "أقصى وقت للطلب المسبق",
]
export const MINIMUM_ORDER = ["Minimum Order", "الحد الأدنى للطلب"]
export const KM = ["Km", "كم"]
export const DISTANCE = ["Distance", "مسافة"]
export const LOCATION = ["Location", "فرع"]
export const PROXIMITY_LOCATION = ["Proximity to Location", "المسافة من الفرع"]
export const OR_MORE = ["Km or more", "كم أو أكثر"]
export const EDIT_LOCATION = ["Edit Location", "تعديل الفرع"]
export const ZONE = ["Zone", "منطقة"]
export const REMAINING_AREAS = ["Remaining Areas", "الأماكن المتبقية"]
export const ASSIGN_TO_ZONE = ["Assign to Zone", "ضمّ إلى منطقة"]
export const MINS = ["Mins", "دقائق"]
export const DAYS = ["Days", "أيام"]
export const DAY = ["Day", "يوم"]
export const MONTH = ["Month", "شهر"]
export const EVERYDAY = ["Everyday", "يومياً"]
export const FROM = ["From", "من"]
export const TO = ["To", "إلى"]
export const BUSINESS_COUNTRY_IS_REQ = [
  "Business’s Country is required",
  "دولة النشاط التجاري مطلوبة",
]
export const DELIVERY_FEES_REQ = [
  "Delivery fee is required",
  "رسوم التوصيل مطلوبة",
]
export const SCHEDULE_MAX_TIME_REQ = [
  "Schedule Max Time is required",
  "أقصى وقت للطلب المسبق مطلوب",
]
export const DELIVERY_TIME_REQ = [
  "Delivery Time is required",
  "زمن التوصيل مطلوب",
]
export const GREATER_THAN_ZERO = ["Must be greater than 0", 'يجب أن يكون أكبر من 0']
export const GREATER_THAN_OR_EQUAL_ZERO = ["Must be greater than or equal 0", 'يجب أن تكون أكبر من أو تساوي 0']
export const MIN_ORDER_INTEGER = ["Minimum order must be integer", "يجب أن يكون الحد الأدنى للطلب عددًا صحيحًا"]
export const MIN_ORDER_REQ = ["Minimum order is required", "الحد الأدنى للطلب مطلوب"]

export const DELIVERY_TIME_INTEGER = ["Delivery time must be integer", "يجب أن يكون وقت التسليم عددًا صحيحًا"]
export const SELECTED_IN = ["Selected in", "مختارة في"]
export const NO_DELIVERY_ZONE = ["No Delivery Zones", "لا توجد مناطق توصيل"]
export const NO_SELECTED_AREAS = ["No Selected Areas", "لا مناطق مختارة"]
export const NO_AREAS_IN_THIS_RANGE = [
  "There are no Delivery Areas within this range",
  "لا توجد أماكن توصيل ضمن هذا النطاق",
]
export const NO_DAYS_SELECTED = ['No days selected', 'لم يتم تحديد أيام'];
export const NO_REMAINING_AREAS = ["No Remaining Areas", "لا توجد مناطق متبقية"]
export const YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST = [
  "you have to add business location first",
  " عليك إضافة فرع أولاً",
]
export const PICK_FROM_THESE_DELIEVERYZONES = [
  "Pick from these Delivery Zones",
  "اختر من ضمن مناطق التوصيل التالية",
]
export const ASSIGN = ["Assign", "تعيين"]
export const MULTIPLE_DELIVERY_HOURS = ['Multiple delivery hours', 'ساعات توصيل متعددة'];

export const CUSTOMIZE_DELIVERY_HOUR = [
  "You can customize your actual delivery hours if it’s different from opening hours. ",
  "يمكنك تخصيص ساعات التسليم الفعلية إذا كانت مختلفة عن ساعات العمل.",
]
export const ADD_AREAS_ZONE_COVERS = [
  "Add areas which this zone covers and delivers to ",
  "أضف الأماكن التي تغطيها منطقة التوصيل هذه",
]
export const YOU_CAN_FILTER_AREAS = [
  "You can filter areas based on a distance around your location",
  "يمكنك تصنيف الأماكن وفقاً لبعدها عن الفرع",
]
export const SELECT_ALL = ["Select All", "تحديد الكل"]
export const CLEAR_ALL = ["Clear All", "مسح الكل"]
export const DELIVERY_ZONE_ADDED = [
  "Delivery Zone Added",
  "تم إضافة منطقة التوصيل",
]
export const DELIVERY_ZONE_EDITED = [
  "Delivery Zone Edited",
  "تم تعديل منطقة التوصيل",
]
export const DELIVERY_ZONE_DELETED = [
  "Delivery Zone Deleted",
  "تم حذف منطقة التوصيل",
]
export const DELIVERY_ZONE_NOT_FOUND = [
  "Delivery zone not found",
  "منطقة التسليم غير موجودة"
]
export const DELIVERY_ZONE_UNPUBLISHED = [
  "Delivery Zone Unpublished",
  "تم إخفاء منطقة التوصيل",
]
export const ZONE_HAS_BEEN_ASSIGNED = [
  "Zone has been assigned",
  "تم تعيين منطقة التوصيل",
]
export const ZONE_PROPERTIES = ['Zone properties', 'خصائص المنطقة'];
export const DELETE_ZONE = ['Delete Zone', 'حذف المنطقة'];
export const ZONE_NAME_EXIST = ['Zone name has already been taken', 'تم استخدام اسم المنطقة بالفعل']
export const DELIVERY_TIME_PREPARATION = time => [
  `Delivery time Can't be less than the preparation time ${time}`, 
  ` لا يمكن أن يكون وقت التسليم أقل من وقت التحضير ${time} `
]

export const BUSINESS_LOCATION = ["Branches", "الفروع"]
export const YOU_CAN_CUSTOMIZE_ACTUAL = [
  "You can customize your actual delivery hours if it’s different from opening hours.",
  "يمكنك تعديل ساعات التوصيل الفعلية إذا كانت مختلفة عن ساعات العمل.",
]
export const ADD_MIN_ORDER = ["+ Add Minimum Order", "أضف الحد الأدنى للطلب +"]
export const ADD_ANOTHER_SHIFT = [
  "+ Add Another Shift",
  "+ إضافة نوبة عمل أخرى",
]
export const AREAS = ["Areas", "مناطق"]
export const ADD_ZONE_COVER = [
  "Add areas which this zone covers and delivers to",
  "أضف الأماكن التي تغطيها منطقة التوصيل هذه",
]
export const YOU_CAN_FILTER = [
  "You can filter areas based on a distance around your location",
  "يمكنك تصنيف الأماكن وفقاً لبعدها عن الفرع",
]
export const PIN_LOCATION_MAP = [
  "Pin location on Map",
  "تحديد الموقع على الخريطة",
]
export const SEARCH_PLACES = ["Search for Areas…", "البحث عن الأماكن ..."]
export const FILTER_AREAS = ["Filter Areas...", "تصنيف الأماكن ..."]
export const SELECTED_AREAS = ["Selected Areas", "الأماكن المختارة"]
export const AVAILABLE_AREAS = ["Available Areas", "الأماكن المتاحة"]
export const SEARCH_GOOGLE_MAPS = ["Search google maps", "ابحث في خرائط جوجل"]
export const DELIVERY_AREA = ["Delivery area", "منطقة التوصيل"]

export const ARE_YOU_SURE_TO_DELETE_ZONE = [
  "Are you sure you want to delete this delivery zone?",
  "هل أنت متأكد أنك تريد حذف منطقة التوصيل هذه؟",
]
export const DELETE_DELIVERY_ZONE = [
  "Delete Delivery Zone",
  "حذف منطقة التوصيل",
]
export const WOULD_YOU_LIKE_ADD_ZONE = [
  "How would you like to add your zone?",
  "كيف تريد إضافة منطقتك؟"

]
export const FREE_DRAWING_ZONES = [
  "Free-drawing Zones",
  "رسم حر"
]
export const FREE_DRAWING_ZONES_DESC = [
  "Conveniently draw zones in your branch area.",
  "ارسم مناطق التوصيل لفرعك يدويا"
]
export const ZONE_TEMPLATES = [
  "Zones Templates",
  "مناطق جاهزة"
]
export const ZONE_TEMPLATES_DESC = [
  "Choose from a list of pre-selected zones in your branch area.",
  "اختر منطقة توصيل معدة مسبقا"
]
export const NO_TEMPLATES_TEXT = ['No templates found, try free-drawing.','لا يوجد مناطق حول هذا الفرع، جرب الرسم الحر.' ]
export const SUGGESTED_ZONES = ["Suggested Zones", 'المناطق المقترحة']
export const ALL_ZONES = ["All Zones", 'كل المناطق']
export const BRANCH_OPTIONS = ["Branch Options", 'قائمة الفروع']
export const SELECT_TEXT = ["Select", 'اختر']
export const SAVE = ["Save", "حفظ"]
export const ASSIGNED_BRANCH = ['Assigned Branch', 'الفرع المعين'];
export const REASIGN_BRANCH_MSG = ['All selected zones will be assigned to the new selected branch', 'سيتم تخصيص جميع المناطق المحددة للفرع الجديد المحدد'];
export const ADD_MULTI_ZONES =  numb => ([`Add (${numb}) zones`, `إضافة (${numb}) مناطق`]);
export const ZONES_SELECTED = numb => ([`${numb} zones selected`, `${numb} مناطق مختارة`]);
export const SET_TO_ACTIVE = ["Set to active", 'تغيير إلى نشط'];
export const SET_ZONES_TO_ACTIVE = numb => [`Set ${numb} zones to active`, `تغيير ${numb} مناطق إلى نشط`];
export const SET_ZONES_TO_INACTIVE = numb => [`Set ${numb} zones to inactive`, `تغيير ${numb} مناطق إلى غير نشط`];
export const SET_TO_INACTIVE = ["Set to inactive", 'تعيين إلى غير نشط'];
export const SET_DELIVERY_FEES = ["Set delivery fees", 'تحديد رسوم التوصيل'];
export const SET_MIN_ORDER = ["Set min order value", 'تعيين الحد الأدنى لقيمة الطلب'];
export const SET_DELIVER_TIME = ["Update time to deliver", 'وقت التحديث للتسليم'];
export const SET_DELIVERY_HOURS = ["Update delivery hours", 'تحديث ساعات التسليم'];
export const REASIGN_BRANCH = ["Re-assign branch", 'إعادة تخصيص الفرع'];
export const UPDATE_MIN_ORDER = ["Update minimun order", "تحديث الحد الأدنى للطلب"]
export const UPDATE_MIN_ORDER_MSG = numb => ([`Remove or update minimun order for ${numb} zones`, `قم بإزالة أو تحديث الحد الأدنى للطلب لـ ${numb} مناطق`])
export const UPDATE_DELIVERY_TIME = ["Update time to deliver", "تحديث وقت التسيلم"]
export const UPDATE_DELIVERY_TIME_MSG = numb => ([`Time to deliver should include the preparation time (${numb} mins)`, `يجب أن يشمل وقت التسليم وقت التحضير (${numb} دقيقة)`])
export const UPDATE_WORKING_HOURS = ["Update delivery operating hours", "تحديث ساعات عمل التسليم"];
export const UPDATE_WORKING_HOURS_MSG = ["Delivery hours are defaulted to branch operating hours", "يتم تحديد ساعات التسليم وفقًا لساعات عمل الفرع"];
export const UPDATE_DELIVERY_FEES = ["Update delivery fees", "تحديث رسوم التوصيل"];
export const UPDATE_DELIVERY_FEES_MSG = numb => ([`Remove or update delivery fees for ${numb} zones`, `إزالة أو تحديث رسوم التوصيل ${numb} مناطق`]);
export const UPDATE_ASSIGN_BRANCH = ["Re-assign to a different branch", "إعادة التخصيص لفرع مختلف"];
export const UPDATE_ASSIGN_BRANCH_MSG = numb => ([`Update delivery fulfillment for 3 zones`, `تحديث تنفيذ التسليم ${numb} مناطق`]);
export const DELETE_ZONES_TITLE = numb => ([`Delete ${numb} Zones?`, `حذف ${numb} مناطق؟`]);
export const DELETE_ZONES_MSG = ['Are you sure you want to delete these zones? Deleting these zones means your customers will not be able to order from any of these places.', 'هل أنت متأكد أنك تريد حذف هذه المناطق؟ يعني حذف هذه المناطق أن عملائك لن يتمكنوا من الطلب من أي من هذه الأماكن.'];
export const APPLY_DELIVERY_FEE = ['Apply delivery fees', 'تطبيق رسوم التوصيل'];
export const REMOVE_DELIVERY_FEE = ['Remove delivery fees', 'إزالة رسوم التوصيل'];
export const APPLY_MIN_ORDER = ['Apply minimum order requirement', 'تطبيق الحد الأدنى لمتطلبات الطلب'];
export const REMOVE_MIN_ORDER = ['Remove minimum order value', 'قم بإزالة الحد الأدنى لقيمة الطلب'];

export const BULK_EDIT_TEXT = ['Bulk edit', 'تعديل المناطق']
export const BRANCH_DELIVERY_DISABLED = ['Delivery disabled for this branch', 'التسليم معطل لهذا الفرع'];
export const ENABLE_DELIVERY_TEXT = ['You"ll need to enable delivery for this branch for customers to order from its zone', 'تحتاج إلى تفعيل التسليم لهذا الفرع للعملاء للطلب من منطقته'];
export const ENABLE_DELIVERY = ['Enable delivery', 'تفعيل التسليم'];
export const ENABLE_DELIVERY_SUCCESS_TEXT = ['Delivery enabled successfully', 'تم تفعيل التسليم بنجاح'];

export const SETUP_DELIVERY = ["Setup › Delivery", "الإعدادات > التوصيل"]
export const SETUP_DELIVERY_CREATION = [
  "Setup › Delivery › Add delivery zone",
  "الإعدادات > التوصيل > إضافة منطقة توصيل",
]
export const CHOOSE_FROM_MENU_OR_MAP = [
  "Choose a branch from the list or the map",
  "اختر الفرع من القائمة او من على الخريطة"
]
export const CHOOSE_ONE_BRANCH = ["List of branches", "قائمة الفروع"]
export const TABLE_VIEW = ["List of zones", "قائمة مناطق التوصيل"]
export const SHAPE_PROPERTIES = ["Shape properties", "خصائص الشكل"]
export const ZONE_NAME = ["Zone Name", "اسم المنطقة"];
export const ZONE_NAME_PLACEHOLDER = ["Enter zone name", "أدخل اسم المنطقة"]
export const ZONE_NAME_REQ = ["Zone name is required", "اسم المنطقة مطلوب"]
export const PUBLISH_ZONE = ["Publish zone", "نشر المنطقة"];
export const NUMBERS_ONLY = ["Numbers only.", "أرقام فقط"]
export const START_ADDING_ZONE = ["Start adding zone", "ابدأ في إضافة المنطقة"]
export const DRAW_SHAPE = ["Draw Shape", "ارسم الشكل"]
export const USE_CIRCLE = ["Use Circle", "استخدم الدائرة"]
export const LEAVE_WITHOUT_SAVE = ["Are you sure to leave without save", "هل أنت متأكد من المغادرة دون حفظ"]
export const DRAW_SHAPE_TOOLTIP = ["Start by adding points on the map.", "ابدأ بإضافة نقاط على الخريطة."]
export const DRAW_CIRCLE_TOOLTIP = ["Drag points on the circle to set radius metrics.", "اسحب نقطة على الدائرة لضبط المسافة."]
export const HIDE_OTHER_ZONES = ["Hide zones from other branches", "إخفاء المناطق من الفروع الأخرى"]
export const ZONES = ['Zones', 'مناطق'];

//BUSINESS LOCATIONS
export const BUSINESS_LOCATIONS_EMPTY_TITLE = [
  "Add Your Business Locations",
  "أضف فروع نشاطك التجاري",
]
export const BUSINESS_LOCATIONS_EMPTY_DESC = [
  "Start adding your business locations to operate and setup delivery",
  "ابدأ بإضافة فروع نشاطك التجاري لإعداد خيارات التوصيل وتفعيل الطلب على المتجر",
]
export const MAP_FAILED_TO_LOAD = [
  "Map was failed to load",
  "فشل تحميل الخريطة",
]
export const SEARCH_FOR_PLACES = ["Search for Areas", "البحث عن أماكن"]
export const ENTER_BUSINESS_LOCATION_NAME = [
  "Enter business location name",
  "أدخل اسم الفرع",
]
export const ENTER_ADDRESS = ["Enter address", "أدخل العنوان"]
export const ENTER_BUILDING_NUMBER = ["Enter building number", "ادخل رقم المبنى"];
export const ENTER_STREET_NAME = ["Enter street name", "ادخل اسم الشارع"];
export const ENTER_CITY = ["Enter city", "ادخل المدينة"];
export const ENTER_DELIVERY_NOTES = ["Enter delivery notes", "ادخل ملاحظات التوصيل"];
export const ENTER_BLOCK = ["Enter block", "ادخل القطعة"];
export const ENTER_COUNTRY = ["Enter country", "ادخل الدولة"];
export const ADDRESS_IN_ENGLISH = ["Address in english", "العنوان بالانجليزية"]
export const ADDRESS_IN_ARABIC = ["Address in arabic", "العنوان بالعربية"]
export const ADD_TO_DELIVERY_ZONE = [
  "Add to delivery zone",
  "إضافة لمنطقة توصيل",
]
export const BUSINESS_LOCATION_SUCCESS_TITLE = name => [
  `You have successfully added ${name} business location!`,
  `تمت إضافة فرع ${name} بنجاح!`,
]
export const BUSINESS_LOCATION_SUCCESS_DESC = [
  "You can return to Business Locations or add Delivery Zones for this location.",
  "يمكنك العودة لصفحة الفروع أو إضافة مناطق توصيل لهذا الفرع.",
]
export const BUSINESS_LOCATION_DELETE_CONFIRMATION = [
  "Are you sure you want to delete this Business Location?",
  "هل أنت متأكد أنك تريد حذف هذا الفرع؟",
]

export const BUSINESS_LOCATION_DELETE_NOTIFICATION = [
  "Deleting a branch may take up to 5 minutes",
  "قد يستغرق حذف الفرع مدة تصل إلى 5 دقائق",
]
export const MAX_ORDER_FULFILLMENT_PERIOD = [
  "Max Order Fulfillment Period (Minutes)",
  "أقصى مدة لتنفيذ الطلب (بالدقائق)",
]
export const ORDER_FULFILLMENT_DESCRIPTION = [
  "When an order checkout requires preparation plus delivery time that exceeds this period, ASAP delivery will be disabled. Customer can schedule the order (if its enabled).",
  "عندما يتطلب إعداد الطلب وتوصيله وقتاً أطول من المدة التي حددتها سيتم تعطيل خيار التوصيل في أسرع وقت ممكن وسيتم استبداله بخيار الطلب المسبق (إذا كان متاحاً)  ",
]
export const BUSINESS_LOCATION_UPDATED = [
  "Business location was updated successfully",
  "تم تحديث الفرع بنجاح",
]
export const BUSINESS_LOCATIONS = ["Business Locations", "الفروع"]
export const PUBLIC_BRANCH_CHECKBOX = [
  "Mark as a public location so customers can visit it.",
  "تحديد الفرع كمكان عام يمكن للعملاء زيارته",
]
export const ADD_LOCATION = ["Add location", "إضافة فرع"]
export const ADD_BUSINESS_LOCATION = ["Add Business Location", "إضافة فرع"]
export const NUMBER_OF_BUSINESS_LOCATIONS = (numberOfBranches,planLimit) => [`${numberOfBranches}/${planLimit} location(s) used`,`${numberOfBranches}/${planLimit} موقع (مواقع) المستخدمة`];
export const YOU_CAN_ADD_A_LOCATION = ["You can still add another location" , "لا يزال بإمكانك إضافة فرع آخر"];
export const YOU_HAVE_REACHED_YOUR_PLANS_LIMIT = ["You have reached your plan limit" , "لقد وصلت إلى حد خطتك"];
export const ADD_A_NEW_BRANCH = ["Add a new branch", "إضافة فرع"];
export const PICKUP = ["Pickup", "استلام من الفرع"]
export const PICKUP_SHORT = ["Pickup", "إستلام"]
export const BUSINESS_LOCATIONS_NAME = ["Name", "الاسم"]
export const BASIC_DETAILS = ["Basic details", "التفاصيل الأساسية"]
export const BASIC_DETAILS_DESC = ["Branch name, phone number, and address", "اسم الفرع٬ رقم الهاتف والعنوان"]
export const ADVANCED_CREATION = ["Advanced Creation", "التفاصيل الأساسية"]
export const ADDRESS = ["Address", "العنوان"]
export const SEARCH_LOCATION = ["Search a location", "البحث عن موقع"]
export const SEARCH_AREA_ZONES = area => [`Search in ${area}`, `البحث فى ${area}`]
export const SEARCH_ZONE_NAME = ["Search zone name", "البحث عن المناطق"]
export const AREA_NOT_MATCHING = ["No available zones", "لا توجد مناطق متاحة"]
export const LOAD_MORE = ["Load more", "تحميل المزيد"];
export const CUSTOMER_SERVICE_NUMBER = [
  "Customer service number",
  "رقم خدمة العملاء",
]
export const BRANCH_PHONE_NUMBER = [
  "Branch phone number",
  "رقم هاتف الفرع",
]
export const External_ID = ["External Id", "رقم التعريف الخارجى"]
export const EXTERNAL_ID_DESC = [
  "If you have a code that identifies the item within your business",
  ".فى حالة إستخدام رقم تعريف خاص بمتجرك",
]
export const EXTERNAL_OPTION_ID_DESC = [
  "If you have a code that identifies the option group within your business",
  ".فى حالة إستخدام رقم تعريف خاص بمتجرك",
]
export const ENTER_EXTERNAL_ID = [
  "Enter External Id",
  "ادخل رقم التعريف الخارجى",
]
export const ENTER_CUSTUMER_SERVIVE_NUM = [
  "Enter customer service number",
  "أدخل رقم خدمة العملاء",
]
export const IS_THIS_LOCATION = [
  "Is this a location customers can visit?",
  "هل هذا فرع يمكن للعملاء زيارته؟",
]
export const HELP_CUSTOMERS_VISIT = [
  "Help customers to visit your location accurately",
  "ساعد العملاء على الوصول للفرع بدقة",
]
export const IF_YOU_ENABLED_THIS_FEATURE = [
  "Enabling this feature will allow you to add an accurate location on map so your customers can reach you easily.",
  "تفعيل هذه الخاصية سيسمح لك بتحديد موقع متجرك بدقة وتسهيل الوصول إليه.",
]
export const PIN_LOCATION = ["Pin location", "تحديد الموقع"]
export const PIN_LOCATION_ON_MAP = [
  "Pin location on map",
  "تحديد الموقع على الخريطة",
]
export const PIN_DESCRIPTION = [
  "You can pin the location on the map to help customers find it easily.",
  "يمكنك تحديد الموقع على الخريطة لمساعدة العملاء على الوصول إليه بسهولة.",
]
export const PICK_UP_ORDERS = ["Pickup Orders", "استلام الطلبات من الفرع"]
export const RING_TONE = ["Order Notification","صوت الرنة"]
export const RINGER_SUB_HEAD = ["Select the notification type you would like to receive for new orders","إستمتع بختيار مختلف النغمات"]
export const RINGER_CONTINUOUS_NOTE = ["The ringer will not stop until you interact with the system","لن يتوقف الرنين حتى تتفاعل مع النظام"]
export const RINGER_SINGLE_NOTE = ["You’ll only recieve one ring for each order","ستتلقى رنةٌ واحدًا فقط لكل طلب"]
export const RINGER_CONTINUOUS = ["Continuous","رنةٌ دائمة"]
export const RINGER_SINGLE = ["Single","رنةٌ واحدة"]
export const RINGER_TIP = ["Make sure to have your volume on and sound permissions enabled","تأكد من تفعيل الصوت"]

export const CUSTOMERS_CAN_PICK = [
  "Customers can pick their orders from your location",
  "يمكن للعملاء استلام طلباتهم من الفرع بدلاً من التوصيل",
]
export const YOU_CAN_ALLOW_CUSTOMERS = [
  "Allow your customers to order from the link and get their orders by car pickup or pickup from the business location.",
  "اسمح لعملائك بالطلب من الرابط والحصول على طلباتهم عن طريق الاستلام بالسيارة أو الاستلام من موقع العمل.",
]
export const ALLOW_YOUR_CUSTOMERS_TO = [
  "Allow your customers to order online and have their items delivered to them",
  "سيتمكن العملاء من الطلب عبر الرابط ليتم بعدها توصيل المنتجات إليهم.",
]
export const OPENING_HOURS = ["Opening Hours", "ساعات العمل"]
export const OPENING_HOURS_DESCRIPTION = [
  "You can customize your actual opening hours for each day of the week.",
  "يمكنك تخصيص ساعات العمل الفعلية لكل يوم من أيام الأسبوع",
]

export const YOU_CAN_ADD_AND = [
  "You can add and customize your actual opening hours everyday of the week.",
  "يمكنك تخصيص ساعات العمل الفعلية لكل يوم من أيام الأسبوع.",
]
export const SETUP_BUSINESS_LOCATIONS = [
  "Setup › Business locations",
  "الإعدادات > الفروع ",
]
export const SETUP_CREATE_BUSINESS_LOCATION = [
  "Setup › Business locations › Create",
  "الإعدادات > الفروع > إضافة",
]
export const PICKUP_OPTION = ["Pickup", "استلام"]
export const CAR_PICKUP_OPTION = ["Car Pickup", "استلام بالسيارة"]
export const PICKUP_FOOT_NOTE = [
  "Customers will pickup the order from the business location.",
  "سيستلم العملاء الطلب من موقع العمل.",
]
export const BOTH_OPTION = ["Both", "استلام بالاختيارين"]
export const CAR_PICKUP_FOOT_NOTE = [
  "Customers will pickup the order from the comfort of their car.",
  "سيستلم العملاء الطلب من سياراتهم.",
]

export const BOTH_FOOT_NOTE = [
  "Customers will pickup the order from the comfort of their car or from the business location.",
  "سيستلم العملاء الطلب من سياراتهم او من موقع العمل.",
]
//INTEGRATION ANALYTICS
export const ANALYTICS_TOOLS = ["Analytical Tools", "أدوات إحصائية"]
export const TRACKING_TOOLS = ["Tracking Tools", "أدوات التتبع الإعلانية"]
export const SETUP_ANALYTICS_INTRO = [
  "Analytics tools help you better understand your customers and take action based on insights.",
  "تساعدك أدوات التحليل على فهم عملائك بشكل أفضل وإجراء التغييرات وفقاً للإحصاءات",
]

export const GOOGLE = ['Google', 'جوجل'];
export const SNAPCHAT_DESCRIPTION = ['Track the actions that Snapchatters take on your website.', 'تتبع الإجراءات التي يتخذها مستخدمو سناب شات على موقعك'];
export const TIKTOK_DESCRIPTION = ['Find new customers, optimize your campaigns and measure ad performance.', 'ابحث عن عملاء جدد وحسِّن حملاتك وقياس أداء الإعلانات'];
export const ANALYTICS_DESCRIPTION = ['Track campaigns and manage your ads on Google Analytics.', 'تتبع الزوار وقياس الحملات وإدارة إعلاناتك باستخدام جوجل أناليتكس الجديد'];
export const GOOGLE_ANALYTICS = ['Google Analytics (Universal Analytics)', '(Universal Analytics) تحليلات جوجل'];
export const GOOGLE_ANALYTICS_DESCRIPTION = [
  'Get a deeper understanding of your customers. Google Analytics gives you the free tools you need to analyze data for your storefront in one place.',
  'احصل على فهم أعمق لعملائك. يمنحك برنامج تحليلات جوجل الأدوات المجانية التي تحتاجها لتحليل البيانات لواجهة متجرك في مكان واحد.',
];
export const LEARN_MORE_ABOUT_GOOGLE = [
  "Learn more about Google Analytics",
  "المزيد عن تحليلات جوجل",
]
export const GOOGLE_TRACKING_ID = [
  "UA Tracking ID",
  "كود تتبع تحليلات جوجل",
]
export const GOOGLE_ANALYTICS_NOTE = [
  "Note: Google is discontinuing Universal Analytics starting 1/July/2023 and will stop receiving data. By then you will need to migrate to the newer Google Analytics called Google Analytics 4 (GA4).",
  "ملاحظة: ستتوقف Google عن دعم Universal Analytics اعتبارًا من 1/يوليو/2023 وستتوقف عن تلقي البيانات. بحلول ذلك الوقت، ستحتاج إلى الترحيل إلى إصدار Google Analytics الأحدث المسمى Google Analytics 4 (GA4)."
]
export const IN_ARTICLE = [
  "help center article",
  "في هذه المقالة"
]
export const GOOGLE_TRACKING_ID_LIKE = [
  "The tracking ID is a string like UA-*** ",
  "رقم تعريف التتبع هو سلسلة مثل UA-***",
]

export const GOOGLE_TRACKING_DRIVE_LINK = ["You can get started by","يمكنك البدء باستيراد"];
export const GOOGLE_TRACKING_DRIVE_LEARN_MORE = ["importing this google tag manager template", "هذا النموذج لجوجل تاج مانجر"];
export const GOOGLE_TRACK_ID = ['GA Tracking ID', 'كود تتبع جوجل'];
export const NO_LONGER_SUPPORTED = ['No longer supported', 'لم يعد مدعومًا'];

export const FACEBOOK_TRACK_ID = [
  "Facebook Pixel Tracking ID",
  "كود تتبع فيسبوك بيكسلl",
]
export const FACEBOOK_PIXEL = ['Facebook Pixel', 'فيسبوك بيكسل'];
export const FACEBOOK_PIXEL_DESCRIPTION = [
  'A piece of code that you put on your website that allows you to measure the effectiveness of your advertising by understanding the actions people take on your website.',
  'جزء من التعليمات البرمجية التي تضعها على موقع الويب الخاص بك والتي تسمح لك بقياس فعالية إعلاناتك من خلال فهم الإجراءات التي يتخذها الأشخاص على موقع الويب الخاص بك.',
];
export const FACEBOOK_CAPI = ['Facebook Conversion API', 'واجهة برمجة تطبيقات تحويل Facebook'];
export const FACEBOOK_CAPI_DESCRIPTION = [
  "Facebook's Conversions API creates a direct connection between your pixel on Zyda and Facebook's servers, which help optimize ad targeting, decrease cost per action and measure results across Meta technologies",
  'يُنشئ اتصالاً مباشرًا بين بيكسل زيدا و فيسبوك، مما يساعد على تحسين استهداف الإعلانات وتقليل تكلفة الإجراء وقياس النتائج عبر فيسبوك. تعلم المزيد هنا',
]
export const FACEBOOK_TOKEN = ['Facebook token', 'رمز الفيسبوك'];
export const CAPI_DISABLED_NOTE = ['Add a Facebook Pixel to Enable CAPI', 'أضف فيسبوك بيكسل لتمكين Facebook CAPI'];

export const LEARN_MORE_ABOUT_FACEBOOK = [
  "Learn more about Facebook Pixel",
  "المزيد عن فيسبوك بيكسل",
]
export const FACEBOOK_TRACKING_ID = [
  "Pixel ID",
  "كود تتبع فيسبوك بيكسل",
]
export const FACEBOOK_ID = ["Facebook Pixel ID", "كود فيسبوك بيكسل"]
export const FACEBOOK_TRACKING_ID_LIKE = [
  "The Pixel code is a string like 689928768188000",
  "كود التتبع هو سلسلة مثل 689928768188000",
]
export const SYNC_FACEBOOK_CATALOG = ['Sync Facebook Catalog', 'مزامنة كتالوج فيسبوك'];
export const SYNC_FACEBOOK_CATALOG_DESCRIPTION = [
  'Connect your Zyda menu to your Facebook catalog to allow for different types of ads and sales channels to promote your items, including dynamic ads, Facebook Shops.',
  'قم بتوصيل قائمة زيدا الخاصة بك بكتالوج فيسبوك الخاص بك للسماح لأنواع مختلفة من الإعلانات وقنوات البيع للترويج لعناصرك ، بما في ذلك الإعلانات الديناميكية ومتاجر فيسبوك.',
];

export const SNAPCHAT_PIXEL = ['Snapchat Pixel', 'سناب شات بكسل'];
export const SNAPCHAT_PIXEL_DESCRIPTION = [
  'A piece of JavaScript code placed on your website that allows you to track the actions that Snapchatters take on your website.',
  'قطعة من كود جافا سكريبت موضوعة على موقع الويب الخاص بك تسمح لك بتتبع الإجراءات التي يتخذها مستخدمو سناب شات على موقع الويب الخاص بك.',
];
export const LEARN_MORE_ABOUT_SNAPCHAT = ['Learn more about Snapchat Pixel', 'المزيد عن سناب شات بكسل'];
export const SNAPCHAT_TRACKING_ID = ['Snapchat Pixel Tracking ID', 'رقم تتبّع سناب شات بكسل'];
export const SNAPCHAT_ID = ['Snapchat Pixel ID', 'رقم سناب شات بكسل'];
export const SNAPCHAT_TRACKING_ID_LIKE = [
  "The Pixel code is a string like 5ab3d7a3-75dc-4874-a24d-ee9f164073d6",
  "كود التتبع هو سلسلة مثل 5ab3d7a3-75dc-4874-a24d-ee9f164073d6",
]
export const GTM_TITLE = ['Google Tag Manager', 'جوجل تاج مانجر'];
export const GTM_DESCRIPTION = [
  'Manage measurement codes, code fragments and custom frontend integrations on your storefront',
  'إدارة أكواد القياس وأجزاء الكود والتكاملات المخصصة للواجهة الأمامية على واجهة متجرك.',
];
export const GTM_ID = ["Google Tag Manager ID", "كود جوجل تاج مانجر"]
export const GTM_PLACEHOLDER = ["Example: GTM-NW24FRS", "مثال: GTM-NW24FRS"]
export const GTM_TRACKING_ID = [
  "GTM Tracking ID",
  "كود تتبع جوجل تاج مانجر"
]
export const GTM_HISTORY_DESC = [
  'Make sure to add a "History Change" trigger in Google Tag Manager to capture pageviews. ',
  'تأكد من إضافة "History Change trigger" في برنامج إدارة العلامات من Google لتسجيل مرات مشاهدة الصفحة. '
]
export const GTM_HISTORY_LINK = [
  "Learn how to do this here",
  "تعلم كيف تفعل هذا"
]
export const GTM_TRACKING_ID_LIKE = [
  "The tracking code is a string like GTM-NW24FRS",
  "كود التتبع هو سلسلة مثل GTM-NW24FRS"
]
export const LEARN_HOW_TO_GET_THIS_CODE_HERE = [
  'Learn how to get this code here',
  'تعلم كيفية الحصول على هذا الرمز هنا',
];
export const LEARN_MORE_ABOUT_GTM = [
  "Learn more about Google Tag Manager",
  "المزيد عن جوجل تاج مانجر"
]
export const ERROR_MSG_GTM = [
  "Google Tag Manager ID is not correct.",
  "كود التتبع غير صحيح",
]

export const ERROR_MSG_GOOGLE_ANALYTICS = [
  'Please use a valid Google Universal Analytics code and not a GA4 code.',
  'يُرجى استخدام رمز Google Universal Analytics صالح. وليس رمز GA4.',
];
export const ERROR_MSG_FACEBOOK_PIXELS = [
  "Facebook Pixel Tracking ID is not correct.",
  "كود التتبع غير صحيح",
]
export const ERROR_MSG_SNAPCHAT_PIXELS = [
  "Snapchat Pixel Tracking ID is not correct.",
  "كود التتبع غير صحيح",
]

// Orders Managements
export const ORDERS_MANAGEMENT = ["Orders Management", "إدارة الطلبات"]
export const SCHEDULING = ["Scheduling", "طلب مسبق"]
export const ALERTS = ["Alerts", "تنبيهات"]
export const ASAP = ["ASAP", "في أسرع وقت"]
export const AS_SOON_AS_POSSIBLE = ["As soon as possible", "في أسرع وقت"]
export const ASAP_ALLOW_CUSTOMERS = [
  "This allows customers to receive their orders as soon as possible.",
  "هذا يسمح للعملاء بتلقي طلباتهم في أقرب وقت ممكن.",
]
export const LATER = ["Later", "طلب مسبق"]
export const LATER_ALLOW_CUSTOMERS = [
  "This allows customers to schedule orders ahead of time.",
  "هذا يسمح للعملاء للطلب لوقت لاحق.",
]
export const TIME_SLOT_INTERVAL = ["Time Slot Interval", "فترة الفاصل الزمني"]
export const TIME_INTERVAL_BETWEEN = [
  "Time intervals between scheduling hours",
  "الفترات الزمنية بين ساعات الجدولة.",
]
export const MAX_SCHEDULE_TIME = ["Max Schedule Time", "أقصى وقت للجدول الزمني"]
export const MAX_TIME_ORDER = [
  "Max time to order ahead",
  "أقصى وقت للطلب المسبق",
]
export const TIME_SLOT_INTERVAL_REQ = [
  "Time Slot Interval is required",
  "فترة الفاصل الزمني مطلوبة",
]
export const MAX_SCHEDULE_TIME_REQ = [
  "Max Schedule Time is required",
  "مطلوب أقصى وقت للجدول الزمني",
]
export const ORDER_MANAGEMENT_UPDATED = [
  "Orders Managements updated successfully",
  "تم تحديث إدارة الطلبات بنجاح",
]
export const ORDER_MANAGEMENT_WARNING = [
  "If you disable both asap and later, users will not be able to order",
  "إذا قمت بتعطيل كل من خاصية في أسرع وقت ولاحقًا ، فلن يتمكن المستخدمون من الطلب",
]
export const ACCEPTANCE_THRESHOLD_TITLE = [
  "Max Acceptance Threshold",
  "الحد الأقصى للقبول",
]
export const ACCEPTANCE_THRESHOLD_DESCRIPTION = [
  "Set the max time it takes to accept an order. You will be notified once the order exceeds that time.",
  "ضبط الحد الأقصى للوقت المستغرق لأمر القبول. سيتم اخطارك بمجرد تجاوز الطلب لذلك الوقت.",
]
export const EMAIL_NOTIFICATION = ["Email Notifications", "اشعار بريد الكتروني"]
export const  EMAIL_AND_SMS_NOTIFICATION = ["Email & SMS", 'بريد الكتروني و رسائل نصية']

export const TIMING = ["Timing", "التوقيت"]
export const TIMING_SUBTITLE = [
  "Set how long it takes to prepare the order by default. You can set specific preparation times for items from the catalog.",
  "حدد المدة التي يستغرقها إعداد الطلب افتراضيًا. يمكنك تعيين أوقات تحضير محددة للمنتجات من قائمة المنتجات.",
]
export const PREPARATION_TIME_FIELD_TITLE = [
  "Default Preparation Time",
  "وقت التحضير الافتراضي",
]
export const GIFT_ORDERING = ["Gift Ordering", "طلبات الهدايا"]
export const GIFT_ORDERING_DESCRIPTION = [
  "Enable customers to order gifts and add recipient information",
  "يمكن لعملائك إرسال الطلبات كهدايا و إضافة بيانات مستلم الهدية",
]
export const WORKFLOW = ["Workflow", "سير العمل"]
export const AUTO_ACCEPT_ORDER = ["Auto Accept Orders", "قبول الطلبات تلقائيًا"]
export const THIS_ALLOW_ORDER_AUTOMATIC = [
  "This will allow the orders to be automatically accepted once the order submitted & has been paid.",
  "سيسمح هذا بقبول الطلبات تلقائيًا بمجرد اتمام الطلب ودفعه.",
]
export const AUTO_ACCEPT_NOT_AVAILABLE = [
  "Auto-accept is not available with courier",
  "القبول التلقائي غير متاح مع شركة الشحن",
]

export const USER_RING_FREQUENCY_UPDATED = [
  "Your order ring frequency changed successfully.",
  "تم تغيير تردد رنين الطلب الخاص بك بنجاح",
]

export const USERS = ["Users", "المستخدمين"]

export const YES = ["Yes", "نعم"]

export const SEARCH_RESULTS = ["Search results", "نتائج البحث"]
export const FILTER_RESULTS = ["Filter results", "تصنيف النتائج"]

export const ZYDA = ["Zyda", "زيدا"]

export const ALL = ["All", "الكل"]

export const AVAILABLE = ["Available", "متاح"]
export const UNAVAILABLE = ["Unavailable", "غير متاح"]
export const ASSIGNED = ["Assigned", "سوف يوصل طلب"]
export const IN_DELIVERY = ["In Delivery", "يوصل طلب"]
export const ASSIGNED_ORDERS = numberOfAssignedOrders => [
  `${numberOfAssignedOrders} ${numberOfAssignedOrders === 1 ? 'order' :  'orders'}`,
  `${numberOfAssignedOrders} ${numberOfAssignedOrders > 2 && numberOfAssignedOrders < 11 ? 'طلب' : 'طلبات'}`,
];
export const ACTIVE_RIDERS = numberOfActiveRiders => [
  `Your riders • ${numberOfActiveRiders} available`,
  `السائقين • ${numberOfActiveRiders} ${numberOfActiveRiders > 1 ? 'متاحين' : 'متاح'}`
];
export const ACTIVE_COURIERS = numberOfActiveCouriers => [
  `Your couriers • ${numberOfActiveCouriers} available`,
  `مقدمين الخدمة • ${numberOfActiveCouriers} ${numberOfActiveCouriers > 1 ? 'متاحين' : 'متاح'}`
];
export const ACTIVE_VERD_NETWORK = numberOfActiveCouriers => [
  `Verd network • ${numberOfActiveCouriers} available`,
  `شبكة توصيل (verd) • ${numberOfActiveCouriers} ${numberOfActiveCouriers > 1 ? 'متاحين' : 'متاح'}`
];
export const DELIVERY_ESTIMATIONS = deliveryTime => [
  `Delivers Within ${deliveryTime} min`,
  `التوصيل خلال ${deliveryTime} دقيقة`,
];
export const PICKUP_ESTIMATIONS = pickupTime => [
  `Pickup Within ${pickupTime} min`,
  `الاستلام خلال ${pickupTime} دقيقة`,
];
export const NO_COURIER_ESTIMATION = [
  'No time and cost estimate',
  'لا يوجد وقت او تكلفة مقدرة',
];
export const PAYMENT_TYPE_NOT_SUPPORTED = [
  'Payment type is not supported',
  'طريقة الدفع غير مدعومة',
];
export const MAX_CAPACITY = ["Max capacity", "الحد الاقصى"]

export const PUBLIC_LOCATION = ["Public location", "مكان عام"]
export const PRIVATE_LOCATION = ["Private location", "مكان خاص"]
export const CURRENTLY_OFFLINE = [
  "You are currently offline, please check your connection!",
  "أنت غير متصل حاليًا، الرجاء التحقق من اتصالك بالانترنت!",
]
export const CUSTOM_DATE = ["Custom Date", "تاريخ مخصص"]

//STOCK_MANAGEMENT
export const STOCK_MANAGEMENT = ["Stock Management", "إدارة المخزون"]
export const STOCK_VARIANTS = ["Variants", "المتغيرات"]
export const STOCK_GENERAL_SETTINGS = ["Settings", "الاعدادات"]
export const STOCK_HISTORY_LOG = ["Stock History", "تاريخ المخزون"]
export const VIEW_HISTORY = ["View Stock History", "عرض تاريخ المخزون"]
export const WARNINIG_THRESHOLD = ["Warning Threshold", "المخزون منخفض"]
export const LOW_STOCK = ["Low Stock", "المخزون منخفض"]
export const LOW_STOCK_EX = ["Ex: 60", "مثال: ٦٠"]
export const LOW_STOCK_NOTIFY = [
  "The value at which the system warns you that item availabilty is low.",
  "أعلمني عندما تكون الكمية الموجودة في المخزن أقل من",
]
export const LOW_STOCK_ALERT = [
  "Set an alert when the stock reaches below a certain limit.",
  "تنبيه عندما يصل المخزون إلى أقل من حد معين.",
]
export const DISABLE_STOCK_CONFIRM = [
  "Are you sure you want to disable stock management?",
  "هل أنت متأكد من أنك تريد تعطيل ادارة المخزون ؟",
]
export const DISABLE_STOCK_NOTE = [
  "Note: Disabling stock won't let you lose your stored data",
  "تعطيل ادارة المخزون لن يجعلك تخسر بياناتك",
]
export const STORE_MESSAGE = ["Limited Availabilty Status", "الرسالة الظاهرة على المتجر"]
export const STORE_MESSAGE_CONTENT = [
  "Displayed item status, on the store web page, when item availabilty threshold is reached. ",
  "رسالة تظهر للعميل عندما تكون الكمية أقل من الحد الأدنى.",
]
export const STORE_MESSAGE_FEW_ITEMS = [
  "Only few items left.",
  "متبقي عدد قليل",
]
export const STORE_MESSAGE_LESS_THAN = QUANTITY => [
  `Only ${QUANTITY}  left in stock.`,
  `تبقي ${QUANTITY} فقط في المخزون`,
]

export const ENABLE_STOCK_TITLE = [
  "Enable Stock Management",
  "تفعيل إدارة المخزون",
]
export const ENABLE_STOCK_DESC = [
  "Keep track of your inventory in your store.",
  "تتبع المخزون الخاص بك في متجرك.",
]
export const ENABLE_STOCK_BTN = [
  "Enable Stock Management",
  "تفعيل إدارة المخزون",
]
export const DISABLE_STOCK_BTN = [
  "Disable Stock Management",
  "تعطيل إدارة المخزون",
]

export const IN_STOCK = ["In stock", "متوفر"]
export const OUT_STOCK = ["Out of stock", "غير متوفر"]
export const TRACK_QUANTITY = ["Track quantity", "تتبع الكمية"]
export const STATUS_STOCK = ["Status", "الحالة"]
export const SEARCH_STOCK = [
  "Variant, Item, SKU or Barcode, Order number",
  "متغير، منتج، كود تعريف البضاعة، باركود، رقم الطلب",
]
export const SKU = ["SKU", "كود تعريف البضاعة"]
export const BARCODE = ["Barcode", "باركود"]
export const QUANTITY = ["Quantity", "كمية"]
export const VARIANT_PRODUCT = ["Variant / Item", "متغير / منتج"]
export const VALUE = ["Value", "القيمة"]
export const UPDATED_BY = ["Updated by", "تم التحديث بواسطة"]
export const CHANGED_DATE = ["Changed date", "تاريخ التحديث"]
export const UPDATED_BY_USER = ["User Name", "اسم المستخدم"]
export const UPDATED_BY_ORDER = ["Order number", "رقم الطلب"]

export const pageNames = {
  home: ["Home", "الرئسية"],
  menu: ["Menu", "القائمة"],
  overview: ["Overview" , "نظرة عامة"],
  stockManagementVariant: ["Stock Management", "إدارة المخزون"],
  stockManagementSettings: ["Settings", "الإعدادات"],
  stockManagementHistoryLog: ["History Log", "تاريخ المخزون"],
  allOrders: ["Orders search", "البحث عن الطلبات"],
  currentOrders: ["Orders search", "البحث عن الطلبات"],
  pastOrders: ["Past orders", "الطلبات السابقة"],
  pendingOrders: ["Pending orders", "طلبات قيد التنفيذ"],
  acceptedOrders: ["Accepted orders", "طلبات مقبولة"],
  readyOrders: ["Ready orders", "طلبات جاهزة"],
  dispatchedOrders: ["Dispatched orders", "طلبات تم إرسالها"],
  pickedUpOrders: ["Picked up orders", "طلبات تم استلامها"],
  deliveredOrders: ["Delivered orders", "طلبات تم توصيلها"],
  canceledOrders: ["Canceled orders", "طلبات تم إلغائها"],
  refundedOrders: ["Refunded orders", "طلبات تم استردادها"],
  completedOrders: ["Completed orders", "طلبات مكتملة"],
  salesAnalytics: ["Sales Analytics", "تقارير المبيعات"],
  salesBreakdown: ["Sales Breakdown", "تفاصيل المبيعات"],
  behaviorBreakdown: ["Behavior Breakdown", "التقارير المفصّلة"],
  bestSelling: ["Best Selling", "الأكثر مبيعاً"],
  productsAnalytics: ["Products Analytics", "تقارير المنتجات"],
  geoAnalytics: ["Branches & Delivery Areas", "الفروع و مناطق التوصيل"],
  waitingForPaymentOrders: [
    "Orders with pending payments",
    "طلبات بانتظار الدفع",
  ],
  paymentFailedOrders: [
    "Orders with failed payments",
    "طلبات بعمليات دفع غير ناجحة",
  ],
  reports: ["Reports", "تقارير"],
  salesReports: ["Sales reports", "تقارير المبيعات"],
  customersReports: ["Customers reports", "تقارير العملاء"],
  setup: ["Setup", "الإعدادات"],
  ordersManagement: ["Orders management", "إدارة الطلبات"],
  integrations: ["Integrations", "دمج الخدمات"],
  businessInformation: ["Business Information", "بيانات النشاط التجاري"],
  branding: ["Branding", "العلامة التجارية"],
  branchList: ["Branches", "قائمة الفروع"],
  branchCreation: ["Create Business Location", "إضافة فرع"],
  branchCreationSuccess: [
    "Business Location created successfully",
    "تم إنشاء الفرع بنجاح",
  ],
  branchEdition: ["Edit Business Location", "تعديل الفرع"],
  deliveryList: ["Delivery Zones", "مناطق التوصيل"],
  deliveryCreation: ["Create Delivery Zone", "إضافة منطقة توصيل"],
  deliveryEdition: ["Edit Delivery Zone", "تعديل منطقة توصيل"],
  deliveryZoneTemplates: ['Delivery Zone Templates', 'قوالب منطقة التسليم'],
  analytics: ["Analytics", "أدوات التحليل"],
  product: ["Products", "المنتجات"],
  menuOptionGroups: ["Option Groups", "مجموعات الخيارات"],
  productsCatalog: ["Catalog", "قائمة المنتجات"],
  baseProductCreation: ["Add Item", "إضافة منتج"],
  advancedProductCreation: ["Add Item", "إضافة المنتج"],
  previewProductCreation: ["Preview Item", "معاينة المنتج"],
  productEdition: ["Edit Item", "تعديل المنتج"],
  ordersManagementScheduling: ["Scheduling", "إدارة الطلبات المسبقة"],
  storeLayout: ["Layout", "تصميم"],
  signIn: ["Sign in", "تسجيل الدخول"],
  signUp: ["Sign up", "إنشاء حساب"],
  forgetPassword: ["Forgot password?", "نسيت كلمة السر؟"],
  resetPassword: ["Reset password", "تغيير كلمة السر"],
  storeCreation: ["Store creation", "إنشاء المتجر"],
  gettingStarted: ["Getting started", "بدء الإعداد"],
  socialmediatracking: ["Tracking & Social Media", "التتبع ووسائل التواصل الاجتماعي"],
  engageautopilot: ["Engage Autopilot", "إشراك الطيار الآلي"],
  qrCode: ["QR Code", 'رمز الاستجابة السريعة'],
  paymentMethods: ["Payment Methods", "طرق الدفع"],
  onlinePaymentCreationForm: ["Payment Methods Creation", "إضافة طرق الدفع"],
  onlinePaymentEditionForm: ["Edit Payment Methods", "تعديل طرق الدفع"],
  webLinks: ["Web Links", "روابط خارجية"],
  marketing: ["Marketing", "التسويق"],
  vouchers: ["Vouchers", "القسائم الشرائية"],
  allVouchers: ["All Vouchers", "كل القسائم"],
  activeVouchers: ["Active Vouchers", "القسائم المفعلة"],
  deactivatedVouchers: ["Deactivated Vouchers", "القسائم المتوقفة"],
  scheduleVouchers: ["Schedule Vouchers", "القسائم المجدولة"],
  expiredVouchers: ["Expired Vouchers", "القسائم المنتهية"],
  campaigns: ["SMS Campaigns", "حملات الرسائل النصية"],
  customers: ["Customers", "عملاء"],
  customerProfile: ["Customer Profile", "ملف التعريف"],
  links: ['Links', 'روابط'],
  loyalty: ['Loyalty', 'برامج الولاء'],
  wallet: ['Wallet', 'محفظه']
}

export const breadcrumbs = {
  OVERVIEW: [["Overview"],["نظرة عامة"]],
  ORDERS: [["Orders"], ["الطلبات"]],
  ANALYTICS: [["Analytics"], ["التحليلات"]],
  SALES_ANALYTICS: [
    ["Analytics", "Sales Analytics"],
    ["التحليلات", "تحليلات المبيعات"],
  ],
  SALES_BREAKDOWN: [
    ["Sales Analytics", "Sales Breakdown"],
    ["التحليلات", "تفاصيل المبيعات"],
  ],
  BEST_SELLING: [
    ["Analytics", "Best Selling"],
    [, "التحليلات", "الأكثر مبيعاً"],
  ],
  PRODUCTS_ANALYTICS: [
    ["Analytics", "Best Selling", "Items"],
    ["التحليلات", "الأكثر مبيعاً", "المنتجات"],
  ],
  GEO_ANALYTICS: [
    ["Analytics", "Branches & Delivery Areas"],
    ["التحليلات", "الفروع و مناطق التوصيل"],
  ],
  GEO_ANALYTICS_ZONES: [
    ["Analytics", "Branches & Delivery Zones"],
    ["التحليلات", "الفروع و مناطق التوصيل"],
  ],
  GEO_ANALYTICS_BRANCHES: [
    ["Analytics", "Branches & Delivery Areas", "Branches"],
    ["التحليلات", "الفروع و مناطق التوصيل", "الفروع"],
  ],
  GEO_ANALYTICS_AREAS: [
    ["Sales Analytics", "Branches & Delivery Areas", "Delivery Areas"],
    ["التحليلات", "الفروع و مناطق التوصيل", "مناطق التوصيل"],
  ],
  GEO_ANALYTICS_AREAS_ZONES: [
    ["Sales Analytics", "Branches & Delivery Zones", "Delivery Zones"],
    ["التحليلات", "الفروع و مناطق التوصيل", "مناطق التوصيل"],
  ],
  BEHAVIOR_BREAKDOWN: [
    ["Analytics", "Behavior Breakdown"],
    ["التحليلات", "التقارير المفصّلة"],
  ],
  DELIVERY_VS_PICKUP: [
    ["Analytics", "Behavior Breakdown", "Delivery vs Pickup"],
    ["التحليلات", "التقارير المفصّلة", "التوصيل مُقابِل الاستلام"],
  ],
  PAYMENT_METHODS: [
    ["Analytics", "Behavior Breakdown", "Payment Methods"],
    ["التحليلات", "التقارير المفصّلة", "طرق الدفع"],
  ],
  NEW_VS_RETURNING: [
    ["Analytics", "Behavior Breakdown", "New vs Returning"],
    ["التحليلات", "التقارير المفصّلة", "الجدد مقابل العائدين"],
  ],
  REPORTS: [["Reports"], ["التقارير"]],
  SETUP_BUSINESS_INFORMATION: [
    ["Setup", "Business Information"],
    ["الإعدادات", "بيانات النشاط التجاري"],
  ],
  SETUP_BUSINESS_LOCATIONS: [
    ["Setup", "Business Locations"],
    ["الإعدادات", "الفروع"],
  ],
  SETUP_CREATE_BUSINESS_LOCATION: [
    ["Setup", "Business Locations", "Add business location"],
    ["الإعدادات", "الفروع", "إنشاء فرع"],
  ],
  SETUP_EDIT_BUSINESS_LOCATION: [
    ["Setup", "Business Locations", "Edit business location"],
    ["الإعدادات", "الفروع", "تعديل الفرع"],
  ],
  SETUP_DELIVERY_LIST: [
    ["Setup", "Delivery"],
    ["الإعدادات", "التوصيل"],
  ],
  SETUP_DELIVERY_CREATION: [
    ["Setup", "Delivery", "Add delivery zone"],
    ["الإعدادات", "التوصيل", "إضافة منطقة توصيل"],
  ],
  SETUP_DELIVERY_EDITION: [
    ["Setup", "Delivery", "Zones"],
    ["الإعدادات", "التوصيل", "مناطق التوصيل"],
  ],
  SETUP_BRANDING: [
    ["Setup", "Branding"],
    ["الإعدادات", "العلامة التجارية"],
  ],

  SETUP_PRODUCTS_OPTION_GROUPS: [
    ["Menu", "Option Groups"],
    ["القائمة", "مجموعات الخيارات"],
  ],
  SETUP_PRODUCTS_CATALOG: [
    ["MENU", "Catalog"],
    ["القائمة", "قائمة المنتجات"],
  ],
  SETUP_PRODUCT_CREATION: [
    ["Products", "Catalog", "Add item"],
    ["المنتجات", "قائمة المنتجات", "إضافة منتج"],
  ],
  SETUP_PRODUCT_EDITION: [
    ["Products", "Catalog", "Edit item"],
    ["المنتجات", "قائمة المنتجات", "تعديل المنتج"],
  ],
  SETUP_STOCK_SETTINGS: [
    ["Menu", "Stock Settings"],
    ["القائمة", "  إعدادات المخزون"],
  ],
  SETUP_STOCK_HISTORY: [
    ["Menu", "Stock History"],
    ["القائمة", " تاريخ المخزون"],
  ],
  SETUP_ORDERS_MANAGEMENT: [
    ["Setup", "Orders Management"],
    ["الإعدادات", "إدارة الطلبات"],
  ],
  SETUP_INTEGRATIONS: [
    ["Setup", "Integrations"],
    ["الإعدادات", "دمج الخدمات"],
  ],
  SOCIAL_MEDIA_TRACKING: [
    ["Marketing","Tracking & Social Media"],
    ["التسويق","التتبع ووسائل التواصل الاجتماعي"]
  ],
  QR_CODE: [
    ['Marketing', 'QR Code'],
    ['التسويق', 'رمز الاستجابة السريعة']
  ],
  SETUP_STORE_LAYOUT: [
    ["Setup", "Store Theme"],
    ["الإعدادات", "تصميم"],
  ],
  SETUP_PAYMENT_METHODS: [
    ["Setup", "Payment Gateways"],
    ["الإعدادات", "طرق الدفع"],
  ],
  INTEGRATE_PAYMENT_GATEWAY: ({ ar, en }) => [
    ["Setup", "Payment Gateways", en],
    ["الإعدادات", "طرق الدفع", ar],
  ],
  SETUP_STOCK_MANAGEMENT: [
    ["Setup", "Stock Management"],
    ["الإعدادات", " إدارة المخزون"],
  ],
  SETUP_CREATE_PAYMENT_METHODS: [
    ["Setup", "Payment Gateways", "Add Payment Gateway"],
    ["الإعدادات", "طرق الدفع", "إضافة طرق الدفع"],
  ],
  SETUP_EDIT_PAYMENT_METHODS: [
    ["Setup", "Payment Gateways", "Edit Payment Gateways"],
    ["الإعدادات", "طرق الدفع", "تعديل طرق الدفع"],
  ],
  TEAM_CREATE_USER: [
    ["Team", "Add New User"],
    ["الفريق", "إضافة مستخدم جديد"],
  ],
  TEAM_EDIT_USER: [
    ["Team", "Edit User"],
    ["الفريق", "تعديل اسم المستخدم"],
  ],
  VERD_CREATE_RIDER: [
    ["VERD", "Add New Rider"],
    ["VERD", "إضافة سائق جديد"],
  ],
  VERD_EDIT_RIDER: [
    ["VERD", "Edit Rider"],
    ["VERD", "تعديل اسم السائق"],
  ],

  Billing_and_Plans: [
    ["", "Billing and Plans"],
    ["", "الفواتير و الباقات"],
  ],
  SALES_REPORT: [
    ["", "Sales Report"],
    ["", "المبيعات"],
  ],
  Invoices_History: [
    ["Billing and Plans", "Invoices History"],
    ["الفواتير و الباقات", "تاريخ الفواتير"],
  ],
  Renew_Subscription: [
    ["Bills and Plans", "Renew Subscription"],
    ["الفواتير و الباقات", "تجديد الإشتراك"],
  ],
  EDIT_USER_INFO: [
    ["", "Edit User"],
    ["", "تعديل المستخدم"],
  ],
  CHANGE_PASSWORD: [
    ["Edit User", "Change Password"],
    ["تعديل المستخدم", "تغير كلمة السر"],
  ],
  SETUP_WEB_LINKS: [
    ["Setup", "Web Links"],
    ["الإعدادات", "روابط خارجية"],
  ],
  SETUP_ADD_WEB_LINK: [
    ["Setup", "Web Links", "Add New Link"],
    ["الإعدادات", "روابط خارجية", "إضافة رابط جديد"],
  ],
  SETUP_EDIT_WEB_LINK: [
    ["Setup", "Web Links", "Edit Web Link"],
    ["الإعدادات", "روابط خارجية", "تعديل الرابط الإضافي"],
  ],
  MARKETING_VOUCHERS: [
    ["Marketing", "Vouchers"],
    ["التسويق", "القسائم الشرائية"],
  ],
  TEAM: [["Team"], ["الفريق"]],
  VERD: [['Verd'], ["Verd"]],
  MARKETING_ADD_VOUCHER: [
    ["Marketing", "Vouchers", "Add Voucher"],
    ["التسويق", "القسائم", "إضافة قسيمة"],
  ],
  MARKETING_EDIT_VOUCHER: [
    ["Marketing", "Vouchers", "Edit Voucher"],
    ["التسويق", "القسائم الشرائية", "تعديل القسيمة"],
  ],
  MARKETING_CAMPAIGNS: [
    ["Marketing", "SMS Campaigns"],
    ["التسويق", "حملات الرسائل النصية"],
  ],
  MARKETING_AUTO_PILOT: [
    ["Marketing", "Engage Autopilot"],
    ["التسويق", "إشراك الطيار الآلي"],
  ],
  MARKETING_ADD_CAMPAIGN: campaignName => [
    ["Marketing", "SMS Campaigns", campaignName ? campaignName : "Create new campaign"],
    ["التسويق", "حملات الرسائل النصية", campaignName ? campaignName : "إنشاء حملة جديدة" ],
  ],
  MARKETING_EDIT_CAMPAIGN: [
    ["Marketing", "SMS Campaigns", "Edit campaign"],
    ["التسويق", "حملات الرسائل النصية", "تعديل الحملة"],
  ],
  CUSTOMERS: [["Customers"], ["عملاء"]],
  CUSTOMER_PROFILE: customerName => [
    ["Customers", `${customerName}`],
    ["عملاء", `${customerName}`],
  ],
  ORDERFAST_LINKS: [["Orderfast Links"], ["روابط Orderfast"]],
  LOYALTY: [["Orderfast Loyalty"], ["برامج الولاء"]],
  WALLET: [["Orderfast Wallet"], ["محفظه Orderfast" ]],
}
export const OF_TOTAL = ['of total', 'من الكل'];

export const GET = ["Get", "حمل"]
export const DOWNLOAD_ZYDA_APP = ["Download Zyda App", "حمل تطبيق زيدا"]

export const STORE_LAYOUT = ["Store Theme", "تصميم المتجر"]
export const STORE_LAYOUT_UPDATED = [
  "Store Layout updated",
  "تم تحديث تصميم المتجر",
]
export const HOME_LAYOUT = ["Home layout", " تصميم الصفحة الرئيسية"]
export const PRODUCT_LAYOUT = ["Product layout", "تصميم صفحة القسم  "]
export const RECOMMENDED_FOOD_INDUSTRY = [
  "Recommended for Food Industry.",
  "يوصى به للصناعات الغذائية.",
]
export const RECOMMENDED = ["Recommended", "يوصى به"];
export const RECOMMENDED_GROCERY_COSMETICS_INDUSTRY = [
  "Recommended for Grocery & Cosmetics Industry.",
  "يوصى به لصناعة البقالة ومستحضرات التجميل.",
]
export const RECOMMENDED_FASHION_INDUSTRY = [
  "Recommended for Fashion Industry.",
  "يوصى به لصناعة الأزياء.",
]
export const EMAIL_PLACEHOLDER = [
  "Enter your email address.",
  "أدخل عنوان بريدك الالكتروني",
]
export const MERCHANT_ID_PLACEHOLDER = [
  "Enter your Merchant Id",
  "أدخل الرقم التعريفي للمتجر",
]
export const CARD_INTEGRATION_ID_PLACEHOLDER = [
  "Enter your Card Integration id",
  "أدخل رقم التكامل للكارت",
]
export const IFRAME_ID_PLACEHOLDER = ["Enter your Iframe id", "أدخل رقم إفريم"]
export const API_KEY_PLACEHOLDER = ["Enter your API KEY", "أدخل الرقم إفريم"]
export const CHOOSE_STORE_HOME_LAYOUT = [
  "Choose store home layout theme",
  "اخترتصميم الصفحة الرئيسية للمتجر ",
]
export const CHOOSE_STORE_CATEGORY_LAYOUT = [
  "Choose store category layout theme",
  "اخترتصميم صفحة القسم للمتجر",
]
export const LEGAL_LICENSE_NUMBER_DECIMAL = [
  "License Number should be only numbers",
  "رقم الرخصة يجب أن يتكون من أرقام",
]
export const LEGAL_VAT_ID_NUMBER_DECIMAL = [
  "VAT ID Number should be only numbers",
  "رقم حساب ضريبة القيمة المضافة يجب أن يتكون من أرقام",
]
export const VISIT_STORE = ["Visit Store", "زيارة الموقع"]

export const PAYMENT_METHODS = ["Payment Methods", "طرق الدفع"]
export const ONLINE_PAYMENT = ["Online Payment", "الدفع أونلاين"]
export const MANUAL_PAYMENT_METHODS = [
  "Manual Payment Methods",
  "طرق الدفع اليدوية",
]
export const ONLINE_PAYMENT_METHODS = [
  "Online Payment Gateways",
  "طرق الدفع المتقدمة",
]
export const PAYMENT_METHODS_PROVIDED_BY = ({ ar, en }) => [
  `Payment methods provided by ${en}`,
  `وسائل الدفع المقدمة من ${ar}`,
]
export const TERMS_AND_CONDITIONS = ["Terms and conditions", "الشروط و الاحكام"]
export const PRICING_URL = ["Check pricing list", "قائمة الاسعار"]
export const WORLDWIDE = ["Worldwide", "جميع أنحاء العالم"]
export const ONLINE_PAYMENT_DESCRIPTION = [
  "We partnered with Tap Payments to empower you with competitive fees and shorter settlement windows.",
  "أقل رسوم بنكية و أسرع تحويلات مع تاب بيمنتس",
]
export const CASH_DELEVERY = ["Cash on Delivery", "كاش عند الاستلام"]
export const CASH_DELEVERY_DESCRIPTION = [
  "Allow your customer to pay for thier purchaes via cash upon the delivery of their orders.",
  "تستطيع ايقاف الكاش عند الاستلام فقط بعد تفعيل الدفع اونلاين",
]
export const CARD_DELEVERY = ["Card on Delivery", "الدفع بالبطاقة عند الاستلام"]
export const CARD_DELEVERY_DESCRIPTION = [
  "Allow your customer to pay for their purchases via card upon the delivery of their orders.",
  ".وفر لعملائك إمكانية الدفع بالبطاقة عند الاستلام",
]
export const CREDIT_CARD_TITLE = ["Credit Card", "بطاقة الائتمان"]
export const CHECK_PAYMENT_GATEWAYS = [
  "You can check payment gateways provided by Tap",
  "خيارات الدفع المتوفرة من قبل تاب بيمنتس",
]
export const PAYMENT_GATEWAY = ["Payment Gateway", "بوابة الدفع"]
export const PAYMENT_GATEWAYS = ["Payment Gateways", "بوابات الدفع"]
export const FEES = ["Fees", "الرسوم"]
export const SETTLEMENT_WINDOW = ["Settlement Window", "فترة التسوية"]
export const THREE_WORKING_DAYS = ["3 working days", "3 أيام عمل"]
export const FIVE_WORKING_DAYS = ["5 working days", "5 أيام عمل"]
export const ONE_PERCENT_TRANSACTION = [
  "1% per transaction, minimum of 100 fils.",
  "1% على كل عملية، حد أدنى 100 فلس ",
]
export const TWO_HALF_PERCENT_TRANSACTION = [
  "2.5% per transaction, no minimum.",
  "2.5% على كل عملية، بدون حد أدنى",
]
export const BANK_INFO = ["Bank Info", "بيانات حسابك البنكي"]
export const BANK_NAME = ["Bank name", "اسم البنك"]
export const BANK_ADDRESS = ["Bank address", "عنوان البنك"]
export const BANK_NAME_PLACE_HOLDER = [
  "Ex. National Bank",
  "مثال: البنك الوطني",
]
export const BANK_ADDRESS_PLACE_HOLDER = [
  " Enter your official Bank Address",
  "أدخل عنوان البنك الرسمي",
]
export const BANK_INFO_DESCRIPTION = [
  "Enter the information of your company bank account if you have a commercial license, if not, enter the information of your personal bank account.",
  "أدخل بيانات البنك لحساب الشركة، أو حسابكم الشخصي",
]
export const ACCOUNT_TYPE = ["Account Type:", "نوع الحساب"]
export const ACCOUNT_NAME = ["Account Name", "اسم الحساب"]
export const ACCOUNT_NAME_SUBTITLE = [
  "The Account holder name as written in the bank in English.",
  "اسم صاحب الحساب كما هو مدون لدى البنك بالانجليزي",
]
export const ACCOUNT_NAME_PLACE_HOLDER = [
  "Enter your account name",
  "أدخل اسم الحساب",
]
export const COMPANY_ACCOUNT_NAME = ["Company account name", "اسم حساب الشركة"]
export const COMPANY_ACCOUNT_NAME_PLACE_HOLDER = [
  "Enter your company account name",
  "أدخل اسم حساب الشركة",
]
export const COMPANY_ACCOUNT_NAME_SUBTITLE = [
  "The Company Name must be as written in the bank in English.",
  "اسم الشركة يجب أن يكون كما هو مدون لدى البنك بالانجليزية",
]
export const ACCOUNT_TYPE_INDIVIDUAL = ["Individual", "فرد"]
export const ACCOUNT_TYPE_COMPANY = ["Company", "شركة"]
export const ACCOUNT_NUMBER = ["Account number", "رقم الحساب"]
export const ACCOUNT_NUMBER_PLACE_HOLDER = [
  "Ex. 1246546546546",
  "1234567890  مثال ",
]
export const BENEFICIARY_ADDRESS = ["Beneficiary address", "عنوان المستفيد"]
export const BENEFICIARY_ADDRESS_PLACE_HOLDER = [
  " Enter your beneficiary address",
  "أدخل عنوان المستفيد",
]

export const BENEFICIARY_ADDRESS_SUBTITLE = [
  "The address of the account holder",
  "عنوان صاحب الحساب",
]
export const IBAN = ["IBAN", "آيبان"]
export const IBAN_PLACE_HOLDER = countryCode => [
  `Ex. ${countryCode}-54564654654564566456`,
  `مثال: ${countryCode}-54564654654564566456`,
]
export const IBAN_REQ = ["Iban is required", "الآيبان مطلوب"]
export const IBAN_CHARACTERES_Number = [
  "IBAN number must be 30 characters",
  "رقم الآيبان يجب أن لا يتخطى 30 حرف",
]
export const SWIFT_CODE = ["Swift code", "سويفت كود"]
export const SWIFT_CODE_PLACE_HOLDER = ["Ex. Bank A", "مثال: بنك ١"]
export const DEPOSIT_TERMS = ["Deposit terms", "شروط الإيداع"]
export const DEPOSIT_TERMS_PLACE_HOLDER = ["Ex. Daily", "مثال: يومي"]
export const WEB_SITE = ["Website URL", "موقع الكتروني"]
export const WEB_SITE_PLACE_HOLDER = ["Ex. Zyda.com", "مثال: zyda.com"]
export const INSTAGRAM_ACCOUNT = ["Instagram account", "حساب الإنستجرام"]
export const INSTAGRAM_PLACE_HOLDER = [
  "Enter you Instagram account",
  "أدخل حسابك في الإنستجرام ",
]
export const MOBILE_APP = ["Mobile app", "تطبيق هاتفي"]
export const MOBILE_APP_PLACE_HOLDER = ["Enter your mobile app", "أدخل تطبيقك"]
export const FAWRY = ["Fawry", "فوري"]
export const NATIONAL_NUM_REQ = [
  "Invalid national is required",
  "رقم البطاقة المدنية مطلوب ",
]
export const BENEFICIARY_ADDRESS_REQ = [
  "Beneficiary address is required",
  "عنوان المستفيد مطلوب",
]
export const BANK_ADDRESS_REQ = [
  "Bank address is required",
  "عنوان البنك مطلوب",
]
export const BANK_NAME_REQ = ["Bank name is required", "اسم البنك مطلوب"]
export const DEPOSIT_TERMS_REQ = [
  "Deposit terms is required",
  "شروط الإيداع مطلوبة",
]
export const ACCOUNT_NAME_REQ = ["Account name is required", "اسم الحساب مطلوب"]
export const ACCOUNT_NUMBER_REQ = [
  "Account number is required",
  "رقم الحساب مطلوب",
]
export const LICENSE_NUMBER_REQ = [
  "License number is required",
  "رقم الرخصة مطلوب",
]
export const FIRST_NAME_PLACEHOLDER = [
  "Enter your first name",
  "أدخل اسمك الأول",
]
export const LAST_NAME_PLACEHOLDER = [
  "Enter your last name",
  "أدخل اسم العائلة",
]
export const MOBILE_NUMBER_PLACEHOLDER = [
  "Enter your mobile number",
  "أدخل رقم هاتفك",
]
export const TEST_SMS = [
  "Test SMS",
  "اختبار SMS",
]
export const TEST_SMS_TEXT = [
  "Send myself a test SMS",
  "أرسل لنفسي رسالة نصية تجريبية"
]
export const TEST_SMS_PHONE = [
  "We will be sending the test SMS to this number",
  "سنرسل رسالة نصية قصيرة للاختبار إلى هذا الرقم",
]
export const TEST_SMS_BALANCE_NOTE = [
  "Please note that the test smses will consume from your credit balance. This will send a working voucher to this number. This test voucher will only be valid until the end of the day.",
  "يرجى ملاحظة أن اختبار smses سيستهلك من رصيدك الائتماني. سيؤدي هذا إلى إرسال إيصال عمل إلى هذا الرقم. قسيمة الاختبار هذه صالحة فقط حتى نهاية اليوم.",
]
export const TEST_SMS_FAILURE_MESSAGE = [
  "You do not have enough SMS credits",
  "ليس لديك ما يكفي من أرصدة الرسائل القصيرة",
]
export const TEST_SMS_SUCCESS_MESSAGE = [
  'Your test message has been sent.',
  'تم إرسال رسالتك الاختبارية.'
]
export const SEND = [
  "Send",
  "إرسال"
]
export const REMAINING_CAMPAIGN_NOTE = count => [
  `You have ${count} more campaigns this month!`,
  `لديك ${count} أخريان هذا الشهر!`
]
export const MAXIMUM_CAMPAIGN_LIMIT = [
  `You can only create 5 announcements per month. You can save this campaign as a draft.`,
  `يمكنك فقط إنشاء 5 إعلانات في الشهر. يمكنك حفظ هذه الحملة كمسودة.`
]


export const INTEGRATIONS = ["Integrations", "دمج الخدمات"]
export const POINT_OF_SALE = ["Point of Sale", "نقطة البيع"]
export const DELIVERY_COURIER = ["Delivery Courier", "خدمة التوصيل"]
export const GET_STARTED = ["Get Started", "البدء"]
export const INTEGRATIONS_TYPE_DESCRIPTION = name => [
  `The identifier you use to link your branch to "${name}"`,
  `رقم التعريف الذي تستخدمه لربط فرعك بـ "${name}"`,
];

export const FOODICS_ERROR_MESSAGE_COD = [
  "You have to disable the Card on Delivery in the payment methods to be able to connect your Foodics account with Zyda",
  "يجب عليك تعطيل البطاقة عند التسليم في طرق الدفع للتتمكن من توصيل الطعام الخاص بحسابك علي فودكس  مع زيدا",
]

export const FACEBOOK_PARTNER_DESCRIPTION = [
  "Connect your Zyda store to Facebook, activate the 'Order Food' button on Instagram, Facebook and Messenger and sync your Facebook Pixel with your Zyda storefront.",
  "قم بتوصيل متجر زيدا الخاص بك بالفيسبوك ، وقم بتنشيط زر 'طلب الطعام' على الانستغرام و الفيسبوك و مسنجر ومزامنة  الفيسبوك بكسل مع واجهة متجر زيدا.",
];

export const FACEBOOK_PARTNER_TITLE = ['Facebook Business Manager', 'مدير أعمال فيسبوك'];

export const FACEBOOK_UNINSTALLED = ['Facebook uninstalled successfully', 'تم إلغاء فيسبوك بنجاح'];

export const CONNECT = ['Connect', 'اتصل'];

export const FACEBOOK_LEARN_MORE = ['Learn more about Facebook Business Manager', 'تعلم المزيد عن مدير أعمال فيسبوك'];

export const TOOLS= [
  "Tools",
  "أدوات",
]

export const BUSINESS_ID = [
  "Business ID",
  "معرف الأعمال"
]

export const BUSINESS_NAME = [
  "Business Name",
  "الاسم التجاري"
]

export const PROMOTE_SERVICES = [
  "Promote Services",
  "الترويج للخدمات"
]

export const MANAGE_CUSTOMERS = [
  "Manage Customers",
  "إدارة العملاء"
]
export const RUN_ADS = [
  "Run Ads",
  "تشغيل الإعلانات"
]
export const PROMOTE_FB = [
  "Promote your mouthwatering posts on Facebook",
  "روّج لمنشوراتك الشهية على فيسبوك"
]

export const REACH_FB = [
  "Reach and connect with your customers through your page on Facebook",
  "قم بالوصول إلى عملائك والتواصل معهم من خلال صفحتك على فيسبوك"
]

export const CAMPAIGNS_MANAGE = [
  "Create targeted campaigns and manage your ads on Facebook",
  "إنشاء حملات مستهدفة وإدارة إعلاناتك على فيسبوك"
]

export const FB_ACTIONS = [
  "More Facebook Actions",
  "المزيد من إجراءات فيسبوك"
]

export const MANAGE_FEATURES = [
  "Manage Features",
  "إدارة ميزات"
]

export const FOODICS_INTEGRATION_MESSAGE = [
  "All your branches needs to be linked to foodics to make sure that menu items are synced correctly",
  "يجب ربط جميع فروعك بفودكس للتأكد من مزامنة عناصر القائمة بشكل صحيح"
]
export const SAME_DAY = ["Same Day", "نفس اليوم"]
export const NEXT_DAY = ["Next Day", "اليوم التالى"]
export const DELIVER_WITHIN = ["Deliver Within", "التوصيل خلال"]

export const ERROR_CTA_COD = ["Go to payment methods", "انتقل إلى طرق الدفع"]

export const BLOCK_TABS_TOP_ERROR = [
  "Please settle your subscription fees to continue managing your store. Meanwhile, your account is limited to orders management.",
  "يرجى سداد قيمة الإشتراك للإستمرار بإدارة متجركم الإلكتروني. بإمكانكم حالياً إستقبال وإدارة الطلبات فقط",
]

export const BLOCK_TABS_PAY_NOW = ["Pay Now", "أدفع الأن"]

export const FOODICS_ERROR_MESSAGE_AA = [
  "You have to disable the Auto Accept in the Order Management to be able to connect your Foodics account with Zyda",
  "يجب عليك تعطيل القبول التلقائي في إدارة الطلبات للتتمكن من توصيل الطعام الخاص بحسابك علي فودكس مع زيدا",
]
export const ERROR_CTA_AA = [
  "Go to order management",
  "انتقل إلى إدارة الطلبات",
]
export const FOODICS_ERROR_MESSAGE_TAX = [
  "You have to set taxes to 0 from business information to be able to connect your foodies account with zyda",
  "يجب عليك أن تحدد قيمة الضرائب لتكون 0 في بيانات النشاط التجاري لتتمكن من ربط حسابك علي فودكس مع زيدا",
]
export const ERROR_CTA_TAX = [
  "Go to business information page",
  "الانتقال إلى صفحة بيانات النشاط التجاري",
]

export const FOR_MORE_INFORMATION = [
  "For more information",
  "للمزيد من المعلومات",
]

export const LEARN_MORE = ["Learn more", "أعرف أكثر"]

export const CONNECT_NOW = ["Connect now", "الاتصال الآن"]

export const SUCCESS = ["Success!", " تم بنجاح!"]

export const FAILED = ["Failed!", "فشل!"]

export const RETRY = ["Retry", "اعاده المحاوله"]

export const CONNECTION_FAILED = [
  "The connection is failed due to the connection error.",
  "فشل! بسبب خطأ في الاتصال.",
]

export const SUCCESS_CONNECTION = [
  "You've successfully connected to your account, Please Import your menu.",
  "تم بنجاح! لقد قمت بالاتصال بحسابك , من فضلك قم باستيراد القائمة",
]

export const BACK_TO_INTEGRATIONS = [
  "Back to integrations",
  "رجوع إلى دمج الخدمات",
]

export const FOODICS = ["Foodics", "فودكس"]
export const FOODICS_MERCHANT_ID = ["Merchant ID", "الرقم التعريفي للمتجر"]
export const FOODICS_COMPANY_NAME = ["Company Name", "اسم الشركة"]
export const FOODICS_STATUS = ["Status", "الحاله"]
export const FOODICS_CONNECTED = ["Connected", "متصل"]
export const FOODICS_DISCONNECTED = ["Disconnected", "غير متصل"]

export const PARTIALLY_CONNECTED = ['Partially Connected', 'متصل جزئيًا'];
export const CONNECTED = ['Connected', 'متصل'];
export const DISCONNECTED = ["Disconnected", "غير متصل"]
export const DISCONNECT = ["Disconnect", "حذف"]
export const MAKE_DEFAULT = ["Make default", "وضعه أساسي"]

export const DISCONNECT_COURIER = [
  "Disconnect Courier?",
  "هل تريد حذف مقدم الخدمة؟",
]

export const ARE_YOU_SURE_TO_DISCONNECT = name => [
  `Are you sure you want to disconnect ${name} ?`,
  `هل أنت متأكد أنك تريد حذف ${name} ؟`,
]

export const INTEGRATION_STATUS = ["Status", "الحاله"]

export const ERROR_MESSAGE_AA = name => [
  `You have to disable the Auto Accept in the Order Management to be able to connect your ${name} account with Zyda`,
  `يجب عليك تعطيل القبول التلقائي في إدارة الطلبات للتتمكن من توصيل الطعام الخاص بحسابك علي ${name} مع زيدا`,
]

export const ERROR_MESSAGE_COD = name => [
  `You have to disable the Card on Delivery in the payment methods to be able to connect your ${name} account with Zyda`,
  `يجب عليك تعطيل البطاقة عند التسليم في طرق الدفع للتتمكن من توصيل الطعام الخاص بحسابك علي ${name}  مع زيدا`,
]
export const ERROR_MESSAGE_TAX = name => [
  `You have to set taxes to 0 from business information to be able to connect your foodics ${name} with zyda`,
  `يجب عليك أن تحدد قيمة الضرائب لتكون 0 في بيانات النشاط التجاري لتتمكن من ربط حسابك علي ${name} مع زيدا`,
]

export const EXTERNAL_ID_INVALID = [
  "External id is invalid",
  "التعريف الخارجى للمتغير غير صحيح",
]
export const EXTERNAL_ID_IS_REQUIRED = [
  "External id is required",
  "التعريف الخارجى للمتغير غير موجود",
]
export const EMPTY_STATE = ["", ""]

export const API_KEY = ["API Key", "API Key"]
export const KEY = ["Key", "Key"]
export const PRINT_EXTRA_DATA = ["Print Extra Data", "طباعة المعلومات الاضافية"]
export const RECEIPT = ["Receipt", "فاتورة"]
export const PRINT_AWB = ["Print Airwaybill", "طباعة بوليصة الشحن الجوي"]
export const OPEN_QRCODE = ["Open QR Code", "فتح رمز الاستجابة السريعة"]
export const PRINT_RECEIPT = ["Print Receipt", "طباعة فاتورة"]
export const PRINT_GIFT_RECEIPT = ["Print Gift Receipt", "اطبع فاتورة الهدية"]
export const GIFT_RECEIPT = ["Gift Receipt", "ايصال هدية"]
export const GIFT_FROM = ["Gift From:", "هدية من:"]

export const ACCOUNT_TYPE_REQ = ["Account type is required", "نوع الحساب مطلوب"]
export const SWIFT_CODE_REQ = ["Swift code is required", "سويفت كود مطلوب"]
export const INVALID_LICENSE_NUMBER = [
  "Invalid license number. Numbers only please.",
  "رقم الرخصة غير صالح، أرقام فقط من فضلك.",
]
export const PAYMENT_METHODS_REQ = [
  "At least one payment method must be selected",
  "يجب اختيار طريقة دفع واحدة على الأقل",
]
export const COMPANY_ACCOUNT_NUMBER = [
  "Company account number  ",
  "رقم حساب الشركة",
]
export const ENTER_YOUR_COMPANY_NAME = [
  "Enter your company name",
  "أدخل الاسم شركتك",
]
export const PERSONAL_ACCOUNT_NUMBER = [
  "Personal account number",
  "Personal account number",
]
export const COMPANY_BENEFICIARY_ADDRESS_SUBTITLE = [
  "The address of the company.",
  "عنوان الشركة.",
]
export const PERSONAL_BENEFICIARY_ADDRESS_SUBTITLE = [
  "The address of the account holder",
  "عنوان صاحب الحساب",
]
export const IBAN_CHARACTERES_MAX_NUMBER = [
  "IBAN number shouldn't be more than 30 characters",
  "يجب ألا يزيد رقم الآيبان عن ٣٠ حرفًا",
]
export const IBAN_CHARACTERES_MIN_NUMBER = [
  "IBAN number shouldn't be less than 10 characters",
  "يجب ألا يقل رقم الآيبان عن ١٠ احرف",
]
export const MERCHANT_ID = ["Merchant ID", "معرّف التاجر"]
export const CARD_INTEGRATION_ID = ["CARD INTEGRATION ID", "رقم التكامل للكارت"]
export const IFRAME_ID = ["IFRAME ID", "رقم إفريم"]
export const SETTLEMENT = ["Settlement", "التسوية"]
export const REJECTED = ["Rejected", "مرفوض"]
export const IMAGE_REQ = ["Image is required", "الصورة مطلوبة"]
export const COMPANY_NAME_SUBTITLE = [
  "The company name must match the English written name in the bank.",
  "يجب أن يتطابق اسم الشركة مع الاسم المكتوب باللغة الإنجليزية في البنك.",
]
export const AUTHORIZED_SIGNATORY_SUBTITLE = [
  "The account holder’s name must be mentioned in the Authorized Signatory.",
  "يجب ذكر اسم صاحب الحساب في الموقِّع المعتمد.",
]
export const TAX_PERCENTAGE_ERR = [
  "VAT percentage must be within 0 and 100",
  "يجب أن تكون النسبة الضريبية بين 0 و 100",
]
export const DELETED_PAYMENT_GATEWAY = [
  "Delete Payment gateway",
  "حذف بوابة الدفع",
]
export const ARE_SURE_TO_DELEYE_PAYMENT_GATEWAY = [
  "Are you sure you want to delete the payment gateway?",
  "هل أنت متأكد أنك تريد حذف بوابة الدفع؟",
]
export const IF_YOU_DELETE_PAYMENT_GATEWAY = [
  "If you choose to delete the payment gateway, all your information will be lost & the online payment option on your store will be disabled. To reactivate it you will have to resubmit all the required information and go through the acceptance process again.",
  "إذا اخترت حذف بوابة الدفع ، فستفقد جميع معلوماتك وسيتم تعطيل خيار الدفع عبر الإنترنت في متجرك. لإعادة تنشيطها ، سيتعين عليك إعادة إرسال جميع المعلومات المطلوبة والمرور بعملية القبول مرة أخرى.",
]
export const PAYMENT_METHOD_DISABLE = ['Disable payment method', 'تعطيل طريقة الدفع'];
export const PAYMENT_METHOD_DISABLE_NOTE = ['Are you sure to disable this payment method?', 'هل أنت متأكد من تعطيل طريقة الدفع هذه؟'];
export const DELETED_PAYMENT_SUCCESS = [
  "Payment gateway is deleted",
  "تم حذف بوابة الدفع",
]
export const PAYMENT_METHOD_DISABLE_NOTE_ENGAGE = ['You need to have an active payment method. Deleting payment method will also deactivate Engage. Are you sure you want to proceed?', 'يجب أن يكون لديك طريقة دفع نشطة. سيؤدي حذف طريقة الدفع أيضًا إلى إلغاء تنشيط Engage. هل انت متأكد انك تريد المتابعة؟'];
export const INVALID_FORMAT_UPLOAD = ["Wrong file format", "تنسيق ملف خاطئ"]
export const ACCOUNT_NUMBER_MIN_NUMBER = [
  "Account number shouldn't be less than 8 characters",
  "يجب ألا يقل رقم الحساب عن 8 احرف",
]
export const PROFILE_NAME = ["Store Profile Name", "اسم ملف تعريف المتجر"]
export const PROFILE_NAME_PLACE_HOLDER = [
  "Enter Profile Name",
  "أدخل اسم ملف التعريف",
]
export const PROFILE_NAME_REQ = [
  "Store Profile Name is required",
  "اسم ملف تعريف المتجر مطلوب",
]
export const PROFILE_NAME_SUBTITLE = [
  "Store Profile name can include only English letters & numbers.",
  "يمكن أن يتضمن اسم ملف تعريف المتجر أحرف انجليزية وأرقام فقط.",
]
export const PROFILE_NAME_VALIDATION = [
  "The Profile name format is incorrect.",
  "تنسيق اسم ملف التعريف غير صحيح.",
]
export const ALL_OPTIONS_GROUPS = ["All option groups", "جميع مجموعات الخيارات"]
export const OPTIONS_SHARED_WITH_PRODUCT = val => [
  `${val[0]} options • Shared with ${val[1]} items`,
  `${val[0]} خيارات • تمت مشاركتها مع ${val[1]} منتجات`,
]
export const OPTION_GROUPS_NO_OPTIONS = [
  "Can not insert option group without options",
  ".لا يمكن إدراج مجموعة خيارات لا تحتوي خيارات",
]
export const OPTION_GROUPS_NO_DUPLICATES = [
  "Cannot insert the same options group for same variant.",
  "لا يمكن إدراج نفس مجموعة الخيارات لنفس المتغير",
]
export const OPTION_GROUPS_ONE_OPTION = [
  "Option group should have at least one option",
  "يجب أن تحتوي مجموعة الخيارات على خيار واحد علي الاقل.",
]
export const SORT_PRODUCT = ["Sort Items", "ترتيب المنتجات"]
export const SORT_PRODUCT_TITLE = [
  "Default Item Sorting",
  "الترتيب الافتراضي للمنتج",
]
export const DESC_SORT_PRODUCT = [
  "Choose how you want to display the Items on your Store",
  "اختر الطريقة التي تريد بها عرض المنتجات في متجرك",
]
export const SORT_PRODUCT_DATE = ["Newly Added.", "مضاف حديثا"]
export const SORT_PRODUCT_AZ = ["Name: A to Z.", "الاسم: من الألف إلى الياء"]
export const SORT_PRODUCT_ZA = ["Name: Z to A.", "الاسم: من الياء إلى الألف"]
export const SORT_PRODUCT_STORE_OWNER = [
  "Defined by the Store Owner.",
  "يحددها صاحب المتجر.",
]
export const SORT_PRODUCT_STORE_OWNER_NOTE = [
  "You can define the sorting of the items manually, by exporting the catalog, sort it in the excel sheet then import it.",
  "يمكنك تحديد ترتيب المنتجات يدويًا ، عن طريق تصدير الكتالوج ، ترتيبه في ملفات جداول حسابية ثم استيراده.",
]
export const SORT_PRODUCT_SUCCESS = ["Sorting success", "تم الترتيب بنجاح"]

export const TEAM = ["Team", "الفريق"]
export const ORDERFAST_LINKS = ["Orderfast Links", "Orderfast روابط"]
export const VERD = ['Verd', 'التوصيل'];
export const ORDERFAST = ["Orderfast", "Orderfast"]
export const LINKS = ["Links", "روابط"]
export const LOYALTY = ["Loyalty", "برامج الولاء"]
export const WALLET = ["Wallet", "محفظه"]
export const ENABLE_VERD = ['Enable Verd Dispatch', 'فعل VERD'];
export const FULL_NAME = ["Full Name", "الاسم الكامل"]
export const EMAIL = ["Email", "البريد الالكتروني"]
export const PHONE_NUMBER = ["Phone Number", "رقم الهاتف"]
export const ROLE = ["Role", "الوظيفة"]
export const JOB_TYPE = ["Job Type", "نوع الوظيفه"]
export const STATUS = ["Status", "الحاله"]
export const REQUEST_STATUS = ["Request Status", "حالة الطلب"]
export const SEARCH_RIDERS = ["Search riders", "البحث عن سائقين"]

export const FULL_NAME_IS_REQ = ["Full Name is required", "الاسم الكامل مطلوب"]
export const ROLE_IS_REQ = ["Role is required", "الوظيفة مطلوبة"]
export const OPERATOR_BRANCHES_REQ = [
  "You should select at least one branch",
  "يرجى اختيار فرع واحد على الأقل",
]
export const PHONE_IS_INVALID = [
  "Phone Number is invalid.",
  "رقم الهاتف غير صحيح.",
]

export const CUSTOMER_NAME_IS_INVALID = [
  "Customer Name is invalid.",
  "اسم العميل غير صحيح.",
]

export const DELETE_USER = ["Delete user?", "إلغاء المستخدم؟"]
export const DELETE_RIDER= ["Delete rider?", "إلغاء السائق؟"]
export const DELETE_USER_CONFIRMATION = [
  "Are you sure you want to delete this user?",
  "سيتم إلغاء المستخدم، يرجى التأكيد",
]
export const DELETE_RIDER_CONFIRMATION = [
  "Are you sure you want to delete this rider?",
  "سيتم إلغاء السائق، يرجى التأكيد",
]
export const ACTIONS = ["Actions", "العمليات"]
export const ADD_NEW_USER = ["Add New User", "إضافة مستخدم جديد"]
export const ADD_NEW_RIDER = ["Add New Rider", "إضافة سائق جديد"]
export const ENTER_FULL_NAME = [
  "Enter user's full name",
  "الاسم الكامل للمستخدم",
]
export const PENDING_ACCEPTANCE = courierName => [`Pending Acceptance`, `قيد الانتظار`]
export const ASSIGNED_SUCCESSFULLY = ["Assigned Successfully", "تم التعيين"]
export const DELIVERING = ["Delivering", "قيد التوصيل"]
export const DELIVERED = ["Delivered", "تم التوصيل"]
export const DECLINED_THE_REQUEST = ["Declined the request", "تم الرفض"]

export const ENTER_EMAIL = ["Enter user's E-mail", "البريد الإلكتروني للمستخدم"]
export const ENTER_PHONE = ["Enter user's Phone Number", "رقم هاتف المستخدم"]
export const OWNER = ["Owner", "المالك"]
export const MANAGER = ["Manager", "المدير"]
export const FULLTIME = ["Full-time", "دوام كامل"]
export const PART_TIME = ["Part-time", "دوام جزئي"]
export const FREELANCER = ["Freelancer", "عامل حر"]
export const OPERATOR = ["Operator", "عامل"]
export const USER_ROLE = ["User Role", "وظيفة المستخدم"]
export const OPERATORS_BRANCHES = [
  "Operators Branches",
  "الفروع المتاحة للعامل",
]
export const RIDERS_BRANCHES = [
  "Rider's Branches",
  "الفروع المتاحة للسائق",
]
export const NAME_SHOULD_CONTAIN_LETTERS = [
  "Name should contain letters",
  "يجب أن يحتوي الاسم على أحرف",
]

export const MORE = ["More", "المزيد"]
export const PAYMENT_TRANSACTION_CAPTURED = ["Captured", "تم الاستلام"]
export const PAYMENT_TRANSACTION_INITIATED = ["Initiated", "بدات"]
export const PAYMENT_TRANSACTION_FAILED = ["Failed", "فشلت"]
export const PAYMENT_TRANSACTION_DECLINED = ["Declined", "تم الرفض"]
export const PAYMENT_TRANSACTION_ABANDONED = ["Abandoned", "تم التخلي"]
export const PAYMENT_INFO = ["Payment Info", "معلومات الدفع"]

export const SORT = ["Sort", "ترتيب"]

// WEB LINKS
export const WEB_LINKS = ["Web Links", "روابط خارجية"]
export const ADD_WEB_LINKS_TO_STORE = [
  "Add web links to your store",
  "اضافة روابط خارجية للمتجر الإلكتروني",
]
export const EXAMPLE_FOLLOW_US = [
  "Ex: Follow us on instagram",
  "مثال: تابعنا علي انستجرام",
]
export const ADD_EXTERNAL_WEB_LINKS = [
  "Add external web links on your store and redirect your customers to interact with you outside the store.",
  "اضافة الروابط الخارجية يتيح إمكانية تصفح أي محتوى خارج نطاق متجرك الالكتروني ",
]
export const YOU_ARE_ABOUT_TO_DELETE_LINK = [
  'You are about to delete ',
  'أنت علي وشك ان تحذف '
]
export const ADD_WEB_LINK = ["Add Web Link", "اضافة رابط خارجي"]
export const ADD_NEW_LINK = ["Add New Link", "إضافة رابط جديد"]
export const SORT_MANUALLY = ["Sort Manually", "ترتيب يدوي"]
export const TYPE_URL = ["Type", "النوع"]
export const URL = ["URL", "الرابط"]
export const WEBSITE_URL = ["Website URL", "رابط الموقع"]
export const FACEBOOK = ["Facebook", "فيسبوك"]
export const INSTAGRAM = ["Instagram", "انستجرام"]
export const TWITTER = ["Twitter", "تويتر"]
export const SNAPCHAT = ["Snapchat", "سناب چات"]
export const WHATSAPP = ["WhatsApp", "واتساب"]
export const SMS = ["SMS", "رسائل الهاتف القصيرة - اس ام اس"]
export const ENTER_URL = ["Enter website url", "ادخال رابط الموقع"]
export const ENTER_FACEBOOK_USERNAME = [
  "Enter facebook username",
  "إدخال اسم المستخدم للفيسبوك",
]
export const ENTER_INSTAGRAM_USERNAME = [
  "Enter instagram username",
  "إدخال اسم المستخدم للانستجرام",
]
export const ENTER_TWITTER_USERNAME = [
  "Enter twitter username",
  "إدخال اسم المستخدم لتويتر",
]
export const ENTER_SNAPCHAT_USERNAME = [
  "Enter snapchat username",
  "إدخال اسم المستخدم لسناب چات",
]
export const ENTER_EMAIL_ADDRESS = [
  "Enter email address",
  "ادخال البريد الالكتروني",
]
export const TITLE_ARABIC = ["Title (Arabic)", "العنوان باللغة العربية"]
export const TITLE_ENGLISH = ["Title (English)", "العنوان باللغة الإنجليزية"]
export const ENTER_WEB_LINK_TITLE = ["Enter link title", "ادخال عنوان الرابط"]
export const DELETE_WEBLINK = ["Delete Weblink", "الغاء الرابط"]
export const DO_YOU_WANT_DELETE_LINK = [
  "Delete this link?",
  "هل متأكد من إزالة هذا الرابط؟",
]
export const LINK_TYPE_IS_REQ = ["Select link type", "اختيار نوع الرابط"]
export const URL_IS_REQ = ["Url is required", "الرابط مطلوب"]
export const VALID_URL = [
  "Please enter valid URL. Example: https//:www.example.com",
  "الرجاء ادخال الرابط بشكل صحيح. مثال https//:www.example.com",
]
export const PHONE_NUMEBER_IS_REQ = [
  "Phone number is required",
  "رقم الهاتف مطلوب",
]
export const SMS_BODY_IS_REQ = ["SMS body is required", "الرسالة مطلوبة"]
export const THE_LINK_ADDED = title => [`"${title}" has been added`, `تم اضافة "${title}"`]
export const THE_LINK_DELETED = title =>  [`"${title}" has been deleted`, `تم مسح "${title}"`]
export const THE_LINK_UPDATED = title => [`"${title}" has been updated`, `تم تعديل "${title}"`]
export const THE_LINKS_SORTED = [
  "The links sorted successfully",
  "تم ترتيب الروابط بنجاح",
]
export const NO_PRODUCT_CATEGORY = [
  "No items available in this category",
  "لا توجد منتجات متوفرة في هذه الفئة",
]

export const INFORM_CUSTOMER_SMS = [
  "Notify customer of the delay through SMS",
  "إرسال رساله نصيه للعميل",
]

export const CHANGE_EXPECTED_AT = (from, to) => [
    `New expected delivery time is ${to} instead of ${from}`,
    `سيتم تغيير الوقت المتوقع من ${from} الي ${to}`
]

export const NO_SUPPORT_CANCELLATION_COURIER_WARNING = (courierName, supportNumber) => [
  `To cancel this request, please call ${courierName} support at ${supportNumber}`,
  `لإلغاء الطلب، برجاء الإتصال بخدمة عملاء ${courierName} على رقم ${supportNumber}`
];

export const CANCELLATION_COURIER_WARNING = courierName => [
  `You might be charged a cancellation fee from ${courierName}`,
  `قد يتم تحصيل رسوم إلغاء منك من ${courierName}`
];

export const EXPECTED_DELIVERY_TIME = ["Expected Delivery Time", "الوقت المقدر"]
// Sales Analytics
export const ANALYTICS = ["Analytics", "التحليلات"]
export const SALES_ANALYTICS = ["Sales Analytics", "تقارير المبيعات"]
export const DATE_RANGE = ["Date range", "المدّة"]
export const TOTAL_SALES = ["Total Sales", "المبيعات الإجمالية"]
export const AVERAGE_ORDER_VALUE = ["Average Order Value", "متوسّط قيمة الطلب"]
export const SALES_TOTAL_ORDERS = ["Total Orders", "عدد الطلبات الإجمالي"]
export const TOTAL_CUSTOMERS = ["Total Customers", "عدد العملاء"]
export const VIEW_FULL_REPORT = ["View Full Report", "عرض التقرير الكامل"]
export const PREVIOUS_PERIOD = ["Previous Period", "المدّة السابقة"]
export const PREVIOUS_YEAR = ["Previous Year", "السنة السابقة"]

export const SELECTED_PERIOD = ["Selected Period", "المدّة المحدّدة"]
export const BEHAVIOR_BREAKDOWN = ["Behavior Breakdown", "التقارير المفصّلة"]
export const DELIVERY_VS_PICKUP = [
  "Delivery vs. Pickup",
  "التوصيل مُقابِل الاستلام",
]
export const PAYMENT_METHOD = ["Payment Method", "طريقة الدفع"]
export const BRANCH_NAME = ["Branch Name", "اسم الفرع"]
export const ORDER_ID = ["Order ID", "رقم الطلب"]
export const REFERENCE_ID = ["Reference ID", "الرقم المرجعى"]
export const PAYMENT_DATE = ["Payment Date", "تاريخ الدفع"]
export const PAYMENT_STATUS = ["Payment Status", "حاله الدفع"]
export const BEST_SELLING_PRODUCTS = [
  "Best Selling items",
  "المنتجات الأكثر مبيعاً",
]
export const BEST_SELLING_CATEGORIES = [
  "Best Selling Categories",
  "الأقسام الأكثر مبيعاً",
]
export const EMPTY_BARS_CHART = [
  "There's no data available to show the graph.",
  "لا يوجد بيانات متاحة لعرض الرسم البياني",
]
export const QUANTITY_SOLD = ["Quantity Sold", "الكميّات المباعة"]
export const COMPARED_TO = ["Compared to", "مقارنة في"]
export const BETA = ['Beta', 'بيتا'];
export const NEW_BADGE = ['New', 'جديد'];

export const SHOWING_RESULTS = count => [
  `Showing ${count[0]} of ${count[1]} results`,
  `جاري عرض ${count[0]} من ${count[1]} نتيجة`,
]
export const SALES_BREAKDOWN = ["Sales Breakdown", "تفاصيل المبيعات"]
export const BREAKDOWN_BY = ["Breakdown by", "التقارير المفصّلة"]
export const PAYMENT_METHODS_ANALYTICS = ["Payment Methods", "طرق الدفع"]
export const TOP_CATEGORIES = ["Top Categories", "الأقسام الأكثر مبيعاً"]
export const TOTAL_QTE_SOLD = ["Total Quantity Sold", "إجمالي المنتجات المباعة"]
export const EMPTY_TOP_SELLING_PRODUCTS = [
  "There's no data available to show the top-selling items.",
  "لا يوجد بيانات متاحة لعرض المنتجات الأكثر مبيعاً",
]

export const EXPORT_TOP_SELLING_PRODUCTS = [
  "Best selling items report will be delivered to your email",
  "سوف نقوم بإرسال تقرير المنتجات الأفضل مبيعا الى بريدك الاكتروني",
]

export const EXPORT_NEW_VS_RETURNING_REPORT = [
  "New vs Returning report has been sent to your email",
  "تم إرسال التقرير إلى بريدك الإلكتروني",
]

export const EXPORT_TOP_CATEGORIES = [
  "Top Categories Report will be delivered to your email",
  "سوف نقوم بإرسال تقرير الأقسام الأفضل مبيعا الى بريدك الاكتروني",
]

export const DATES_CONFLICT = [
  "The start date can not be after the end date",
  "تاريخ البداية لا يمكن ان يكون بعد تاريخ النهاية",
]
export const ANALYTICS_DATE_BEFORE_CREATION = [
  "The start date should be after the store creation date",
  "تاريخ البداية يجب أن يكون بعد تاريخ إنشاء المتجر",
]
export const ANALYTICS_DATE_FUTURE = [
  "The end date should not be in the future",
  "تاريخ النهاية لا يجب أن يكون فى المستقبل",
]

export const EMPTY_GEO_HEATMAP = [
  "There's no data available to show the geo analytics.",
  "لا يوجد بيانات متاحة لعرض التحليل الجغرافي",
]

export const SEARCH_GROUPS = ["Search for groups...", "ابحث عن مجموعة الخيارات"]
export const ANALYTICS_TOOLTIP_INFORMATION = [
  [
    'We calculate total sales as a sum of all accepted, ready, dispatched, and completed orders.',
    'نحسب إجمالي المبيعات كمجموع لجميع الطلبات المقبولة والجاهزة والمرسلة والمكتملة.',
  ],
  [
    'Orders that were submitted will not reflect in analytics unless they are accepted.',
    'الطلبات التي تم تقديمها لن تنعكس في التحليلات ما لم يتم قبولها.',
  ],
  [
    'Order that have been submitted today and were cancelled will reflect in real-time.',
    'ستتم إزالة الطلبات الملغاة من بيانات التحليلات في الوقت الفعلي.',
  ],
  [
    'Orders that are "Waiting for payment" will not be included in analytics until payment has gone through successfully.',
    'لن يتم تضمين الطلبات التي "تنتظر السداد" في التحليلات حتى يتم الدفع بنجاح.',
  ],
];

//billing and subscriptions
export const BILLING_AND_PLANS = ["Billing and Plans", "الفواتير و الباقات"]
export const SALES_REPORT = ["Sales Report", "المبيعات"]
export const NEXT_BILLING_DATE = ["Your next bill is on", "تاريخ التجديد"]
export const CURRENT_PLAN = ["Current Plan", "الباقة الحالية"]
export const RENEW_NOW = ["Renew Now", "جدد الآن"]
export const VIEW_INVOICES = ["View Invoices", "إظهار الفواتير"]
export const EXPIRES_ON = ["Expires on", "ينتهي فى"]
export const PAY_NOW = ["Pay now", "أدفع الأن"]
export const SUBSCRIPTION = ['Subscription', 'الإشتراك'];
//invoices
export const INOICE_ID = ["Invoice ID", "رقم الفاتورة"]
export const INVOICE_NO = ["Invoice no.","رقم الفاتورة"];
export const INVOICE_START_DATE = ["Date", "التاريخ"]
export const INVOICE_END_DATE = ["End", "إنتهاء"]
export const INOICE_PLAN = ["Description", "الوصف"]
export const INOICE_AMOUNT = ["Amount", "القيمة"]
export const INOICE_TYPE = ["Type", "النوع"]
export const EXPORT_ALL = ["Export all", "تنزيل كل الفواتير"]
export const CANCELLED = ['Cancelled', 'ملغاة'];
//edit user topBar
export const CHANGE_PASSWORD = ["Change Password", "تغير كلمة السر"]
export const Old_PASSWORD = ["Old password", "كلمة السر الحالية"]
export const PASSWORD_CONSTRAINT = [
  "Password must contain at least 8 charcters",
  "كلمة السر لا تقل عن 8 حروف",
]

export const MY_ACCOUNT = ["My Account", "حسابي"]
export const MARKETING = ["Marketing", "التسويق"]
export const ADD_VOUCHER = ["Add Voucher", "إضافة قسيمة "]
export const INFO = ['Info', 'معلومات'];
export const GENERATE_BULK_VOUCHERS = [
  "Generate Bulk Vouchers",
  "إنشاء مجموعة قسائم",
]
export const ACTIVE = ["Active", "مفعل"]
export const DEACTIVATED = ["Deactivated", "متوقف"]
export const SCHEDULED_VOUCHER = ["Scheduled", "مجدول"]
export const EXPIRED = ["Expired", "منتهى"]
export const INACTIVE = ["Inactive", 'متوقف']

export const CODE_VOUCHER = ["Code", "كود القسيمة"]
export const CODE_USAGE = ["Code Usage", "استهلاك الكود"]
export const VALID_FROM = ["Valid from", "سارى من"]
export const VALID_TO = ["Valid to", "سارى حتى"]
export const VALID_FOR = ['Valid for', 'سارى لمدة']
export const VALID_FOR_DESCRIPTION = [
  'Enter the days you would like the voucher to be valid for once the sms is sent.',
  'أدخل الأيام التي تريد أن تكون فيها القسيمة صالحة بمجرد إرسال الرسائل القصيرة',
];
export const STATUS_VOUCHER = ["Status", "حالة الكود"]

export const PERCENTAGE = ["Percentage", "نسبة"]
export const AMOUT = ["Amount", "قيمة"]
export const NO_VOUCHER = ['No Voucher', 'لا يوجد قسيمة']

export const PUBLIC = ["Public", "عام"]
export const PRIVATE = ["Private", "خاص"]

export const VIEW_DETAILS = ["View details", "إظهار التفاصيل"]
export const ACTIVATE = ["Activate", "تفعيل"]
export const DEACTIVATE = ["Deactivate", "إيقاف"]

export const DELETE_VOUCHER_CONFIRMATION = [
  "Are you sure you want to delete this voucher?",
  "هل أنت متأكد من حذف هذه القسيمة",
]

export const NO_VOUCHERS_YET = [
  "You didn’t add any vouchers yet",
  "لم يتم إضافة أى قسائم حتى الآن",
]
export const DSCP_EMPTY_STATE_VOUCHERS = [
  "You can start adding vouchers on your store to attract more customers and reward your current customers.",
  "تستطيع إنشاء قسائم شرائية من متجرك لجذب المزيد من العملاء و مكافأة عملائك الحاليين.",
]
export const ADD_NEW_VOUCHER = ["Add New Voucher", "إضافة قسيمة جديدة"]
export const VOUCHER_UPDATED = [
  "Voucher updated successfully",
  "تم تحديث القسيمة بنجاح",
]

export const SOCIAL_MEDIA = ['Social Media', 'وسائل التواصل الاجتماعي'];
export const SOCIAL_MEDIA_TRACKING = ['Tracking & Social Media', 'التتبع ووسائل التواصل الاجتماعي'];
export const ENGAGE_AUTO_PILOT = ['Engage Autopilot', 'إشراك الطيار الآلي'];

export const VOUCHER_DELETED = [
  "Voucher deleted successfully",
  " تم حذف القسيمة بنجاح",
]

export const VOUCHER_TYPE_PREVIEW = payload => [
  `- ${payload[0]} - (${payload[1]})`,
  `- ${payload[0]} - (${payload[1]})`,
]
export const VOUCHER_MINIMUM_REQ_PREVIEW = payload => [
  `- Minimum requirement - (${payload[0]} ${payload[1]})`,
  `- المتطلبات الأساسية - (${payload[0]} ${payload[1]})`,
]
export const VOUCHER_LIMIT_USERS_PREVIEW = payload => [
  `- Limit of (${payload[0]}) uses - (${payload[1]}) per customer`,
  `- حد الخصم (${payload[0]}) للكود - (${payload[1]}) لكل عميل`,
]
export const VOUCHER_HAPPY_HOURS_PREVIEW = payload => [
  `- Happy hours: From (${payload[0]}) to (${payload[1]})`,
  `- ساعة الحظ  : من (${payload[0]}) إلى (${payload[1]}) `,
]
export const VOUCHER_VALID_DATE_PREVIEW = payload => [
  `- Valid from ${payload[0]} - Valid to ${payload[1]}`,
  `- سارى من ${payload[0]} - سارى حتى ${payload[1]}`,
]
export const VOUCHER_VALID_DATE_UNLIMITED = payload => [
  `- Valid from ${payload} - Unlimited`,
  `- سارى من (${payload}) - غير محدود`,
]

export const VOUCHERS = ["Vouchers", "القسائم الشرائية"]
export const VOUCHER_CODE = ["Voucher code", "رمز القسيمة"]
export const VOUCHER_TYPE = ["Voucher Type", "نوع القسيمة"]
export const VOUCHER_VALUE = ["Voucher Value", "قيمة القسيمة"]
export const VOUCHER_CODE_CHECKOUT = [
  "Your customers will use this code to redeem the voucher.",
  "يستخدم العميل هذا الكود للإستفادة من عرض القسيمة",
]
export const VOUCHER_CODE_PLACEHOLDER = [
  "Ex: 50OffOnFirstOrder",
  "توصيل مجاني على طلبك الأول",
]
export const VOUCHER_DESC_PLACEHOLDER = [
  "Ex: Free Delivery on your first order",
  "مثال: توصيل مجاني علي طلبك الأول",
]
export const VOUCHER_DESC_SUBTITLE = [
  "Write a clear description for your customers. ",
  "أضف وصف واضح عن مميزات العرض لعملائك",
]
export const ENTER_DESCRIPTION = ["Enter description", "أدخل الوصف"]
export const AMOUNT = ["Amount", "القيمة"]
export const FREE_DELIVERY = ["Free Delivery", "توصيل مجانى"]
export const TYPE = ["Type", "النوع"]
export const APPLIES_TO = ["Applies to", "يُطبق على"]
export const ENTIRE_ORDER = ["Entire Order", "إجمالي الطلب"]
export const TYPE_OF_ORDER = ["Type of Order", "نوع الطلب"]
export const NONE = ["None", "لا يوجد"]
export const MINIMUM_ORDER_AMOUNT = [
  "Minimum Order Amount",
  "الحد الأدنى للطلب",
]
export const MINIMUM_ORDER_AMOUNT_PLACEHOLDER = [
  "Enter minimum order amount",
  "أدخل الحد الأدنى للطلب",
]
export const MINIMUM_REQUIREMENTS = [
  "Minimum Requirements",
  "المتطلبات الأساسية",
]
export const USAGE_LIMITS = ["Usage Limits", "حد الاستهلاك"]
export const USAGE_LIMITS_DESC = [
  "You can set the voucher usage limit per code and per customer",
  "يمكنك تحديد الحد الأقصى لإستهلاك القسائم لكل كود و لكل عميل",
]
export const USAGE_LIMITS_OPTION_1 = [
  "Set the total times this code will be valid for use",
  " اضف الحد الأقصى لاستخدام هذا الكود",
]
export const USAGE_LIMITS_OPTION_2 = [
  "Set the total times a customer can use this code.",
  "أضف الحد الأقصى لإستخدام هذه القسيمة للعميل الواحد",
]
export const HAPPY_HOUR = ["Happy Hour", "ساعة الحظ"]
export const HAPPY_HOUR_DESC = [
  "Create your own peak hours of sales by limiting this voucher to be valid only for a specific time through the day. Selected time follows your store’s time zone",
  "حدد بنفسك ساعات ذروة لمبيعاتك بتحديد توقيت معين في اليوم لتطبيق هذه القسيمة.الوقت المحدد يتبع توقيت دولة متجرك",
]
export const TIME_PERIOD = ["Time Period", "المدة الزمنية"]
export const START_DATE = ["Start Date", "تاريخ البدء"]
export const START_TIME = ["Start Time", "وقت البدء"]
export const END_DATE = ["End Date", "تاريخ الانتهاء"]
export const END_TIME = ["End Time", "وقت الانتهاء"]
export const SET_END_DATE = ["Set end date", "حدد تاريخ الانتهاء"]
export const VOUCHER_CODE_IS_REQUIRED = ["Code is required", "الكود مطلوب"]
export const VOUCHER_AMOUNT_IS_REQUIRED = ["Value is required", "القيمة مطلوبة"]
export const SMS_BODY_IS_REQUIRED = ['SMS body is required', 'نص الرسالة النصية مطلوب'];
export const TARGETED_SEGMENT_IS_REQUIRED = ['Targeted Segment is required', 'الشريحة المستهدفة مطلوبة'];
export const AMOUNT_MORE_THAN_0 = [
  "The value should be more than 0",
  "يجب أن تكون القيمة أكثر من 0",
]
export const START_DATE_IS_REQUIRED = [
  "Start date is required",
  "تاريخ البدء مطلوب",
]
export const START_TIME_IS_REQUIRED = [
  "Start time is required",
  "وقت البدء مطلوب",
]
export const END_DATE_IS_REQUIRED = [
  "End date is required",
  "تاريخ الانتهاء مطلوب",
]
export const END_DATE_MUST_BE_GREATER = [
  "The end date must be greater than the start date",
  "تاريخ الإنتهاء يجب ان يكون اكبر من تاريخ البدء",
]
export const END_TIME_IS_REQUIRED = [
  "End time is required",
  "وقت الانتهاء مطلوب",
]
export const VOUCHER_UNIQUE = [
  "Voucher code must be unique per store",
  " يتم إنشاء كود القسيمة مرة واحدة ولا يمكن إعادة استخدامه إلا بعد إنتهاء مدة العرض",
]

export const VOUCHER_START_DATE_EDIT_MESSAGE = [
  "Start date can not be edited after the code has been used once or more",
  "تاريخ البدء لا يمكن تعديله بعد استخدام الكود مرة واحدة أو أكثر",
]

export const WELCOME_TO = payload => [
  `Welcome to ${payload}`,
  `اهلا بك فى ${payload}`,
]
export const VAL_DISCOUNT = payload => [`${payload} Discount`, `${payload} خصم`]
export const EXPIRES_IN_DAYS = payload => [
  `Expires in ${payload} days`,
  `ينتهي خلال ${payload} أيام`,
]
export const APPLY_OFFER = ["Apply Offer", "أستخدم العرض"]

export const ORDER_TYPE = ["Order Type", "نوع الطلب"]

export const OPTIONS_GROUPS_SORTED = [
  "Options groups sorted successfully",
  "تم تصنيف المجموعات بنجاح",
]
export const SEARCH_OPTION = [
  "Search for a specific option...",
  "البحث عن خيار محدد؟",
]

export const FULFILLMENT_TITLE = [" Fulfillment", "التسليم"]
export const FULFILLMENT_DESC = [
  "How your customers will be receiving their order?",
  "كيف سيستلم عملائك طلبهم؟",
]
export const BOTH = ["Both", "الكل"]
export const FULFILLMENT_DELIVERY = ["Delivery", "توصيل"]
export const FULFILLMENT_PICKUP = ["Pickup", "إستلام من الفرع"]

export const CANCELLED_SUBSCRIPTION = [
  "Your service has been canceled due to unpaid invoices,",
  "تم إلغاء الخدمة نظراً لعدم سداد الفواتير المستحقة.",
]
export const CANCELLED_SUBSCRIPTION_HINT = [
  "kindly settle your payment and contact us to reactivate your dashboard.",
  "يرجى التكرم بالدفع والتواصل معانا لإعادة تشغيل الخدمة",
]

export const REQUEST_CANCELLED_SUBSCRIPTION = [
  "Service has been canceled upon your request,",
  "تم إلغاء الخدمة بناء على طلبك.",
]
export const REQUEST_CANCELLED_SUBSCRIPTION_HINT = [
  "to reactivate your store please contact us.",
  "لإعادة تشغيل المتجر الخاص بك، يرجى التواصل مع فريق الدعم.",
]

export const OWNER_LATE_SUBSCRIPTION = [
  " Due to outstanding invoices you only have access to managing your orders, click",
  "يمكنك فقط إدارة الطلبات نظراً لوجود فواتير مستحقة. اضغط",
]
export const OWNER_LATE_SUBSCRIPTION_LINK = ["here", "هنا"]
export const OWNER_LATE_SUBSCRIPTION_LINK_AFTER = [
  " to settle your invoices",
  " لتسوية الفواتير.",
]
export const OWNER_EXPIRED_SUBSCRIPTION = [
  "Your subscription has expired!, Click here to",
  "انتهت مدة اشتراكك، اضغط هنا",
]
export const OWNER_EXPIRED_SUBSCRIPTION_LINK = ["Renew Now", "للتجديد فوراً"]

export const OPERATOR_LATE_SUBSCRIPTION = [
  "Your invoice is overdue! To avoid service cancellation, please, contact your",
  "لتجنب انقطاع الخدمة يرجى التواصل مع",
]
export const OPERATOR_LATE_SUBSCRIPTION_LINK = ["store owner ", "مالك المتجر"]
export const OPERATOR_LATE_SUBSCRIPTION_LINK_AFTER = ["to renew", " للتجديد."]
export const OPERATOR_EXPIRED_SUBSCRIPTION = [
  "Your subscription has expired! Please, contact your",
  "انتهت مدة اشتراكك، يرجى التواصل مع",
]
export const OPERATOR_EXPIRED_SUBSCRIPTION_LINK = ["store owner", "مالك المتجر"]
export const OPERATOR_EXPIRED_SUBSCRIPTION_AFTER_LINK = ["to renew.", "للتجديد"]
export const EN = ["(EN)", "EN"]
export const AR = ["(AR)", "AR"]

export const PREP_TIME_REQUIRED = ["Add Preparation Time", "اضف وقت التحضير"]
export const PREP_TIME_LESS_ERROR = [
  "Preparation Time is less than the default preparation time",
  "وقت التحضير الذى أدخلته أقل من وقت التحضير الإفتراضى",
]

export const DISCOUNTED_PRICE_CURRENCY = CURRENCY => [
  `Discounted Price (${CURRENCY})`,
  `سعر مخفض (${CURRENCY})`,
]
export const DISCOUNTED_PRODUCT = [`Discounted item`, `منتج مخفض`]
export const UPLOAD_DRAG_IMAGE = [
  "Drag image or click to upload",
  "اسحب الصورة هنا أو اضغط  لتحميلها",
]
export const UPLOAD_DRAG_IMAGE_FORMATS = [
  "Formats: .jpg or .png",
  "صيغة الصورة .jpg أو .png",
]
export const MEDIA = ["Item's Image", "صورة المنتج"]
export const VARIANT_IMAGE = ["Variant Image", "صورة المتغير"]
export const VARIANT_IMAGE_TITLE = TITLE => [
  `${TITLE} Variant Image`,
  `${TITLE} صورة المتغير`,
]
// Campaigns
export const CONTACT_DRAG_FILE = ["Drag & drop your file here, or click to browse", "اسحب الملف وأفلته هنا ، أو انقر للتصفح"];
export const CONTACT_FILE_SIZE = ["File size shouldn't be more than 30MB, and file type should be CSV or XLSX", "يجب ألا يزيد حجم الملف عن 30 ميغا بايت، ويجب أن يكون نوع الملف CSV أو XLSX"];
export const BROWSE_LOCAL_FILES = ["Browse local files", "تصفح الملفات المحلية"];
export const UPLOADING_FILE_NOTE = ["Uploading your file, please don’t close this window.", "تحميل الملف الخاص بك ، من فضلك لا تغلق هذه النافذة."];
export const TOTAL_UPLOADED_NUMBERS = number => [
  `Total numbers uploaded: ${number}`,
  `إجمالي الأرقام التي تم تحميلها: ${number}`
]
export const VALID_NUMBERS = number => [
  `Valid numbers: ${number}`,
  `أرقام صحيحة: ${number}`
]
export const VALID_NUMBERS_NOTE = ['We will only use these numbers in this campaign.', 'سنستخدم هذه الأرقام فقط في الحملة.']
export const DUPLICATED_NUMBERS = number => [
  `Duplicate numbers: ${number}`,
  `أرقام مكررة: ${number}`
]
export const DUPLICATED_NUMBERS_NOTE = ["These phone numbers are duplicated and already counted in the 'Correct Numbers' section", "'تم تكرار أرقام الهواتف و احتسابها في 'الأرقام الصحيحة"]
export const INVALID_NUMBERS = number => [
  `Invalid numbers: ${number}`,
  `أرقام غير صحيحة: ${number}`
]
export const INVALID_NUMBERS_NOTE = ['These phone numbers have issues or are incorrectly formatted. Please download the template and follow the formatting', 'أرقام الهواتف هذه بها مشاكل أو تم تنسيقها بشكل غير صحيح. يرجى تنزيل النموذج واتباع التنسيق.']
export const UPLOAD_ANOTHER_FILE = ['Upload another file', 'قم بتحميل ملف آخر']
export const PROCEED = ['Proceed', 'تقدم']
export const FILE_NAME_PLACEHOLDER = ['Write a file name', 'اكتب اسم ملف']
export const FILE_NAME_SUBTEXT = ['It will be easier for you to find your files later.', 'سيكون من الأسهل عليك العثور على ملفاتك لاحقًا.']
export const UPLOADED_FILE_NAME = ['Uploaded file name', 'اسم الملف الذي تم تحميله'];
export const SMART_CAMPAIGNS = ['Smart Campaigns', 'الحملات الذكية'];
export const SMART_CAMPAIGNS_NOTE = ["sends a message 1 hour before each user's last order time. Sends within 24 hour window for retention and segment targeting", "يرسل رسالة قبل ساعة واحدة من وقت الطلب الأخير لكل مستخدم. يرسل في غضون 24 ساعة للاحتفاظ واستهداف الشريحة"];
export const CLASIC_CAMPAIGNS = ['Classic Campaigns', 'الحملات الكلاسيكية'];
export const CLASIC_CAMPAIGNS_NOTE = ["sends the campaign to all people immediately", "يرسل الحملة إلى جميع الأشخاص على الفور"];
export const FILE_NAME_REQURIED = ['File name is required', 'اسم الملف مطلوب'];
export const INVALID_FILE_SIZE_ERROR = ['Invalid file size', 'حجم الملف غير صالح'];
export const FILE_HAS_NO_PHONES_ERROR = ["File doesn't contain phone numbers", 'لا يحتوي الملف على أرقام هواتف'];
export const INVALID_FILE_FORMAT_ERROR = ['Invalid file format', 'تنسيق ملف غير صالح'];
export const FILE_HAS_NO_VALID_PHONES_ERROR = ["File doesn't contain valid phone numbers", 'لا يحتوي الملف على أرقام هواتف صالحة'];
export const INVALID_COLUMN_HEADER_ERROR = ['Invalid column header', 'رأس العمود غير صالح'];
export const FILE_NAME_EXIST = ['file name already exist', 'اسم الملف موجود بالفعل'];
export const USEFUL_TIPS = ['Useful tips', 'نصائح مفيدة'];
export const ADD_CONTACTS_RIGHT_WAY = ['Add contacts the right way', 'أضف جهات الاتصال بالطريقة الصحيحة'];
export const CONTACTS_DOWNLOAD_INFO = ['You can upload a list of phone numbers to send the announcement campaign to. To get started, please fill in the template below.', 'يمكنك تحميل قائمة بأرقام الهواتف لإرسال الحملة الإعلانية إليها. للبدء، يرجى ملء النموذج أدناه.']
export const CONTACTS_DOWNLOAD_BTN = ['Download template', 'تحميل النموذج'];

export const CAMPAIGNS = ["SMS Campaigns", "حملات الرسائل النصية"]
export const CREATE_CAMPAIGN = ["Create campaign", "انشاء حملة"]
export const CAMPAIGN_LANGUAGE = ['Campaign Language', 'لغة الحملة']
export const CAMPAIGN_GOAL = ['Campaign Goal', 'هدف الحملة'];
export const CAMPAIGN_GOAL_DESCRIPTION = [
  'Goals help you decide the content and the offer to the target, select a goal that matches your need.',
  'تساعدك الأهداف على تحديد المحتوى والعرض على الهدف ، واختيار الهدف الذي يتناسب مع حاجتك',
];
export const PURCHASE = ["Purchase ", "شراء"]
export const SMS_CREDITS = ['Available SMS Credits', 'رصيد الرسائل النصية'];
export const CREDIT = ['Credit', 'رصيد'];
export const CREDITS = ['Credits', 'رصيد'];
export const CREDITS_LEFT = ["Credits left", "رصيد متبقي"]
export const CREDITS_CONSUMED = ["Credits consumed", "رصيد مستخدم"]
export const START_FIRT_CAMPAIGN = [
  "Launch your first campaign now",
  "ابدأ حملتك الأولى الآن",
]
export const ESTIMATE_REACH_ERROR = [
  'Please select a segment with at least 1 customer',
  'الرجاء تحديد شريحة بها عميل واحد على الأقل',
];
export const INPUT_LABEL_SMS = ['SMS' , 'رسالة'];
export const SUMMARY = ['Summary', 'ملخص'];
export const YOU_CAN_START_ADDING_CAMPAIGNS = [
  "Create SMS campaigns to connect with your customers, generate repeated orders and fuel your growth",
  "انشئ حملات رسائل نصية للتواصل مع العملاء لزيادة الطلبات المكررة و مضاعفة أرباحك",
]
export const ANNOUNCEMENT = ["Announcement", "إعلان"]
export const ANNOUNCEMENT_DESCRIPTION = ["Stay in contact with customers and give them updates.", "ابق على اتصال مع العملاء وأعطهم التحديثات."]

export const RETENTION = ["Retention", "حفاظ على العملاء"]
export const RETENTION_DESCRIPTION = [
  'Encourage your current customers to order again',
  'شجع عملائك الحاليين على الطلب مرة أخرى',
];
export const CAMPIAGNS_DETAILS_SECTION_ESTIMATE_REACH_CUSTOMERS = ['Customers', 'عميل'];
export const SEGMENT_TARGETING = ['Segment Targeting', 'استهداف الشرائح'];
export const SEGMENTS_TITLE = ['Segments', 'الشرائح'];
export const CONTACTS = ['Upload Phone Numbers', 'تحميل أرقام الهواتف'];
export const CONTACTS_COMING_SOON = ['Coming soon', 'قريباً'];

export const SEGMENT_TARGETING_DESCRIPTION = [
  'Target one of your customer segment with predefined goals',
  'استهدف شريحة عملائك بأهداف محددة مسبقًا',
];
export const ENCOURAGE_YOUR_CURRENT_CUSTOMERS = [
  "Turn your one-time customers into brand loyalists",
  "حول عملائك من طلب واحد إلى عملاء مخلصين لمشروعك التجاري",
]
export const SMS_CREDITS_WITHIN_RANGE_BUT_LESS_THAN_REQUIRED_WARNING = [
  'You do not have enough SMS credits to reach all estimated customers for this campaign. You can still launch this campaign but it will pause once credits reach 0.',
  'يس لديك ما يكفي من أرصدة الرسائل القصيرة للوصول إلى جميع العملاء المقدرين لهذه الحملة. لا يزال بإمكانك إطلاق هذه الحملة ولكنها ستتوقف مؤقتًا بمجرد وصول الأرصدة إلى ٠.',
];
export const SMS_CREDITS_BELOW_MINIMUM = [
  'You do not have enough SMS credits to launch this campaign.',
  'ليس لديك رصيد كافي من الرسائل القصيرة لبدء هذه الحملة.',
];
export const CONVERSION = ["Conversion", "تحويل العملاء"]
export const COMING_SOON = ["(Coming soon)", "قريباً"]
export const ATTRACY_NEW_CUSTOMERS = [
  "Attract your third party clients to order directly from your online store",
  "اجذب عملاء منصات التسوق الخارجية للطلب مباشرة عبر موقعك الإلكتروني",
]
export const FAILED_TO_LOAD_CAMPAIGN_SMS_PREVIEW = ['Failed to load sms preview', 'فشل تحميل معاينة الرسائل القصيرة'];
export const CAMPAIGN_TITLE = ["Campaign Title", "عنوان الحملة"]
export const EX_CHECK_OUR_OFFERS = [
  "Ex: New Year Campaign",
  "حملة المنتجات الجديدة",
]
export const SMS_CONTENT = ["SMS Content", "محتوى الرسالة"]
export const PLACEHOLDER_SMS_BODY = [
  "Ex: New items for you! [DL]. Place your order now and enjoy [VV] off, using voucher code =VC. Valid until [date]‫.‬",
  "منتجات جديدة من أجلك انت! اطلب الآن =DL و تمتع بخصم =VV عند استخدامك لكود =VC. هذا العرض ساري حتي {تاريخ}",
]
export const SMS_CONTENT_WARNING = [
  'If you edit the campaign details make sure to update these changes in the voucher section and/or the sms content.',
  'إذا قمت بتحرير تفاصيل الحملة ، فتأكد من تحديث هذه التغييرات في قسم القسيمة و/أو محتوى الرسائل القصيرة',
];
export const TOOLBOX = ["Toolbox", "الاختصارات"]
export const SMS_CONYENT_WARNING_WITHOUT_LINK = [
  `Make sure to use "=DL" in the toolbox which is converted to your store's website in the sent message. Alternatively, you can type your website link yourself.
  By adding your website, customers can identify who the SMS is from.`,
  `تأكد من استخدام "= DL" في صندوق الاختصارات الذي سيتم تحويله إلى موقع متجرك في الرسالة المرسلة. بدلاً من ذلك ، يمكنك كتابة رابط الموقع الخاص بك بنفسك.
  إضافة الموقع الخاص بك يوضح للعملاء هوية الرسائل القصيرة.`
];
export const NOTE = ['NOTE', 'ملاحظة']
export const ORDER_NOTE = ['Note', 'ملاحظة']
export const DIRECT_LINK = ["Direct link =DL", "رابط الطلب =DL"]
export const VOUCHER_CODE_TOOLBOX = ["Voucher code =VC", "كود خصم =VC"]
export const VOUCHER_VALUE_TOOLBOX = ["Voucher value =VV", "قيمة الخصم =VV"]
export const VOUCHER_EXPIRY_TOOLBOX = ['Voucher expiry =VE', 'تاريخ انتهاء القسيمة =VE']
export const MAXIMUM_CHARACTERS = [
  'Characters limit for SMS content is 480 English characters or 210 Arabic characters',
  'الحد الأقصى للحروف المسموح للرسالة الواحدة هو ٤٨٠ حرف إنجليزي أو ٢١٠ حرف عربي',
];

export const CAMPAIGN_TYPE = ["Campaign type", "نوع الحملة"]
export const SETTINGS = ["Settings", "الإعدادات"]
export const EX_30 = ["Ex.30", "Ex.30"]
export const EX_1000 = ["Ex.1000", "Ex.1000"]
export const INACTVITY_PERIOD = ['Inactivity Period', 'مدة عدم الشراء'];
export const INACTVITY_PERIOD_DESC = [
  'Set the amount of days to send an SMS message to the customer in case of no order submitted within this period.',
  'حدد عدد الأيام لإرسال رسالة نصية قصيرة للعميل في حالة من عدم تقديم الطلب خلال هذه الفترة',
];
export const LOOK_BACK_WINDOW = ['Look Back Window', 'فترة الاستهداف الماضية'];
export const LOOK_BACK_WINDOW_DESC = [
  'Set the amount of days for the campaign to look back and send to the customers who didn’t order within the set criteria',
  'عيّن عدد الأيام للحملة للنظر في الماضي وإرسالها إلى العملاء الذين لم يطلبوا ضمن المعايير المحددة',
];
export const ALL_CUSTOMERS = ["All customers", "كل العملاء"]
export const SPECIFIC_SEGMENTS = ["Specific segments", "شرائح محددة"];
export const INACTIVE_CUSTOMERS = ["Inactive customers", "العملاء غير النشطين"];

export const LAST_YEAR = ["Last Year", "العام الماضي"]
export const LAST_6_MONTHS = ["Last 6 months", "آخر ٦ شهور"]
export const LAST_3_MONTHS = ["Last 3 months", "آخر ٣ شهور"]
export const LAST_MONTH = ["Last Month", "الشهر الماضي"]
export const _OPTIONAL_ = ["(optional)", "اختياري"]
export const MESSAGES_LIMIT = ['Messages limit', 'الحد الأقصى للرسائل'];
export const MESSAGES_LIMIT_DESC = [
  'Set the limit for the number of SMS messages to be sent in this campaign.',
  'حدد الحد الأقصى لعدد الرسائل القصيرة التي سيتم إرسالها في هذه الحملة.',
];
export const MESSAGES_LIMIT_WARNING = [
  'Please note that depending on your SMS character length, one SMS may count as more than one credit.',
  'يرجى ملاحظة أنه بناءً على عدد أحرف الرسائل القصيرة الخاصة بك ، قد يتم احتساب رسالة قصيرة واحدة على أنها أكثر من رصيد واحد.',
];
export const NOTED_COOLDOWN_CAMPAIGN = [
  'Please note that there’s a 30-day cooldown period before sending the second message to the same customer to not mark your Sender ID as spam.',
  'يرجى ملاحظة أن هناك فترة توقف مدتها ٣٠ يومًا قبل إرسال الرسالة الثانية إلى نفس العميل لعدم وضع علامة على معرّف المرسل كرسالة غير مرغوب فيها.',
];
export const TARGETED_SEGMENT = ['Targeted Segment' , 'الشريحة المستهدفة'];
export const ESTIMATED_CREDITS = ['Estimated SMS Credits', 'معدل الرسائل المتوقع'];
export const ESTIMATED_RESULTS = ["Estimated Reach", "معدل الوصول المتوقع"]
export const ESTIMATED_RESULTS_DESC = [
  "The estimated number of customers your campaign will be targeting based on your chosen settings",
  "العدد المتوقع للعملاء الذين سيتم استهدافهم خلال الحملة بناءً على الإعدادات المسبقة",
]
export const REACH = ["Reach:", "الوصول"]
export const NUMBERS_CUSTOMERS = value => [
  `${value} Customers`,
  `عدد العملاء ${value}`,
]
export const AVERAGE_ORDER_VALUE_ROI = (profit, currency) => [
  `if this campaign converts 3%, you are looking to make ${profit} ${currency}`,
  `إذا كانت هذه الحملة تحول ٣٪ ، فستتطلع إلى تحقيق ${profit} ${currency}`,
];
export const SENDER_ID = ['Sender ID', 'هوية المرسل'];
export const SENDER_ID_DEFAULT_NOTE = [
  `If you want to send promotional smses to your customers using your own name, you can request for a custom sender ID, please contact support. Custom senderIDs are case-sensitive, they cant exceed 11 characters, and all characters must be alphanumeric. If you dont have a custom sender ID. You'll still be able to send promotional smses but it will be using 'OrderDirect'`,
  `إذا كنت ترغب في إرسال رسائل نصية قصيرة ترويجية إلى عملائك باستخدام اسمك الخاص ، فيمكنك طلب معرف مرسل مخصص ، يرجى الاتصال بالدعم. معرفات المرسل المخصصة حساسة لحالة الأحرف ، ولا يمكن أن تتجاوز 11 حرفًا ، ويجب أن تكون جميع الأحرف أبجدية رقمية. إذا لم يكن لديك معرف مرسل مخصص. ستظل قادرًا على إرسال رسائل نصية قصيرة ترويجية ولكنها ستستخدم "OrderDirect"`
];
export const SENDER_ID_CUSTOM_NOTE = senderId => [
  `You are using your custom senderid ${senderId}, if you want to change this, please reach out to customer support.`,
  `أنت تستخدم المرسل المخصص بك ${senderId} ، إذا كنت ترغب في تغيير ذلك ، فيرجى التواصل مع دعم العملاء.`
]

export const INSUFFICIENT_AVERAGE_ORDER_VALUE_ROI = [
  'Insufficient data to calculate average order values.',
  'بيانات غير كافية لحساب متوسط الطلبات.',
];
export const INSUFFICIENT_TOTAL_ORDERS_ROI = [
  'Number of orders are too low to calculate average order values.',
  'عدد الطلبات لا يكفي لحساب متوسط الطلبات.',
];

export const YOU_CAN_CREATE_DEDICAT_VOUCHER_CAMPAIGN = [
  "Encourage your customers to order, with a unique voucher created just for them",
  "شجّع عملائك على الطلب باستخدام كود خصم مخصص لهم فقط",
]
export const SAVE_AS_DRAFT = ["Save as Draft", "حفظ كمسودة"]

export const COMPLETED_CAMPAIGN = ["Completed", "مكتملة"]
export const DRAFT_CAMPAIGN = ["Draft", "مسودة"]
export const START_ON = ["Start on", "تبدأ في"]
export const END_ON = ["End on", "تنتهي في"]

export const MESSAGES_SENT = ["Messages sent", "الرسائل المرسلة"]
export const CAMPAIGN_DETAILS = ["Campaign details", "تفاصيل الحملة"]
export const SAMPLE_SMS_BODY = ["Sample SMS Body", "نموذج محتوي الرسالة"]
export const PERFORMANCE = ["Performance", "الأداء"]
export const STATE = ["State", "الحالة"]
export const SENT_SMS = ["Sent SMS", "الرسائل المرسلة"]
export const ACQUIRED_CUSTOMERS = ["Acquired customers", "العملاء الجدد"]
export const VOUCHER = ["Voucher", "القسيمة"]
export const VOUCHER_AMOUNT = ["Voucher Amount", "قيمة القسيمة"]
export const REDEMPTION_LIMIT = ["Redemption Limit", "حد الاستهلاك"]
export const REDEMPTION_LIMIT_PER_USER = ["Redemption Limit Per User", "حد الاستهلاك للعميل الواحد"]
export const TOTAL_VOUCHER_REDEMPTION = ["Total Voucher Redemptions", "إجمالي القسائم المستردة"]
export const NUMBER_VOUCHER_REDEMPTION = ["Number of Redemptions", "عدد القسائم المستردة"]
export const NUMBER_VOUCHER_REDEMPTION_TOOLTIP = ["Total number of orders with vouchers that were redeemed in this campaign", "العدد الإجمالي للطلبات بالقسائم التي تم استردادها في هذه الحملة"]
export const SALES_OF_VOUCHER_REDEMPTION = ["Sales of Redemptions", "مبيعات القسائم المستردة"]
export const SALES_OF_VOUCHER_REDEMPTION_TOOLTIP = ["Total sales of orders with vouchers that were redeemed in this campaign", "إجمالي مبيعات الطلبات بالقسائم التي تم استردادها في هذه الحملة"]
export const ORDERS_GENERATED = ["Orders generated", "إجمالي الطلبات"]
export const SALES_GENERATED = ["Sales generated", "إجمالي المبيعات"]
export const ORDERS_GENERATED_TOOLTIP = ["Total number of orders by your customer list within 7 days of receiving the SMS. Including orders without vouchers", "إجمالي عدد الطلبات وفقًا لقائمة العملاء الخاصة بك في غضون 7 أيام من استلام الرسالة القصيرة. (ويشمل أيضًا الطلبات بدون القسائم"]
export const SALES_GENERATED_TOOLTIP = ["Total sales of orders by your customer list within 7 days of receiving the SMS. Including orders without vouchers", "إجمالي مبيعات الطلبات حسب قائمة العملاء الخاصة بك في غضون 7 أيام من استلام الرسالة القصيرة. (يشمل أيضًا الطلبات بدون قسائم"]
export const USE = ["use", "استخدام"]
export const USES = ["uses", "استخدام"]

export const CAMPAIGN_TITLE_REQUIRED = [
  "Please add the campaign title",
  "من فضلك أدخل عنوان الحملة",
]
export const SMS_CONTENT_REQUIRED = [
  "Please enter the SMS body",
  "من فضلك قم بكتابة محتوى الرسالة",
]
export const CAMPAIGN_TYPE_REQUIRED = [
  "Please choose the campaign type",
  "من فضلك قم بإختيار نوع الحملة",
]
export const INACTIVITY_REQUIRED = [
  "Please set the Customer inactivity period.",
  "من فضلك قم بإدخال مدة عدم الشراء للعميل",
]
export const VALID_FOR_REQUIRED = ['Please set the voucher validity period.', 'من فضلك قم بإدخال مدة سريان القسيمة'];
export const VALID_FOR_MUST_BE_POSITIVE = [
  'Validity period must be a positive number',
  'يجب أن تكون فترة الصلاحية عددًا موجبًا',
];
export const VALID_FOR_MUST_BE_AN_INTEGER = [
  'Please enter a valid amount without decimal values',
  'الرجاء إدخال مبلغ صالح بدون قيم عشرية',
];
export const INACTIVITY_CANT_MORE_THAN_LOOKBACKWINDOW = [
  "Customer inactivity period can’t be more than the targeting window",
  "فترة عدم الشراء للعميل لا يمكن أن تزيد عن فترة الاستهداف لتجنب توقف الحملة",
]
export const START_DATE_REQUIRED = [
  "Please enter the start date",
  "من فضلك قم بإدخال تاريخ البدء",
]
export const END_DATE_CANT_BE_BEFORE_START_DATE = [
  "End date can't be before start date",
  "تاريخ الانتهاء لا يمكن أن يسبق تاريخ البدء",
]
export const END_DATE_CANT_BE_SAME_START_DATE = [
  "The end date can't be the same start date",
  "لا يمكن أن يكون تاريخ الانتهاء هو نفس تاريخ البدء",
]
export const PERCENTAGE_MAX_100 = [
  "The percentage value must be below 100",
  "قيمة النسبة المئوية يجب أن تكون ١٠٠ أو أقل",
]

export const MAX_IS = value => [`max is ${value}`, `الحد الأقصى هو ${value}`]

export const CAMPAIGN_TYPE_SUBTITLE = ['Campaign objective', 'هدف الحملة'];
export const CAMPAIGN_RECIPIENTS_SUBTITLE = ['Audience of message','جمهور الرسالة'];
export const CAMPAIGN_DETAILS_SUBTITLE = ['In-depth message information', 'معلومات رسالة متعمقة'];
export const CAMPAIGN_REVIEW_SUBTITLE = ['Revise and confirm SMS campaign', 'مراجعة وتأكيد حملة الرسائل القصيرة'];
export const CAMPAIGN_CONVERSION = (currency, averageOrderValue, conversionPercentage, lookingToMake) => [
  `Your average order value is ${currency} ${averageOrderValue}, if this campaign converts ${conversionPercentage}%, your looking to make ${currency} ${lookingToMake}`,
  `متوسط ​​قيمة طلبك هو ${currency} ${averageOrderValue} ، إذا كانت هذه الحملة تحول ${conversionPercentage}٪ ، فستتطلع إلى تحقيق ${currency} ${lookingToMake}`,
];

export const DRAFT_CAMPAIGN_MODAL = ["Draft campaign", "مسودة الحملة"]
export const DESC_DRAFT_CAMPAIGN = [
  "This campaign has been saved as a draft, purchase an SMS package to activate it",
  "تم حفظ الحملة كمسودة، لتفعيلها يرجى شراء باقة رسائل نصية",
]
export const GO_TO_CAMPAIGNS = ["Back to main page", "الرجوع للصفحة الرئيسية"]
export const PURCHASE_CREDITS = ["Purchase Credits", "شراء الباقات"]

export const START_YOUR_FIRST_CAMPAIGN = [
  "Retain Customers with Automated SMS Campaigns",
  "حافظ على عملائك عن طريق نظام حملات الرسائل النصية",
]
export const YOU_CAN_START_ADDING_CAMPAING = [
  "Retain your customers effectively and generate more revenue with 3 simple steps",
  "حافظ على عملائك و حقق أرباح أكثر بثلاث خطوات بسيطة",
]
export const SETUP_YOUR_CAMPAIGN = ["Choose your package", "إختيار الباقة"]
export const SETUP_YOUR_CAMPAIN_DESC = [
  "Purchase a package to charge your SMS credit",
  "اشحن رصيد الرسائل النصية بشراء الباقة المناسبة",
]
export const ADD_MARKETING_MESSAGE = ["Setup your campaign", "إعداد الحملة"]
export const ADD_MARKETING_MESSAGE_DESC = [
  "Customize your campaign, write your message, and choose whom to target",
  "صمم حملتك واكتب رسالتك واختر من تريد استهدافه",
]
export const RETAIN_ENGAGE = ["Retain and engage!", "إنطلاق الحملة"]
export const RETAIN_ENGAGE_DESC = [
  "Watch your orders multiply",
  "شاهد طلباتك تتضاعف",
]
export const SELECT_PACKAGE_SMS = [
  "Select the package that fits you best!",
  "اختر الباقة التي تناسبك",
]
export const SMS_PACKAGE = ["SMS", "الرسائل النصية"]
export const CAMPAIGN_UPDATED = ["Campaign is updated", "تم تحديث الحملة"]
export const DELETE_CAMPAIGN_CONFIRMATION = [
  "Are you sure you want to delete this campaign?",
  "هل أنت متأكد من حذف هذه الحملة؟",
]
export const CAMPAIGN_DELETED = ["Campaign is deleted", "تم حذف الحملة"]
export const MESSAGES_LIMIT_REQUIRED = [
  "Please set messages limit",
  "من فضلك اضف الحد الأقصى للرسائل",
]
export const ALL_STATUS = ["All Statuses", "جميع الحالات"]
export const BRANCHES_ALL = ["All Business Locations", "جميع الفروع"]

export const GROUP_NAME = ["Group Name", "اسم المجموعة"]
export const SET_OPTIONS_LIMIT = ["Set Options Limit", "تعيين حد للاختيار"]
export const SET_MIN_MAX_OPTIONS = [
  "Set minimum and maximum selection for this option group",
  "قم بتحديد الحد الأقصى و الحد الأدنى للاختيار لهذه المجموعة",
]
export const MINIMUM_SELECTION = ["Minimum Selection", "الحد الأدنى للاختيار"]
export const MAXIMUM_SELECTION = [
  "Maximum Selection (Optional)",
  "الحد الأقصى للاختيار",
]
export const GROUP_OPTIONS = ["Group Options", "مجموعة الخيارات"]
export const MULTIPLE_OPTIONS_GROUP = [
  "Add multiple options to this group",
  "اضف اختيارات متعددة لهذه المجموعة",
]
export const INSERT_GROUP_SUBTITLE = [
  "Insert a group of options for a  specific variant or multiple variants",
  "اضف مجموعة اختيارات لمتغير واحد أو عدة متغيرات",
]
export const SET_SELECTION_QUANTITY = [
  "Set selection quantity",
  "تعيين حد للاختيار",
]
export const SET_SELECTION_QUANTITY_NIM_MAX = [
  "Set the quantity of the minimum and maximum options",
  "قم بتحديد الحد الأقصى و الحد الأدنى للاختيار لهذه المجموعة",
]
export const SELECT_GROUP_VARIANT = [
  "Select group variant",
  "اختر مجموعة للمتغير",
]
export const INSERT_GROUP_OPTION = [
  "Search for the group you want to insert",
  "ابحث عن المجموعة التي تريد إضافتها",
]
export const NOTE_REQ = ["Note is required", "الملاحظة مطلوبة"]
export const SHOW_MORE = ["Show more", "عرض المزيد"]
export const SHOW_ALL = ["Show all", "عرض الكل"]
export const SHOW_LESS = ["Show less", "عرض أقل"]
export const EDIT_GROUP = ["Edit group", "تعديل مجموعة"]
export const DELETE_PRODUCT = ["Delete item", "حذف المنتج"]
export const NEW_OPTION = ["New Option", "إضافة خيار"]
export const NEW_VS_RETURNING = [
  "New vs. Returning Customers",
  "العملاء الجدد مقابل العائدين",
]
export const NEW = ["New", "جديد"]
export const RETURNING = ["Returning", "عائد"]
export const NEW_CUSTOMERS = ["New Customers", "العملاء الجدد"]
export const RETURNING_CUSTOMERS = ["Returning Customers", "العملاء العائدين"]
export const NOT_AVAILABLE_IN_YOUR_PLAN = [
  "This feature is not available on your plan",
  "تم إرسال التقريرإلى بريدك الإلكتروني",
]
export const NOT_AVAILABLE_IN_YOUR_PLAN_DESC = [
  "This feature is not available for your plan",
  "هذه الميزة غير متوفرة ضمن باقة إشتراكك",
]
export const VIEW_PLANS = ["View Plans", "عرض الباقات"]
export const FAILED_TO_LOAD_DATA = [
  "Failed to load the data",
  "فشل في تحميل البيانات",
]
export const CLICK_RELOAD = ["Click to reload.", "انقر لإعادة التحميل."]
export const SMS_ALMOST_FINISHED = [
  "Your SMS credit is low, running campaigns will pause once credit reaches zero.",
  "رصيد الرسائل منخفض، للمحافظة علي استمرار الحملة من فضلك قم بتجديد باقتك",
]
export const CAMAPIGN_ALMOST_REACH_LIMIT = [
  "The Campaign has less than 10% from the total messages to reach messages limit",
  "باقي أقل من ١٠٪ للوصول للحد الأقصي للرسائل",
]
export const CAMPAIGN_ALMOST_FINISHED = [
  "Your campaign will be completed soon",
  "الحملة أوشكت علي الانتهاء",
]

export const BEST_SELLING = ["Best Selling", "الأكثر مبيعاً"]
export const GEO_ANALYTICS = ["Branches & Delivery Areas", "الفروع و مناطق التوصيل"]
export const GEO_ANALYTICS_ZONES = ["Branches & Delivery Zones", "الفروع و مناطق التوصيل"]
export const AREA_PERFORMANCE = ["Area Performance", "أداء المنطقة"]
export const NUMBER_OF_CUSTOMERS = ["Number of Customers", "عدد العملاء"]

export const GEO_BREAKDOWN = ["Geo Breakdown", "التوزيع الجغرافي"]
export const PLACEHOLDER_SEARCH_BRANCHES = [
  "Search by branch name...",
  "البحث حسب الفرع",
]
export const PLACEHOLDER_SEARCH_AREAS = [
  "Search by area name...",
  "البحث حسب المنطقة",
]
export const PLACEHOLDER_SEARCH_ZONES = [
  "Search by zone name...",
  "البحث حسب المنطقة",
]
export const MAX_QUANTITY_LESS_THAN_OPTIONS = [
  "Max quantity should be less than or equal options' count",
  "على الحد الاقصى للاختيارات ألا يتعدى عدد الخيارات",
]
export const MIN_QUANTITY_LESS_THAN_MAX_QUANTITY = [
  "MIN quantity must be less than MAX quantity",
  "يجب أن يكون الحد الأدنى أقل من الحد الأقصى",
]

// Start Customers
export const CUSTOMERS_TABLE_HEADERS = {
  CUSTOMER_NAME: ["Customer Name", "اسم العميل"],
  PHONE_NUMBER: ["Phone Number", "رقم الهاتف"],
  FIRST_ORDER: ["First Order", "أول طلب"],
  LAST_ORDER: ["Last Order", "آخر طلب"],
  TOTAL_ORDERS: ["Total Orders", "عدد الطلبات الإجمالي"],
  TOTAL_SALES: ["Total Sales", "المبيعات الإجمالية"],
  SEGMENT: ["Segment", "قطعة"],
}
export const FIRST_ORDER = ["First Order", "أول طلب"]
export const VIEW_PROFILE = ["View Profile", "عرض ملف التعريف"]
export const CUSTOMER_ARCHIVED = [
  "Customer archived successfully",
  "تمت أرشفة العميل بنجاح",
]
export const ARCHIVE_CUSTOMER_CONFIRMATION = [
  "Are you sure you want to archive this customer?",
  "هل أنت متأكد من أرشفة هذا العميل؟",
]
export const ARCHIVE_CUSTOMER_CONFIRMATION_TIP = [
  "The customer will be moved to the archived list",
  "سيتم نقل العميل إلى القائمة المؤرشفة",
]
export const ARCHIVE_CUSTOMER = ["Archive Customer", "أرشفة العميل"]
export const FREE_DURING_BETA = [
  "Free during Beta",
  "مجانًا أثناء الإصدار التجريبي",
]
export const ORDER_HISTORY = ["Order History", "تاريخ الطلبات"]
export const PERSONAL_INFORMATION = ["Personal Information", "معلومات شخصية"]
export const SALES_INSIGHTS = ["Sales Insights", "رؤى المبيعات"]
export const SEGMENT_TITLE_ALL_CUSTOMERS = ['All Customers', 'كل العملاء']
export const SEGMENT_TITLE_ALL_CUSTOMERS_DESC = ['Everyone who has order through your website at anytime', 'كل من قام بالطلب من خلال موقعك في أي وقت']

export const SEGMENT_TITLE_CHAMPIONS = ["Champions", "الأبطال"]
export const SEGMENT_TITLE_LOYAL = ["Loyal", "الأوفياء"]
export const SEGMENT_TITLE_PROMISING = ["Promising", "المأمولين"]
export const SEGMENT_TITLE_NEW = ["New", "الجدد"]
export const SEGMENT_TITLE_LOW_VALUE = ["Low Value", "منخفضى القيمة"]
export const SEGMENT_TITLE_NEEDS_ATTENTION = [
  "Needs Attention",
  "بحاجة للإهتمام",
]
export const EMPTY_DELIVERY_ADDRESSES = [
  "There's no data available to show",
  "لا يوجد بيانات",
]
export const EXPORT_CUSTOMERS = [
  "Customers report has been sent to your email",
  "تم إرسال التقرير إلى بريدك الإلكتروني",
]
// End Customers

export const SUPPORT_TEAM_IS_READY_TO_HELP = [
  "Our support team is ready to help you",
  "نحن جاهزون للمساعدة",
]
export const GLAD_YOU_EXPAND = ["We’re glad you’re expanding", "سعداء لتوسعك"]
export const REQUEST_NEW_BRANCH = ["Request a new branch", "إضافة فرع جديد"]
export const CREATE_LATER = ["Later", "لاحقاً"]
export const UPDATES = ["What's new?", "ما هو الجديد؟"]
export const HELP_CENTER = ["Need Help", "مساعدة"]
export const REFER_PROGRAM = {
  TITLE: ["Get 3 months free", "احصل على 3 أشهر مجانًا"],
  ACTION: ["Refer a friend", "أوص بأحد الأصدقاء"]
}

// POS Manual Order
export const MANUAL_ORDER =['Create Order', 'طلب يدوي'];
export const MANUAL_ORDER_DROPDOWN = ['Manual Order','طلب يدوي'];
export const MANUAL_ORDER_ADDED_BY = operatorName => [
  `Manual Order added by ${operatorName}`,
  `أنشئ بواسطة ${operatorName}`,
];
export const CHANGING_TO =  ['Changing To ', `تغيير الى `];
export const SELECT_CUSTOMER = ['Select Customer', 'حدد العميل'];
export const LOOKS_LIKE_NEW_CUSTOMER = ['This looks like a new customer', 'هذا يبدو وكأنه عميل جديد'];
export const ADD_CUSTOMER = ['Add Customer', 'أضف عميل'];
export const COMPLETE_INFO = ['Complete Info.', 'المعلومات الكاملة'];
export const ENTER_CUSTOMER_FULL_NAME = ['Enter full name', 'أدخل الاسم بالكامل'];
export const CUSTOMER_CREATED_SUCCESSFULLY = ['Customer created successfully', ' تم إنشاء العميل الجديد بنجاح'];
export const STOREFRONT_CUSTOMER = ['Customer', 'عميل'];
export const NEW_ADDRESS = ['New Address', 'عنوان جديد'];
export const ADD_NEW_ADDRESS = ['Add New Address', 'إضافة عنوان جديد'];
export const FOR_EACH = ['for each', 'لكل عدد'];
export const SELECT_BRANCH = ['Select Branch', 'إختر الفرع'];
export const AVENUE_OPTIONAL = ['Avenue (optional)', '(اختياري)الجادة']
export const SPECIAL_DIRECTIONS_OPTIONAL = ['Special directions (optional)', 'إرشادات إضافية']
export const AREA_IS_REQUIRED = ['Area is required', 'إسم المنطقة مطلوب'];
export const STREET_IS_REQUIRED = ['Street is required', 'إسم الشارع مطلوب'];
export const BLOCK_IS_REQUIRED = ['Block is required', 'رقم القطعة مطلوب'];
export const BUILDING_IS_REQUIRED = ['Building is required', 'رقم المبنى مطلوب'];
export const FLOOR_IS_REQUIRED = ['Floor is required', 'رقم الطابق مطلوب'];
export const SAVE_AND_PROCEED = ['Save & Proceed to Order', 'حفظ ومتابعة للطلبات']
export const AREA_PLACEHOLDER = ['Select your area', 'أختر المنطقة'];
export const STREET_PLACEHOLDER = ['Enter street information', 'معلومات الشارع'];
export const BLOCK_PLACEHOLDER = ['Enter block information', 'رقم القطعة'];
export const AVENUE_PLACEHOLDER = ['Avenue name or number', 'رقم الجادة'];
export const BUILDING_PLACEHOLDER = ['Enter building information', 'رقم المبنى'];
export const FLOOR_PLACEHOLDER = ['Enter floor information', 'رقم الطابق'];
export const SPECIAL_DIRECTIONS_PLACEHOLDER = ['Enter special directions', 'إرشادات إضافية'];
export const CANCELLATION_REASON_REQ = ['Cancellation reason is required','مطلوب سبب الإلغاء'];
export const UNDERSCORE = [' - ', ' - '];
export const MANUALLY_ORDERED = ['Created', 'انشأ'];
export const APPLY_UPDATES = ['Apply Update?', 'تأكيد التغيرات؟'];
export const SELECTED_ORDERS = numberOfSelectedOrders => [
  `${numberOfSelectedOrders} selected`,
  `${numberOfSelectedOrders} ${numberOfSelectedOrders > 1 ? 'طلبات محددة' : 'طلب محدد'}`,
];
export const BULK_CHANGE_NOTIFICATION = numberOfSelectedOrders => [
  `${numberOfSelectedOrders > 1 ? `${numberOfSelectedOrders} orders are changing to ` : `${numberOfSelectedOrders} order is changing to `}`,
  `${numberOfSelectedOrders < 10 && numberOfSelectedOrders !== 1? 
  `جاري تحديث ${numberOfSelectedOrders} طلبات الى `
  : 
  `جاري تحديث ${numberOfSelectedOrders} طلب الى `}`,
];
export const BULK_CHANGE_CONFIRMATION_MESSAGE = [
  'This might take a while, It’s recommended to be done after 10pm, would you like to continue?',
  'من المحتمل أن تاخذ هذه العمليه بعض من الوقت، نفضل أن تقوم بها بعد العاشرة مساء، تريد الاستكمال؟'
];
export const DISCONNECTED_SUCCESSFULLY = ['Disconnected successfully', 'تم قطع الاتصال بنجاح'];
export const AUTHORIZED_SUCCESSFULLY = ['Authorized successfully', 'تم التفويض بنجاح'];

export const TAP_HERE_TO_ORDER = ['Tap here to order', 'أضغط هنا للطلب'];
export const ADD_LINKS_TO_ORDERFAST = ['Add new links to your Orderfast landing page to start share it with your customers', 'أضف روابط الي Orderfast'];
export const POWERED_BY_ZYDA = ['Powered by Zyda®','مدعم من زيدا®']

// Refund wallet
export const REFUND_AMOUNT_TITLE = price => [`Refund the Full Amount (${price}) to:`, `رد المبلغ بالكامل (${price}) إلى`];
export const ORDERFAST_WALLET = ['Customer Wallet', `محفظة Orderfast الخاصة بالعميل`];
export const ORDERFAST_WALLET_DISCLAMER = ["Refunds instantly appear in your customer's wallet.", `تظهر المبالغ المعادة في الحال في محفظة Orderfast الخاصة بالعميل.`];
export const INSTANT = ["Instant", "في الحال"];
export const ORDER_REFUND_CONFIRM = (orderNumber,refundType) => [
  `Do you want to refund for this order #${orderNumber} the customer’s ${refundType}?`,
`هل تريد  رد المبلغ بالكامل لهذا الطلب ${orderNumber}# إلى محفظة Orderfast الخاصة بالعميل؟`
]
export const REFUND_OPTIONS_MODAL_TITLE = (orderNumber, orderPrice) => [
`Do you want to refund for this order #${orderNumber} the Full Amount (${orderPrice})?`,
`هل تريد رد المبلغ بالكامل لهذا الطلب ${orderNumber}# (${orderPrice})؟`
]
export const REFUND_TO = ['Refund to', 'رد إلى'];
export const REFUND_LATER = ['Refund later', 'رد المبلغ لاحقًا'];
export const PROCEED_REFUND = ['Proceed Refund', 'متابعة رد المبلغ'];
export const ADD_COMPENSATION = ['Add compensation (a percentage of the order amount)', 'إضافة تعويض (نسبة من مبلغ الطلب)'];
export const TOTAL_REFUND = ['Total Refund', 'إجمالي المبلغ المردود'];
export const COMPENSATION = amount => [`Compensation (${amount}%)`, `التعويض (${amount}٪)`];
export const ORDERFAST_WALLET_AND_CREDIT_CARD = ['Credit Card / Wallet', 'بطاقة الائتمان / المحفظة'];
export const DELIVERECT_MODAL_STEP_1_TITLE= ['1. Connect with Deliverect', '1. اتصال مع دليفيركت'];
export const DELIVERECT_MODAL_STEP_2_TITLE= ['2. Link Business Locations', '2. ربط فروع زيدا'];


export const PAID_BY_WALLET = ['Paid by wallet', 'مدفوع من محفظة Orderfast'];
export const CASHBACK_CANCELLED = ['Cashback cancelled', 'تم إلغاء الخصم'];
export const CASHBACK_TO_WALLET = ['Cashback added to wallet', 'إضافة خصم للمحفظة'];
export const REFUND_TO_WALLET = ['Order refunded to wallet', 'استرداد قيمة الطلب للمحفظة'];
export const COMPENSATION_TO_WALLET = ['Compensation added to wallet', 'إضافة تعويض للمحفظة'];
export const ENGAGE_CASHBACK_TO_WALLET = ['Engage cashback added to wallet', 'إضافة خصم Engage للمحفظة'];
export const ENGAGE_CASHBACK_CANCELLED = ['Engage cashback cancelled', 'إلغاء خصم Engage'];





export const DELIVERECT_MODAL_STEP_2_DESCRIPTION = [`1- Login to your Deliverect account using the below link
2- Copy each of your Zyda business location ids listed below
3- Navigate to your corresponding branch location on Deliverect
4- Navigate to the ‘Zyda’ channel settings, and paste the location id under ‘External location id’`,
`برجاء تسجيل الدخول إلى حساب دليفيركت الخاص بك من خلال الرابط ادناه
انسخ كل من معرّفات مواقع زيدا التجارية المدرجة أدناه
انتقل إلى موقع الفرع المقابل على دليفيركت
انتقل إلى إعدادات قناة “زيدا” ، والصق معرف الموقع تحت “معرف الموقع الخارجي”
`];
export const DELIVERECT_MODAL_STEP_2_NOTE = ['Note: Please keep this window open until you’ve mapped all of your Zyda business locations to their corresponding Deliverect location channels.', 'ملاحظة: يرجى إبقاء هذه النافذة مفتوحة حتى تنتهي من تعيين جميع مواقع أعمال زيدا لقنوات مواقع دليفيركت المقابلة لها.'];
export const DELIVERECT_MODAL_LINK = ['Go to Deliverect', 'انتقل إلى Deliverect'];

export const DELIVERECT_MODAL_NO_BRANCHES = ['No branches found', 'لا يوجد فروع'];
export const DELIVERECT_MODAL_ADD_BRANCH = ['Add Branch', 'إضافة فرع'];

export const DELIVERECT_MODAL_TAX_INCLUSIVE = ['Tax inclusive', 'شامل الضريبة'];
export const DELIVERECT_MODAL_TAX_EXCLUSIVE = ['Tax exclusive', 'لا يشمل الضرائب'];
export const DELIVERECT_MODAL_TAX_TYPE = ['Tax Configuration', 'اعدادات الضرائب'];

export const DELIVERECT_MODAL_RELOAD = ['Reload', 'تحديث'];
export const DELIVERECT_MODAL_LINK_BRANCHES = ['Link Branches', 'ربط الفروع'];
export const POS_EXTERNAL_ID = [
  "POS External ID",
  "رقم المنطقة الخارجي",
]
export const POS_EXTERNAL_ID_PLACEHOLDER = [
  "Enter external id",
  "أدخل رقم المنطقة الخارجي",
]
export const OCIMS_EXTERNAL_ID_HINT = ['Branch ID on OCIMS system','رقم الفرع التعريفي على أنظمة أوسيمس'];
export const OCIMS_AREA_ID_HINT = ['The ID of store area for delivery order','رقم منطقة توصيل الفرع لطلبات التوصيل'];
export const OCIMS_VALIDATE_EMAIL = ['Validate User', 'تحقق من صحة المستخدم'];
export const OCIMS_VALIDATION_EMAIL_FAIL = ['Invalid Credentials', 'اسم المستخدم أو كلمة المرور غير صحيحة'];
export const OCIMS_STEP_1 = ['Verify User', 'تحقق من المستخدم'];
export const OCIMS_STEP_2 = ['Link Settings Ids', 'معرفات إعدادات الارتباط'];
export const OCIMS_STEP_3 = ['Link Bussiness Locations', 'ربط مواقع الأعمال'];
export const OCIMS_INTEGRATIONS_SUCCESS = ['Integration Completed!', 'تم الدمج!'];
export const OCIMS_INVALID_MERCHANT_ID = ['Merchant Id is required', 'معرف التاجر مطلوب'];
export const OCIMS_INVALID_EXTERNAL_ID = ['External Id is required', 'المعرف الخارجي مطلوب'];
export const OCIMS_INVALID_MENU_ID = ['Menu Id is required', 'معرف القائمة مطلوب'];
export const OCIMS_INVALID_DISOCUNT_ID = ['Discount Id is required', 'معرف الخصم مطلوب'];
export const OCIMS_INVALID_BRAND_NAME = ['Brand name is required', 'اسم العلامة التجارية مطلوب'];
export const OCIMS_AREA_ID = ['Enter Area ID', 'أدخل معرف المنطقة'];
export const OCIMS_AREA_ID_REQ = ['Area ID is required','معرف المنطقة مطلوب'];

export const AJWAN_EXTERNAL_ID_HINT = ['Branch ID on AJWAN system', 'رقم الفرع التعريفي على أنظمة أجوان'];
export const AJWAN_INVALID_MENU_ID = ['Menu Id is required', 'معرف القائمة مطلوب'];
export const AJWAN_INVALID_BRAND_NAME = ['Brand name is required', 'اسم العلامة التجارية مطلوب'];
export const AJWAN_INVALID_BRAND_ID = ['Brand Id is required', 'رقم العلامة التجارية مطلوب'];
export const AJWAN_STEP_1 = ['Link Settings Ids', 'معرفات إعدادات الارتباط'];
export const AJWAN_STEP_2 = ['Link Bussiness Locations', 'ربط مواقع الأعمال'];

export const LOYALTY_EMPTY = ['Create a loyalty program to retain your customers and increase your brand’s value, let them earn cashback after placing an order to use for their upcoming orders.', 'أنشئ برنامج ولاء للاحتفاظ بعملائك وزيادة قيمة علامتك التجارية ، والسماح لهم بكسب النقود بعد تقديم طلب لاستخدامه في طلباتهم القادمة.']
export const WALLET_EMPTY = ["You don’t have any customers’ wallets", "ليس لديك أي محافظ للعملاء"]
export const WALLET_EMPTY2 = ["Customers’ wallet data will appear here if you refunded or compensated amounts to their wallets or they earned cashback.", "ستظهر بيانات محفظة العملاء هنا إذا قمت برد أو تعويض مبالغ إلى محافظهم أو حصلوا على استرداد نقدي."]
export const ENDED = ['Ended', 'انتهى'];
export const STARTED = ['Started', 'بدأت'];
export const CASHBACK_CONTINOUS = (startDate) => [`From ${startDate} • Continuous `, `البدايه ${startDate} • مستمر`];
export const CASHBACK_DATE = (startDate, endDate) => [`From ${startDate} • To ${endDate}`, `البدايه ${startDate} • الانتهاء ${endDate}`];
export const NEW_CASHBACK_DETAILS = ['New cashback details', 'تفاصيل استرداد النقود الجديدة']
export const CREATE_CASHBACK_PROGRAM = ['Create Cashback Program', 'إنشاء برنامج الاسترداد النقدي']
export const ENTER_CASH_BACK_PROGRAM_NAME = ['Enter your cashback program name', 'ادخل اسم البرنامج'];
export const PROGRAME_NAME = ['Program Name', 'اسم البرنامج'];
export const RUN_PROGRAM_FOREVER = ['Run this program continuously', 'فعل هذا البنامج بدون نهايه'];
export const CHOOSE_END_DATE = ['Choose when this program will end', 'أختر متي ينتهي هذا البرنامج'];
export const PROGRAM_NAME_REQUIRED = [
  "Program name is required",
  "اسم البرنامج مطلوب",
]
export const PROGRAM_START_DATE_REQUIRED = [
  "Start date is required",
  "تاريخ البدء مطلوب",
]
export const PROGRAM_END_DATE_REQUIRED = [
  "End date is required",
  "تاريخ الانتهاء مطلوب",
]

export const START_DATE_BEFORE_END = [
  "The start date should be before the end date",
  "تاريخ البداية يجب أن يكون قبل تاريخ النهايه",
]

export const SELECT_PERCENTAGE = [
  "Select the percentage of the cashback that the customer should receive of his payable amount.",
  "أختر النسبه التي سوف يستلمها المستخدم",
]

export const PREVIOUS_CASHBACK_WILL_BE_DEACTIVATED = [
  "Any previous cashback program will be deactivated once this program is active.",
  "سوف يتم الغاء اي برنامج قبل ذلك",
]

export const ENABLE_ONLINE_PAYMENTS = [
  "Enable online payments",
  "فعل طرق الدفع المتقدمه ",
]

export const ACQUIRE_THE_POWER_OF_LOYALITY = [
  "Acquire the power of loyality programs",
  " استخدم برامج الولاء",
]


export const UNLOCK_LOYALITY_PROGRAMS = [
  "Unlock Loyalty Programs",
  " فعل برامج الولاء",
]

export const ARE_YOU_SURE_TO_DEACTIVATE = [
  "Are you sure you want to deactivate this program?",
  "هل متأكد من ايقاف هذا البرنامج؟",
]

export const ARE_YOU_SURE_TO_CACNEL = [
  "Are you sure you want to cancel this program?",
  "هل متأكد من الغاء هذا البرنامج؟",
]

export const UPGRADE_YOUR_FREE_PLAN = [
  "Chat with us",
  "تواصل مع فريق الدعم",
]

export const CONVERT_NEW_TO_LOYALS = [
  "Convert new into loyals",
  "شجع العملاء ليكرروا الطلب",
]

export const REASONS_TO_CONVERT_NEW_TO_LOYALS = [[
  "Your customers will get cashback to use on their upcoming orders.",
  "عملائك سوف يستردون نقود ليستخدمهم في طلباتهم القادمه",
], [
  "Be in control with customizable cashback percentages.\n",
  "تحكم في نسبة استرداد النقود للبرنامج",
]];

export const NEW_PLAN = ['New Plan', 'باقة جديدة'];

export const WALLET_BALANCE = [
  "Wallet Balance",
  "المال ف المحفظه",
]

export const ORDER_HASH = [
  "Order #",
  "طلب #",
]

export const TRANSACTIONS_HISTORY = [
  "Transactions History",
  "تاريخ المعاملات",
]

export const TOTAL_PAID_BY_WALLET = [
  "Total paid by wallet",
  "مجموع الدفع بالمحفظه",
]
export const TOTAL_PAID_BY_WALLET_TOOLTIP = [
 "Amounts redeemed from customers’ wallets", 
 "المبالغ المستردة من محفظة العملاء",
]

export const TOOLTIP_AMOUNT_IN_WALLETS = [
  "Money that is in customers’ wallets",
  "الأموال الموجودة في محفظة العملاء",
]

export const ORDERS_PAID_BY_WALLET = [
  "Orders paid by wallet",
  "مجموع الطلبات بالمحفظه",
]

export const ORDERS_VALUE = [
  "Total orders value",
  "قيمه الطلبات",
]

export const REFUNDED = [
  "Total Refunded",
  "تم استردادها"
]

export const COMPENSATIONS = [
  "Total Compensations",
  "كل تعويضات"
]

export const CASHBACK = [
  "Total Cashback",
  "مجموع الاسترداد النقدي"
]

export const AMOUNT_IN_WALLETS = [
  "Amount in wallets",
  "النقود في المحفظه"
]

export const LAYOUT_ACCESS_MATRIX = {
  CUSTOMERS: {
    featureTitle: ['Unlock more of CRM with Growth Plan', 'افتح المزيد من CRM مع خطة النمو'],
    featureDescription: ['Campaigns that drive results.', 'الحملات التي تؤدي إلى النتائج.'],
    featureListTitle: ['Complete customers overview', 'نظرة عامة كاملة على العملاء'],
    featureList: [
      ['Easily track individuals or groups of customers.', 'تتبع الأفراد أو مجموعات العملاء بسهولة.'],
      ['Customers are segmented into six groups.', 'يتم تقسيم العملاء إلى ست مجموعات.'],
      [
        'Track each group, observe changes, and try to keep good customers while acquiring new loyal ones.',
        'تتبع كل مجموعة ، ولاحظ التغييرات ، وحاول الاحتفاظ بالعملاء الجيدين مع اكتساب عملاء مخلصين جدد.',
      ],
    ],
  },
  SALES_ANALYTICS: {
    featureTitle: ['Unlock more Analytics with the Standard Plan', 'أطلق العنان للمزيد من التحليلات باستخدام الخطة الرئيسية'],
    featureDescription: ['Acquire the power of insights', 'اكتساب قوة البيانات'],
    featureListTitle: ['Complete overview', 'نظرة عامة كاملة'],
    featureList: [
      ['Sales analytics.', 'تحليلات المبيعات.'],
      ['Branches analytics.', 'تحليلات الفروع.'],
      [
        'Top selling items.',
        'العناصر الأكثر مبيعًا.',
      ],
    ],
  },
  BEHAVIOR_BREAKDOWN: {
    featureTitle: [
      'Unlock more of Analytics with Growth Plan',
      'أطلق العنان للمزيد من التحليلات باستخدام خطة النمو',
    ],
    featureDescription: ['Acquire the power of insights', 'اكتساب قوة الرؤى'],
    featureListTitle: ['Compare behaviors', 'قارن بين السلوكيات'],
    featureList: [
      [
        'All the data you need on trends like delivery vs. pickup, cash vs. credit card, and new vs. returning customers.',
        'جميع البيانات التي تحتاجها حول اتجاهات مثل التسليم مقابل الاستلام ، والنقد مقابل بطاقة الائتمان ، والعملاء الجدد مقابل العائدين.',
      ],
    ],
  },
  BEST_SELLING: {
    featureTitle: [
      'Unlock more of Analytics with Growth Plan',
      'أطلق العنان للمزيد من التحليلات باستخدام خطة النمو',
    ],
    featureDescription: ['Acquire the power of insights', 'اكتساب قوة الرؤى'],
    featureListTitle: ['Best-selling', 'أفضل مبيعات'],
    featureList: [
      [
        'You have access to best-selling items and categories.',
        'لديك حق الوصول إلى العناصر والفئات الأكثر مبيعًا.',
      ],
      [
        'Each contains further breakdowns like total sales, number of orders, and quantities sold.',
        'يحتوي كل منها على مزيد من التفاصيل مثل إجمالي المبيعات وعدد الطلبات والكميات المباعة.',
      ],
    ],
  },
  GEO_ANALYTICS: {
    featureTitle: [
      'Unlock more of Analytics with Growth Plan',
      'أطلق العنان للمزيد من التحليلات باستخدام خطة النمو',
    ],
    featureDescription: ['Acquire the power of insights', 'اكتساب قوة الرؤى'],
    featureListTitle: ['Branch performance', 'أداء الفرع'],
    featureList: [
      ['Find detailed numerical breakdowns for each branch.', 'ابحث عن توزيعات رقمية مفصلة لكل فرع.'],
      [
        'Further breakdown by delivery zones. Compare performances and find trends.',
        'مزيد من التفصيل حسب مناطق التسليم. قارن العروض وابحث عن الاتجاهات.',
      ],
    ],
  }
}

export const GET_WITH_GROWTH = ['Get with Growth Plan', 'احصل مع خطة النمو'];
export const GET_WITH_STANDARD = ['Get with Standard Plan', 'احصل مع الخطة الاساسية'];
export const PERCENTAGE_OFF = ["% off", "٪ تخفيض"];

// Branding Experience page
export const DEFAULT_LANGUAGE = ['Default language', 'اللغة الافتراضية'];
export const SELECT_LANGUAGE = ['Select your website’s default language.', 'حدد اللغة الافتراضية لموقعك .'];
export const FONT = ['Font', 'الخط'];
export const SELECT_FONT = ['Select a font for your website.', 'حدد خط لموقع الخاص بك.'];
export const BRAND_COLOR = ['Brand Color', 'لون العلامة التجارية'];
export const SELECT_COLOR = ['Select the brand color of your brand.', 'حدد لون العلامة التجارية.'];
export const LOGO = ['Logo', 'شعار'];
export const SELECT_LOGO = ['Customize the section containing your logo', 'تخصيص القسم الذي يحتوي على شعارك'];
export const BUTTONS = ['Buttons', 'أزرار'];
export const SELECT_BUTTONS = ['Customize the look of the buttons', 'تخصيص مظهر الأزرار'];
export const CIRCLE = ['Circle', 'دائرة'];
export const SHARP = ['Sharp', 'حاد'];
export const ROUNDED = ['Rounded', 'مدور'];
export const SELECT_MENU = ['How would you like your menu to appear for your customers.', 'كيف تريد أن تظهر قائمتك لعملائك.'];
export const GRID = ['Grid', 'شبكة'];
export const SCROLLABLE = ['Scrollable', 'سحب'];
export const ONE_PAGE = ['One Page', 'صفحة واحدة'];
export const LIST = ['List', 'قائمة'];
export const MODERN = ['Modern', 'عصري'];
export const CLEAN = ['Clean', 'جمالي'];
export const FANCY = ['Fancy', 'فاخر'];
export const BOLD = ['Bold', 'سميك'];
export const LIGHT = ['Light','رفيع'];
export const MONO = ['Mono', 'وحيد'];

// Homepage

// Active orders
export const ACTIVE_ORDERS = ['Active Orders', ' الطلبات قيد التشغيل'];
export const VIEW_ALL_ORDERS = ['View All Orders', 'اظهر كل الطلبات'];
export const YOU_HAVE_NO_ACTIVE_ORDERS = ["You've no active orders!", '!ليس لديك طلبات قيد التشغيل'];

// Top Sales
export const TOP_FIVE_SALES = ['Top 5 selling items', 'لعناصر الخمسة الأكثر مبيعًا'];
export const SOLD_QUANTITY = ['Sold Qty.', 'الكمية المباعة'];
// ENGAGE
export const WHATSAPP_MARKET_AUTOPILOT = ['WhatsApp marketing on autopilot', 'التسويق عبر WhatsApp على الطيار الآلي'];
export const WHATSAPP_MARKET_AUTOPILOT_MESSAGE = ['So you can make money while focusing on other aspects of your business. Set it up once and earn forever', 'حتى تتمكن من كسب المال مع التركيز على جوانب أخرى من عملك. قم بإعداده مرة واحدة واكسب إلى الأبد']
export const SEE_HOW_IT_WORKS = ['See how it works', 'انظر كيف يعمل'];
export const GENERATED_SALES = ['Generated sales', 'ولدت المبيعات'];
export const GENERATED_SALES_TOOLTIP = ['The total sales in the past 30 days that were attributed to ENGAGE (attribution is calculated as an order placed within 10 days sending the customer the Whatsapp message', 'إجمالي المبيعات في آخر 30 يومًا المنسوبة إلى إنجيج (يتم احتساب الإسناد كطلب تم تقديمه في غضون 10 يومًا من إرسال رسالة واتساب إلى العميل'];
export const TOTAL_ENGAGEMENTS = ['Total Engagements', 'إجمالي المشاركات'];
export const SENT = ['Sent', 'مرسل'];
export const LOST_CUSTOMERS = ['Customers Lost', 'فقد العملاء'];
export const TARGETED_CUSTOMERS = ['Targeted Customers', 'العملاء المستهدفون'];
export const RETENTION_PROGRESS = ['Lifetime retention progress', 'تقدم الاحتفاظ مدى الحياة'];
export const RETENTION_PROGRESS_TOOLTIP = ['This is the retention funnel for your customers in ENGAGE. This shows the funnel from their first, second and third purchase. You can also track the conversion rate between each step', ' هذا هو مسار تحويل الاستبقاء لعملائك في إنجيج. يوضح هذا مسار عملية الشراء الأولى والثانية والثالثة. يمكنك أيضًا تتبع معدل التحويل بين كل خطوة'];
export const FIRST_TIME_ORDER_VALUE = ['Sales from first order', 'المبيعات من الدرجة الأولى'];
export const ORDER_PLACED = ['Orders placed', 'الطلبات صنفت'];
export const SECOND_TIME_ORDER_VALUE = ['Sales from second order', 'مبيعات من الدرجة الثانية'];
export const THIRD_TIME_ORDER_VALUE = ['Sales from third order', 'مبيعات من الدرجة الثالثة'];
export const DEACTIVATE_ENGAGE_MESSAGE = ['Deactivating engage autopilot will stop your automatic retention program from working and might decrease your number of orders.', 'سيؤدي إلغاء تنشيط الطيار الآلي إلى إيقاف برنامج الاحتفاظ التلقائي الخاص بك عن العمل وقد يقلل من عدد الطلبات الخاصة بك.'];
export const PROCEED_TO_DEACTIVATE = ['Proceed to deactivation', 'انتقل إلى التعطيل'];
export const DEACTIVATE_ENGAGE_CONFIRM_MESSAGE = ['Engage autopilot has been deactivated.', 'تم تعطيل إشراك الطيار الآلي'];
export const DEACTIVATE_ENGAGE_CONFIRM_TITLE = ['Are you sure you want to deactivate?', 'هل أنت متأكد أنك تريد إلغاء التنشيط؟'];
export const DEACIVATE_ENGAGE_NOTE = ['Engage has been disabled, you may be seeing outdated information. Click to re-enable engage and resume your autopilot', 'تم تعطيل المشاركة ، ربما ترى معلومات قديمة. انقر لإعادة تمكين الانخراط واستئناف الطيار الآلي الخاص بك'];
export const ACTIVATE_ENGAGE_MESSAGE = ['Engage autopilot has been enabled.', 'تم تفعيل إشراك الطيار الآلي.'];
export const ENGAGE_LOW_ORDER_NOTE = ['Your Engage Autopilot is now running automatically', 'يعمل برنامج انجيج الآن تلقائيًا'];
export const NO_DATA_FOUND = ['No data found', 'لاتوجد بيانات.'];
export const ENGAGE_DISABLE_NOTE_1 = ['Automatic welcome messages for new customers', 'رسائل ترحيب تلقائية للعملاء الجدد'];
export const ENGAGE_DISABLE_NOTE_1_DESC = ['When a new order is placed for the first time, a Whatsapp welcome message will be sent after 2 hours ', 'عندما يتم وضع طلب جديد للمرة الأولى، سيتم إرسال رسالة ترحيب عبر واتساب بعد مرور 2 ساعات'];
export const ENGAGE_DISABLE_NOTE_2 = ['Encouraging second orders from promising customers', 'تشجيع الطلبات الثانية من العملاء الواعدين'];
export const ENGAGE_DISABLE_NOTE_2_DESC = ['After 20 days of inactivity, another Whatsapp message is sent to encourage them to place their second order', 'بعد مضي 20 يومًا من عدم النشاط، يتم إرسال رسالة أخرى عبر واتساب لتشجيعهم على تقديم طلبهم الثاني'];
export const ENGAGE_DISABLE_NOTE_3 = ['Convert regulars to loyal customers', 'تحويل المنتظمين إلى عملاء مخلصين'];
export const ENGAGE_DISABLE_NOTE_3_DESC = ['After 20 days of inactivity, we encourage their third order with a final Whatsapp message', 'بعد مرور 20 يومًا من الخمول، نشجع على طلبهم الثالث من خلال رسالة نهائية عبر واتساب'];
export const ACTIVATE_WHATSAPP_AUTOPILOT = ['Activate WhatsApp Autopilot', 'قم بتنشيط WhatsApp Autopilot'];
export const ACTIVATE_WHATSAPP_AUTOPILOT_DESC = ['Customize your autopilot', 'تخصيص Whatsapp Autopilot الخاص بك'];
export const ONLINE_PAYMENT_NOTE_1 = ['You’re customers will be able to use online payment methods such as Knet, Credit card, Apple pay.', 'ستتمكن عملائك من استخدام طرق الدفع الإلكترونية مثل كي نت، بطاقة الائتمان، وأبل باي.'];
export const ONLINE_PAYMENT_NOTE_2 = ['Enable your customers to get a contactless experience.', 'تمكين عملائك من الحصول على تجربة لاسلكية الملامسة.'];
export const ONLINE_PAYMENT_NOTE_3 = ['Wallets will be enabled to all customers who use online payments', 'ستتمكن جميع العملاء الذين يستخدمون الدفع الإلكتروني من استخدام المحافظ الإلكترونية.'];
export const DELETED_PAYMENT_GATEWAY_NOTE = ['You need to have  an active payment gateway. Deleting payment gateway will also deactivate Engage. Are you sure you want to proceed?', 'هل تريد بالتأكيد المتابعة وحذف بوابة الدفع النشطة؟ سيؤدي ذلك أيضًا إلى إلغاء تفعيل Engage.']
export const TARGETED_CUSTOMERS_DESC = ['Customers who are scheduled to receive encouraging re-order messages.', 'العملاء الذين تم جدولتهم لتلقي رسائل تشجيعية لإعادة الطلب.'];
export const MESSAGES_SENT_DESC = ['Messages that have already been sent to customers.', 'الرسائل التي تم إرسالها بالفعل للعملاء.'];
export const LOST_CUSTOMERS_DESC = ['Non-ordering customers within the attribution window.', 'العملاء الذين لم يقموا بطلب أي منتجات خلال فترة الإحالة.'];
export const ENGAGE_LOW_ORDER_NOTE_DESC = ["There's nothing more you need to do. Sit back and relax and let the Autopilot retain them over time.", "أنت لا تحتاج إلى فعل أي شيء آخر. استرخ ودع الطيار الآلي يحتفظ بعملائك الجدد بمرور الوقت."];
export const TEMPLATE_ONE_TITLE = ["Pick a reward message after a customer's first order", 'اختر رسالة مكافأة بعد أول طلب للعميل'];
export const TEMPLATE_ONE_TITLE_DESC = ["This is the message that will be sent to the customer after their first order", 'هذه هي الرسالة التي سيتم إرسالها إلى العميل بعد أول طلب له'];
export const TEMPLATE_TWO_TITLE = ['Pick a message to encourage 2nd orders', 'اختر رسالة لتشجيع الطلبات الثانية'];
export const TEMPLATE_TWO_TITLE_DESC = ["After 30 days we send a message with an optional reward to the customer to encourage their second order.", "بعد 30 يومًا، نرسل رسالة بمكافأة اختيارية للعميل لتشجيعه على طلبه الثاني."];
export const TEMPLATE_THREE_TITLE = ['Pick a message to encourage 3rd orders', 'اختر رسالة لتشجيع الطلبات الثالثة'];
export const TEMPLATE_THREE_TITLE_DESC = ["After the second order we encourage their third order with another optional reward.", "بعد أن يقدموا طلبهم الثاني ، نشجعهم على ترتيبهم الثالث بمكافأة اختيارية أخرى"];
export const VOUCHER_PRECENTAGE = ['Voucher %', 'نسبة القسيمة'];
export const REWARD_AMOUNT = ['Reward amount', 'مبلغ المكافأة'];
export const MIN_1 = ['Minimum 1%', 'لا تقل عن 1٪'];
export const MAX_100 = ['Maximum 100%', '100٪ كحد أقصى'];
export const CURRENT_TEMPLATE_ONE_TITLE = ['The incentive for the first message', 'الحافز للرسالة الأولى'];
export const CURRENT_TEMPLATE_TWO_TITLE = ['The incentive for the second message', 'الحافز للرسالة الثانية'];
export const CURRENT_TEMPLATE_THREE_TITLE = ['The incentive for the third message', 'الحافز للرسالة الثالثة'];
export const MIN_0_1_AMOUNT = ['Minimum 0.1', 'لا تقل عن 0.1'];
export const MAX_100_AMOUNT = ['Maximum 100', '100 كحد أقصى'];
export const CURRENT_SETTING_TITLE = ['Incentives Structure', 'هيكل الحوافز']
export const FIRST_INCENTIVE = ['First Incentive','الحافز الأول'];
export const SECOND_INCENTIVE = ['Second Incentive','الحافز الثاني'];
export const THIRD_INCENTIVE = ['Third Incentive','الحافز الثالث'];

// ADD RIDER MODAL
export const DELETE_RIDER_ERROR = name => [`Driver ${name} could not be deleted, as he is still assigned to active orders.`,`لا يمكن حذف السائق ${name}، لانه مكلف بطلبات قيد التنفيذ`]
export const DELETE_RIDER_MODAL_TITLE = ['Unable to delete rider', 'لا يمكن حذف السائق']
export const DELIVER_BY_VERD_DISPATCH = ['Deliver by VERD Dispatch', 'التوصيل عن طريق VERD Dispatch']
export const ADD_RIDER_SUCCESS = ['Congrats on adding your first rider! You can manage your riders in the VERD tab on the left', 'تهانينا على إضافة سائقك الأول! يمكنك إدارة سائقي التوصيل الخاصين بك في قسم التوصيل على اليمين']
export const ADD_RIDER = ['Add rider',' اضف سائق']
export const RIDER_NAME = ['Rider name', 'اسم السائق']
export const WATCH_VIDEO = ['Watch Video', 'شاهد الفيديو']
export const CONTROL_FLEET = ['Easily control your own fleet from one single place.', 'تحكم بسهولة في أسطول التوصيل الخاص بك من مكان واحد']
export const SKIP_FOR_NOW = ['Skip for now', 'تخطي في الوقت الراهن']
export const ESTIMATED_COST = ['Estimated Cost', 'التكلفة المتوقعة']
export const DELIVERY_COST = ['Delivery cost', 'تكلفة التوصيل']
export const SERVICE_FEES = ['Service fees', 'رسوم الخدمة']

// QR CODE PAGE
export const QR_CODE = ["QR Code", "رمز الاستجابة السريعة"];
export const QR_CODE_HEADER = ["We are working on dynamic QR codes", "نحن نعمل على رموز QR الديناميكية"];
export const QR_CODE_DESCRIPTION = ['Our next objective is to deliver a fully dynamic QR code generator that enables you to have more orders with your code.', "هدفنا التالي هو تقديم منشئ رمز الاستجابة السريعة الديناميكي بالكامل والذي يمكّنك من الحصول على المزيد من الطلبات باستخدام الرمز الخاص بك."];
export const QR_CODE_STYLE = ['QR Code Style', 'شكل رمز الاستجابة السريعة'];
export const SELECT_QR_CODE_STYLE = ['Select your QR code style', 'اختار شكل رمز الاستجابة السريعة الخاص بك'];
export const RESTAURANT_LOGO = ['Restaurant Logo', 'لوجو المطعم'];
export const ADD_RESTAURANT_LOGO = ['Add your restaurant logo to the QR code ', 'أضف لوجو  مطعمك إلى رمز الاستجابة السريعة'];
export const SHOW_LOGO_IN_QR = ['Show logo in the QR code', 'اظهر اللوجو في الرمز'];
export const DOWNLOAD_QR_CODE = ['Download QR Code', 'تنزيل رمز الاستجابة السريع'];
export const SEE_PERFORMANCE = ['You can track the performance using the UTM code.', 'يمكنك تتبع الأداء باستخدام كود UTM في الرابط.'];
export const COPY_QR_CODE_LINK = ['Copy QR Code', 'نسخ رمز الاستجابة السريع'];

// VERD SECTION
export const DELIVERY_BY_VERD = ['Delivery by Verd', 'التسليم عن طريق Verd'];
export const DELIVERY_FEE_DEDUCTED = ['Delivery Fee • Deducted from order amount', 'رسوم التوصيل • تخصم من مبلغ الطلب'];
export const PROCESSING_FEES = ['Processing Fees', 'رسوم العملية'];
export const DELIVERY_BY_COURIER = courier => [`Delivery by ${courier}`, `التسليم عن طريق ${courier}`];
export const SEND_ORDER_THROUGH_WHATSAPP = ['Send order details to driver through whatsapp automatically.', 'أرسل تفاصيل الطلب إلى السائق باستخدام الواتساب'];
export const REALTIME_UPDATES = ['Realtime updates on the order.', 'تحديثات على حالة الطلب تلقائيا'];
export const ASSIGN_THROUGH_WHATSAPP = ['Assign rider via Whatsapp', 'تعيين سائق من خلال واتساب'];
export const NEW_ETA_CONFIRMATION = ['New ETA confirmation', 'تأكيد للوقت المقدر الجديد'];
export const DRIVER_MAX_CAPACITY_ERROR = ['The selected orders are more than driver maximum capacity', 'عدد الطلبات المختارة اكبر من عدد الطلبات التي يمكن تعيينها للسائق'];
export const BULK_CHANGE_ERROR = ["You are not allowed to change completed or cancelled orders", "لا يمكنك تغيير الطلبات المكتملة او الملغاة"]
export const BULK_ASSIGN_ERROR = ["You are not allowed to assign completed or cancelled orders", "لا يمكنك تعيين الطلبات المكتملة او الملغاة"]
export const FAILED_TO_ASSIGN_ERROR = (failedOrders) => [`${failedOrders.length > 1 ? `These orders are failed to be assigned: ${failedOrders.join(', ')}` : `This order is failed to be assigned: ${failedOrders[0]}`}`, `${failedOrders.length > 1 ? `فشل تعيين هذه الطلبات: ${failedOrders.join(', ')}` : `فشل تعيين هذا الطلب: ${failedOrders[0]}`}`];
export const ORDERS_NUMBERS_ERROR = ['Number of orders exceeds driver max capacity', 'عدد الطلبات يتخطى الحد الاقصى للسائق']
export const RIDER_BRANCH_ERROR = ['Driver is not available for this branch', 'هذا السلئق غير معين لهذا الفرع']

export const SHARE = ['Share', 'مشاركة']

// ASSIGN DRIVER POPUP
export const CHANGE_DELIVERY = ['Change delivery', 'تغيير السائق'];
export const ASSIGN_DELIVERY = ['Assign delivery', 'تعيين سائق'];
export const MANAGE = ['Manage', 'إعدادات'];
export const SEARCH_PLACEHOLDER = ['Search couriers and riders', 'البحث عن سعاة أو سائقين'];
export const ASSIGN_LATER = ['Assign later', 'تعيين لاحقًا'];
export const ASSIGN_NOW = ['Assign now', 'تعيين الآن'];
export const ADD_AND_ASSIGN_NOW = ['Add & assign now', 'اضف وعين الان'];
export const ADD_AND_ASSIGN_RIDER_SUCCESS = ['Congrats on adding a rider! You can manage your riders in the VERD tab on the left', 'تهانينا على إضافة سائق! يمكنك إدارة سائقي التوصيل الخاصين بك في قسم التوصيل على اليمين']
export const LAST_ASSIGNMENT = ['Last Assignment', 'التعيين الاخير'];
export const CLIENT_WILL_BE_NOTIFIED = ['The client will be notified with the driver name', 'سيتم إخطار العميل باسم السائق'];
export const ADD_TO_DRIVERS_LIST = ['Add to my list of drivers for future assignments', 'أضف إلى قائمة السائقين للتعيينات المستقبلية'];
export const OR = ['OR', 'أو'];
export const ASSIGN_NOTIFY_WHATSAPP = ['Driver will be notified by Zyda via WhatsApp', 'سيتم إخطار السائق بواسطة زيدا عبر الواتساب'];
export const ASSIGN_DRIVER = ['Assign driver', 'تعيين سائق'];
export const CURIOUS_TO_LEARN = ['Curious to learn more? Watch below.', 'هل لديك فضول لمعرفة المزيد؟ شاهد أدناه.'];
export const PROCESS_FEES_APPLY = ['Processing fees apply', 'تطبق رسوم المعالجة'];

// Summary
export const VIEW_SALES_ANALYTICS = ['View All Analytics', 'عرض كل التحليلات'];

// verd settings
export const ENABLE_VERD_DESC = ['Manage your delivery fleet', 'إدارة أسطول التوصيل الخاص بك'];
export const RIDER_CAPACITY = ['Rider capacity', 'عدد الطلبات لكل سائق'];
export const RIDER_CAPACITY_DESC = ['How many orders can be assigned to the rider simultaneously', 'عدد الطلبات التي يمكن تعيينها للسائق في وقت واحد'];
export const AUTO_ASSIGN_RIDER = ['Auto-assign Rider', 'التعيين التلقائي للسائق'];
export const AUTO_ASSIGN_RIDER_DESC = ['Automatically assign orders to riders after accepting an order', 'قم بتعيين الطلبات تلقائيًا للركاب بعد قبول الطلب'];
export const ROUND_ROBIN = ['Round robin', 'تعيين تبادلي'];
export const ROUND_ROBIN_DESC = ['Orders are assigned rotationally', 'يتم تعيين الطلبات دورياً على السائقين'];
export const AFTER_COURIER_FAILS = ['After courier fails', 'بعد فشل مقدم خدمة التوصيل'];
export const AFTER_COURIER_FAILS_DESC = ['in case courier declined assignment, Order is assigned to the next available driver', 'في حالة رفض الطلب من شركة التوصيل يتم تعيين الطلب دورياً لأول سائق متوفر'];
export const DO_NOT_AUTO_ASSIGN = ['Do not auto-assign', 'ايقاف خدمة التعيين الذاتي'];
export const MAX_ORDER_PER_RIDERS = ['Max. orders per rider', 'الحد الاقصي للطلبات لكل سائق'];
export const WHATSAPP_FLOW = ['WhatsApp flow', 'رسائل الواتس اب'];
export const WHATSAPP_FLOW_DESC = ['Choose between 2 different flows for WhatsApp to best suit your store preferences', 'إختار الأنسب لمتجرك من بين طريقتين مختلفتين لإستلام رسائل الواتس اب'];
export const DEFAULT_FLOW = ['Default flow (Accept, Pickup, & Delivered)', 'التعيين الأساسي (رسائل قبول الطلب، الإستلام و التوصيل)'];
export const DEFAULT_FLOW_DESC = ['This feature allows us to track performance analytics of riders', 'تتيح لنا هذه الميزة قياس أداء السائقين لمتجرك'];
export const QUICK_ASSIGN_FLOW = ['Quick Assign flow (Delivered only)', 'التعيين السريع (رسالة تم التوصيل فقط)'];
export const QUICK_ASSIGN_FLOW_DESC = ['This feature will prevent us from measuring driver performance', 'ستمنعنا هذه الميزة من قياس أداء السائق لمتجرك'];
