import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import { Button } from 'components/kit';
import { ORDER_STATUS } from 'constants/order';
import { useMobile } from 'hooks';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import * as translations from 'constants/translations';
import CancelOrderBody from './CancelOrderBody';
import TitleOrderNumber from '../../TitleOrderNumber';

const CancelOrder = ({ order, shouldDisableActionBtns, open, ordersVariables, close, status: CanceledOrderStatus }) => {
  const { translate } = useContext(localeContext);
  const isMobile = useMobile();
  const { paymentStatus, status, inBetweenTransitions, number, id, deliveryCourier, deliveryStatus } = order || {};
  const { courierId, isInternalDelivery, supportCancellation, courierDetails } = deliveryCourier || {};
  const { displayNameEn, supportNumber } = courierDetails || {};
  const hasCourier = !!courierId && !isInternalDelivery;
  const deliveryOrderStatus = deliveryStatus;
  const isCourierNotDeclinedTheRequest = hasCourier && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED;
  const isCourierSupportCancellation = supportCancellation;
  const orderDeliveryCourierName = displayNameEn;
  const orderDeliverySupportNumber = supportNumber;

  return (
    ![ORDER_STATUS.CANCELED].includes(status) &&
    ![
      ORDER_STATUS.WAITING_FOR_PAYMENT,
      ORDER_STATUS.PAYMENT_FAILED,
      ORDER_STATUS.REDIRECT_URL,
      ORDER_STATUS.PAYMENT_EXPIRED,
      ORDER_STATUS.IFRAME_URL,
    ].includes(paymentStatus) && (
      <Button
        isRounded
        size="md"
        kind="secondaryError"
        className={cx(isMobile && 'mb-4', 'border-red-500 hover:border-red-700 hover:text-red-700')}
        disabled={shouldDisableActionBtns || inBetweenTransitions}
        onClick={() =>
          open({
            title: <TitleOrderNumber isCancel orderNumber={number} />,
            body: (
              <CancelOrderBody
                ordersVariables={ordersVariables}
                status={CanceledOrderStatus}
                isCourierNotDeclinedTheRequest={isCourierNotDeclinedTheRequest}
                isCourierSupportCancellation={isCourierSupportCancellation}
                orderDeliveryCourierName={orderDeliveryCourierName}
                orderDeliverySupportNumber={orderDeliverySupportNumber}
                orderId={id}
                onCancel={close}
                open={open}
              />
            ),
            size: 'max-w-sm',
          })
        }
        data-testid="cancel-order-button"
      >
        {translate(translations.CANCEL_ORDER)}
      </Button>
    )
  );
};

CancelOrder.propTypes = {
  order: PropTypes.shape({
    paymentStatus: PropTypes.string,
    status: PropTypes.string,
    inBetweenTransitions: PropTypes.bool,
    number: PropTypes.string,
    id: PropTypes.number,
    isScheduled: PropTypes.bool,
  }),
  shouldDisableActionBtns: PropTypes.bool,
  open: PropTypes.func,
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  close: PropTypes.func,
  status: PropTypes.string,
};
export default CancelOrder;
