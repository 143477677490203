import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const OrderDetailsFees = ({ order, currency, deliveryType }) => {
  const { lang, translate } = useContext(localeContext);
  const { colors } = useTheme();
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  const {
    subtotal,
    discountedAmount,
    voucherCode,
    voucherAmount,
    subtotalAfterVoucher,
    taxPercentage,
    tax,
    deliveryFee,
    total,
    taxInclusive,
    cashbackAmount,
  } = order || {};
  return (
    <div className="w-full" style={{ direction }}>
      <div className="flex flex-col w-full py-3 border-b border-gray-200 gap-2">
        <div className="flex flex-row w-full justify-between">
          <Typography>{translate(translations.SUBTOTAL)}</Typography>
          <Typography>{`${translate(currency)} ${subtotal.toFixed(currency.decimals)}`}</Typography>
        </div>
        {discountedAmount !== 0 && (
          <div className="flex flex-row w-full justify-between">
            <Typography>{translate(translations.DISCOUNT)}</Typography>
            <Typography>{`- ${translate(currency)} ${discountedAmount.toFixed(currency.decimals)}`}</Typography>
          </div>
        )}
        {voucherCode && (
          <>
            <div className="flex flex-row w-full justify-between">
              <Typography color={colors.red[500]}>{`${translate(
                translations.COUPON_DISCOUNT,
              )} ${voucherCode}`}</Typography>
              <Typography>{`- ${translate(currency)} ${voucherAmount.toFixed(currency.decimals)}`}</Typography>
            </div>
            <div className="flex flex-row w-full justify-between">
              <Typography>{translate(translations.SUBTOTAL_AFTER_VOUCHER)}</Typography>
              <Typography>{`${translate(currency)} ${subtotalAfterVoucher.toFixed(currency.decimals)}`}</Typography>
            </div>
          </>
        )}
        {!taxInclusive && (
          <div className="flex flex-row w-full justify-between">
            <Typography>{`${translate(translations.TAX)} ${taxPercentage}%`}</Typography>
            <Typography>{`${translate(currency)} ${tax.toFixed(currency.decimals)}`}</Typography>
          </div>
        )}
        {deliveryType !== 'pickup' && (
          <div className="flex flex-row w-full justify-between">
            <Typography>{translate(translations.DELIVERY_FEES)}</Typography>
            <Typography>{`${translate(currency)} ${deliveryFee.toFixed(currency.decimals)}`}</Typography>
          </div>
        )}
        {cashbackAmount !== 0 && cashbackAmount > 0 && (
          <div className="flex flex-row w-full justify-between">
            <Typography>{translate(translations.CASHBACK_AMOUNT)}</Typography>
            <Typography>{`${translate(currency)} ${cashbackAmount?.toFixed(currency?.decimals)}`}</Typography>
          </div>
        )}
        {taxInclusive && (
          <div className="flex flex-row  w-full justify-between">
            <Typography>{translate(translations.TAX_ARE_INCLUDED, lang, taxPercentage)}</Typography>
            <Typography>{`${translate(currency)} ${tax.toFixed(currency.decimals)}`}</Typography>
          </div>
        )}
      </div>
      <div className="flex flex-flex w-full justify-between pt-3">
        <Typography variant="heading16">{translate(translations.TOTAL)}</Typography>
        <Typography variant="heading16">{`${translate(currency)} ${total.toFixed(currency.decimals)}`}</Typography>
      </div>
    </div>
  );
};

OrderDetailsFees.propTypes = {
  order: PropTypes.shape({
    subtotal: PropTypes.number.isRequired,
    discountedAmount: PropTypes.number,
    voucherCode: PropTypes.string,
    voucherAmount: PropTypes.number,
    subtotalAfterVoucher: PropTypes.number.isRequired,
    taxPercentage: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    taxInclusive: PropTypes.bool.isRequired,
    deliveryFee: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    verdFees: PropTypes.number.isRequired,
    feastFees: PropTypes.number.isRequired,
    engageFees: PropTypes.number.isRequired,
  }),
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }),
  deliveryType: PropTypes.string,
};
export default OrderDetailsFees;
