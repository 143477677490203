import React from 'react';
import moment from 'moment';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { REFUND_TYPE_ENUM, REFUND_STATUS_ENUM } from './OrderDetails/OrderDetailsStatus/CancelOrderBody/data';

/**
 * @param {Object} order The order.
 * @param {number} defaultProductPrepTime The default preparation time for the order.
 * @return {number} maximum preparation time .
 */
export const calcMaxPrepTime = ({ order, defaultProductPrepTime }) => {
  let maxPrepTime = -1;
  order.orderItems.forEach(element => {
    element?.variant?.prepTime > maxPrepTime && (maxPrepTime = element.variant.prepTime);
    if (element?.variant?.prepTime < 0 || !element?.variant?.prepTime) {
      maxPrepTime = defaultProductPrepTime;
    }
  });
  // There is no prep time per item
  return maxPrepTime;
};

/**
 * @param {text} value The text needed to be copied.
 */
export const copyToClipboard = value => {
  const textArea = document.createElement('textarea');
  textArea.value = value;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
};

/**
 * @param {Object} addressDetails.
 * @return {Array} addressDetails to text array .
 */
export const generateAddressElements = ({
  avenue,
  block,
  building,
  floor,
  street,
  unitNo,
  unitType,
  area,
  lang,
  zoneName,
  areaName,
}) => {
  const translate = (language, word) => (language === 'en' ? word[0] : word[1]);

  const areaAdress = area && `${lang === 'en' ? area.titleEn : area.titleAr}`;
  const blockAddress = block && `${translate(lang, translations.BLOCK)} ${block}`;
  const streetAddress = `${translate(lang, translations.STREET)} ${street}`;
  const floorAddress = floor && `${translate(lang, translations.FLOOR)} ${floor}`;
  const avenueAddress = avenue && `${translate(lang, translations.AVENUE)} ${avenue}`;
  const buildingAddress = building && `${translate(lang, translations.BUILDING)} ${building}`;
  const unit = unitType && unitNo && `${translate(lang, translations[unitType.toUpperCase()])} ${unitNo}`;
  const areaNameAdress = `${areaName}`;
  return [
    !zoneName && areaAdress,
    zoneName && areaName && areaNameAdress,
    blockAddress,
    avenueAddress,
    buildingAddress,
    floorAddress,
    unit,
    streetAddress,
  ];
};

/**
 * @param {Object} order The order.
 * @return {dateTime} new expected at the order can be fulfilled at.
 */
export const calculateNewExpectedAt = order => {
  const minimumCourierDeliveryTime = order.deliveryTime;
  return moment().add(minimumCourierDeliveryTime, 'minutes');
};

/**
 * @param {Object} order the order.
 * @param {Boolean} hasCourier has courier Id or not.
 * @param {DateTime} newExpectedAt new expected at if changed the expected at manually.
 * @return {Boolean} If it has new expected at or not.
 */
export const hasNewExpectedAt = ({ order, hasCourier, newExpectedAt }) => {
  const diffBetweenNewExpectedAndOld = moment(newExpectedAt).diff(moment(order.expectedAt));
  return hasCourier && newExpectedAt && (diffBetweenNewExpectedAndOld > 59999 || diffBetweenNewExpectedAndOld < -59999);
};

/**
 * @param {Object} order the order.
 * @param {Boolean} hasCourier has courier Id or not.
 * @param {DateTime} newExpectedAt new expected at if changed the expected at manually.
 * @return {Boolean} If it the order expected at now valid or not.
 */
export const isExpectedAtValid = ({ order, hasCourier, newExpectedAt }) => {
  if (hasNewExpectedAt({ order, hasCourier, newExpectedAt })) {
    return false;
  }
  // If not courier .. its valid to be accepted
  if (!hasCourier || order.deliveryType === DELIVERY_BUSY_MODE.PICKUP) {
    return true;
  }

  const minimumCourierDeliveryTime = order.deliveryTime;
  return moment().isSameOrBefore(moment(order.expectedAt).subtract(minimumCourierDeliveryTime, 'minutes'), 'minute');
};

/**
 * @param {Object} order the order.
 * @return {Boolean} If the courier has been set to order.
 */
export const isCourierAvailable = ({ deliveryType, restaurantCourierSetting, isDeliveryBusiness }) =>
  deliveryType === DELIVERY_BUSY_MODE.DELIVERY && restaurantCourierSetting && isDeliveryBusiness;

/**
 * @param {Object} needed data for the order.
 * @return {String} URL with the text in msg.
 */
export const generateWhatsAppUrl = ({
  lang,
  currency,
  number,
  total,
  userData,
  addressGMapURL,
  paidThrough,
  zoneName,
}) => {
  const { notes, avenue, block, building, floor, street, unitNo, unitType, area, cityName, areaName } = userData.address
    ? userData.address
    : {};

  const { name, phoneNumber } = userData;
  const translate = translation => {
    const [enTranslation, arTranslation] = translations[translation];
    return lang === 'en' ? enTranslation : arTranslation;
  };
  const { titleEn: currencyTitleEn, titleAr: currencyTitleAr, decimals: currencyDecimals } = currency;
  const currencyTitle = lang === 'en' ? currencyTitleEn : currencyTitleAr;
  const generatedAddress = generateAddressElements({
    avenue,
    block,
    building,
    floor,
    street,
    unitNo,
    unitType,
    area,
    lang,
    zoneName,
    cityName,
    areaName,
  })
    .filter(Boolean)
    .join(', ');

  const customerName = name && `*${translate('CUSTOMER_NAME')}:* ${name}`;
  const customerPhone = phoneNumber && `*${translate('PHONE_NUMBER_ORDER')}:* ${phoneNumber}`;
  const orderNumber = number && `*${translate('ORDER')}:* #${number}`;
  const orderTotal = total && `*${translate('TOTAL')}:* ${total.toFixed(currencyDecimals)} ${currencyTitle}`;
  const address = generatedAddress && `*${translate('DELIVERY_ADDRESS')}:* ${generatedAddress}`;

  const addressLink = addressGMapURL && `*${translate('GET_DIRECTIONS')}:* ${addressGMapURL}`;

  const deliveryNotes = notes && `*${translate('DELIVERY_NOTE')}:* ${notes}`;

  const paymentMethod = paidThrough && `*${translate('PAYMENT_METHOD')}:* ${paidThrough}`;

  const urlText = [
    orderNumber,
    customerName,
    customerPhone,
    orderTotal,
    address,
    deliveryNotes,
    addressLink,
    paymentMethod,
  ]
    .filter(Boolean)
    .join('\n');

  return `https://api.whatsapp.com/send?text=${encodeURIComponent(urlText)}`;
};

/**
 * @param {String} deliveryType the order's delivery type.
 * @return {Boolean} If the Order is a delivery order.
 */
export const isDeliveryOrder = deliveryType => deliveryType === DELIVERY_BUSY_MODE.DELIVERY;

/**
 *
 * @param {String} typeOfRefund the order's refund type
 * @returns {String} the exact refund statement based on the passed refund type
 */
export const RefundDisclamerText = ({ typeOfRefund }) => {
  switch (typeOfRefund) {
    case REFUND_TYPE_ENUM.ORDERFAST_WALLET:
      return <Text value={translations.ORDERFAST_WALLET_DISCLAMER} className="px-3 text-sm" />;
    case REFUND_TYPE_ENUM.WALLET_AND_CREDIT_CARD:
      return (
        <div className="flex flex-col">
          <Text value={translations.ORDERFAST_WALLET_DISCLAMER} className="px-3 text-xs" />
          <Text value={translations.REFUND_STATMENT} className="px-3 text-xs" />
        </div>
      );
    default:
      return <Text value={translations.REFUND_STATMENT} className="px-3 text-sm" />;
  }
};

/**
 *
 * @param {String} status the order's refund status
 * @returns {String} the refund status text based on the passed refund status
 */
export const getRefundStatusText = status => {
  switch (status) {
    case REFUND_STATUS_ENUM.FAILED:
      return translations.PAYMENT_TRANSACTION_FAILED;
    case REFUND_STATUS_ENUM.EXPIRED:
      return translations.EXPIRED;
    case REFUND_STATUS_ENUM.SUCCEEDED:
      return translations.REFUNDED_ORDERS;
    default:
      return translations.PENDING_ORDERS;
  }
};

/**
 *
 * @param {String} status the order's refund status
 * @returns {String} the refund status color based on the passed refund status
 */
export const getRefundStatusColor = status => {
  switch (status) {
    case REFUND_STATUS_ENUM.FAILED:
    case REFUND_STATUS_ENUM.EXPIRED:
      return 'bg-zyda-red-600';
    case REFUND_STATUS_ENUM.SUCCEEDED:
      return 'bg-zyda-green-600';
    default:
      return 'bg-zyda-orange-600';
  }
};
