import React, { useContext } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'style.css';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';
import { ClockIcon } from '@heroicons/react/solid';
import { PaidMethodsIcon } from 'utils/imagesResolver';
import { ORDER_STATUS } from 'constants/order';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import CheckBox from 'components/kit/CheckBox';
import OrderDate from './OrderDate';
import StatusOrder from './StatusOrder';
import PaymentStatusOrder from './PaymentStatusOrder';
import { ManualOrderMark, BulkChangeTip, getDeliveryTypeText, getOrderIcon } from './utils/orderSectionUtils';
import { DELIVERY_ENUMS } from '../constants';

const OrderItemMobile = ({
  id,
  paidThrough,
  number,
  deliveryType,
  createdAt,
  branchName,
  branchData,
  total,
  isManualOrder,
  customerName,
  isSelected,
  updateOrderSelectedState = () => {
    // Do Nothing
  },
  isBulkChange = false,
  isOrderUpdating,
  bulkChangeStatus,
  isScheduled,
  expectedAt,
  timeSlot,
  isFaded,
  onClick,
  status,
  showOrderStatus,
  paymentStatus,
  gift,
  showScheduled,
  deliveryZone,
  areaNameEn,
  areaNameAr,
}) => {
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { currency } = selectedStore;
  const fromDate = timeSlot && timeSlot.split(',')[0];
  const toDate = timeSlot && timeSlot.split(',')[1];
  const theFromDate =
    fromDate &&
    moment(fromDate)
      .tz(selectedStore.timeZone)
      .locale(lang === 'ar' ? 'ar' : 'en-gb')
      .format('h:mm A');
  const theToDate =
    toDate &&
    moment(toDate)
      .tz(selectedStore.timeZone)
      .locale(lang === 'ar' ? 'ar' : 'en-gb')
      .format('h:mm A');
  const theTimeSlot = theFromDate && theToDate && `${theFromDate} - ${theToDate}`;
  const areaName = lang === 'en' ? areaNameEn : areaNameAr || areaNameEn;

  return (
    <>
      {isOrderUpdating && <BulkChangeTip status={bulkChangeStatus} />}
      <div
        role="presentation"
        className={cx(
          'flex flex-col md:flex-wrap bg-gray-100 items-center cursor-pointer py-4',
          isSelected && 'bg-primary-base/10',
          lang === 'ar' ? 'md:flex-row-reverse' : 'md:flex-row',
          (isFaded || isOrderUpdating) && 'opacity-25',
        )}
        onClick={onClick}
      >
        <div
          className={cx(
            'flex justify-between w-full border-b md:border-r md:border-b-0 border-gray-300 px-4 pb-4',
            lang === 'ar' && 'flex-row-reverse',
          )}
        >
          <div className="flex items-center">
            {isBulkChange && !isOrderUpdating && (
              <CheckBox value={isSelected} lang={lang} onChange={value => updateOrderSelectedState(id, value)} />
            )}
            {isOrderUpdating && (
              <ClockIcon className={cx('w-5 h-5 text-zyda-yellow-500', lang === 'ar' ? 'ml-3' : 'mr-3')} />
            )}
            <OrderDate timeZone={selectedStore.timeZone} date={createdAt} />
          </div>
          <div className={cx('flex items-center justify-start', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}>
            {isManualOrder && <ManualOrderMark lang={lang} />}
            <div className="h-5">
              <PaidMethodsIcon paidThrough={paidThrough} width="1.25rem" height="1.25rem" />
            </div>
            <div className={cx(lang === 'ar' ? 'text-right' : 'text-left', 'text-xs text-gray-700')}>
              <span style={{ direction }} className="block">
                {total.toFixed(currency.decimals)} <Text value={currency} className="inline" />
              </span>
            </div>
          </div>
        </div>
        <div
          className={cx(
            'mt-3 px-4 w-full flex-1 flex',
            lang === 'ar' && 'flex-row-reverse',
            'flex-wrap items-center select-none',
          )}
        >
          <div
            className={cx('w-full flex items-start justify-between', lang === 'ar' ? 'flex-row-reverse' : 'flex-row ')}
          >
            <div className={cx('w-2/3', lang === 'ar' ? 'text-right' : 'text-left')}>
              <div className="w-full flex items-center">
                <span className="block text-gray-700 text-xs">
                  #<span className="select-all cursor-text">{number}</span>
                </span>
                <div
                  className={cx(
                    'flex items-center md:w-1/5',
                    lang === 'ar' ? 'mr-2 flex-row-reverse ' : 'flex-row ml-2',
                  )}
                >
                  {status !== ORDER_STATUS.PENDING ? (
                    <StatusOrder status={status} />
                  ) : (
                    <PaymentStatusOrder paymentStatus={paymentStatus} />
                  )}
                </div>
              </div>
              <span className="block text-sm">{customerName}</span>
            </div>

            <div className={cx('w-1/2 flex items-center justify-end', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}>
              <div className="flex flex-col justify-start align-end">
                <div className={cx('flex justify-end', lang === 'ar' && 'flex-row-reverse')}>
                  <div
                    className={cx(
                      'h-5',
                      deliveryType === DELIVERY_BUSY_MODE.BEACH && (lang === 'ar' ? 'ml-1' : 'mr-1'),
                      gift && 'px-1',
                    )}
                  >
                    {getOrderIcon(deliveryType, gift)}
                  </div>
                  <span className="block text-gray-700 text-xs">{getDeliveryTypeText(deliveryType)}</span>
                </div>
                <span className={cx('block', lang === 'ar' ? 'text-left' : 'text-right')}>
                  {branchData ? translate(branchData) : branchName}

                  {// checking if store use new delivery zone system (useDeliveryzonesMs)
                  // NOTE later we will migrate all stores to new system
                  deliveryType === DELIVERY_ENUMS.DELIVERY_SMALL &&
                    ` - ${deliveryZone?.zoneName ? deliveryZone.zoneName : areaName}`}
                </span>
              </div>
            </div>
          </div>
          <div
            className={cx(
              'w-full flex items-center justify-between mt-4',
              lang === 'ar' ? 'flex-row-reverse' : 'flex-row ',
            )}
          >
            {showScheduled && (
              <div
                className={cx(
                  'w-1/2 flex items-center justify-start',
                  lang === 'ar' ? ' flex-row-reverse' : 'flex-row',
                )}
              >
                <div className={cx(lang === 'ar' ? 'text-right' : 'text-left')}>
                  <span className="block text-gray-700 text-xs">
                    {isScheduled ? <Text value={translations.SCHEDULED} /> : <Text value={translations.ASAP} />}
                  </span>
                  <span className="block">
                    {theTimeSlot ? (
                      <>
                        <div>{theTimeSlot}, </div>
                        <div>
                          {moment(expectedAt)
                            .tz(selectedStore.timeZone)
                            .locale(lang === 'ar' ? 'ar' : 'en-gb')
                            .format('DD MMM YYYY')}
                        </div>
                      </>
                    ) : expectedAt ? (
                      moment(expectedAt)
                        .tz(selectedStore.timeZone)
                        .locale(lang === 'ar' ? 'ar' : 'en-gb')
                        .format('h:mm A, DD MMM YYYY')
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </div>
            )}
            {showOrderStatus &&
              (status !== ORDER_STATUS.PENDING ? (
                <div
                  className={cx('flex items-center justify-start', lang === 'ar' ? ' flex-row-reverse' : 'flex-row')}
                >
                  <StatusOrder status={status} lang={lang} />
                </div>
              ) : (
                <div className={cx('flex items-center justify-end', lang === 'ar' ? ' flex-row-reverse' : 'flex-row')}>
                  <PaymentStatusOrder paymentStatus={paymentStatus} lang={lang} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

OrderItemMobile.propTypes = {
  id: PropTypes.number,
  paidThrough: PropTypes.string,
  number: PropTypes.number,
  updateOrderSelectedState: PropTypes.func,
  isBulkChange: PropTypes.bool,
  isOrderUpdating: PropTypes.bool,
  isSelected: PropTypes.bool,
  bulkChangeStatus: PropTypes.string,
  customerName: PropTypes.string,
  areaNameEn: PropTypes.string,
  areaNameAr: PropTypes.string,
  deliveryType: PropTypes.string,
  createdAt: PropTypes.string,
  isManualOrder: PropTypes.bool,
  branchName: PropTypes.string,
  branchData: PropTypes.shape({
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    titleAr: PropTypes.string,
    titleEn: PropTypes.string,
  }),
  total: PropTypes.number,
  isScheduled: PropTypes.bool,
  expectedAt: PropTypes.string,
  timeSlot: PropTypes.string,
  isFaded: PropTypes.bool,
  onClick: PropTypes.func,
  status: PropTypes.string,
  showOrderStatus: PropTypes.bool,
  paymentStatus: PropTypes.string,
  gift: PropTypes.string,
  showScheduled: PropTypes.bool,
  deliveryZone: PropTypes.shape({
    zoneName: PropTypes.string,
  }),
};

export default OrderItemMobile;
