import React, { useContext, Fragment } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import { CANCELLATION_REASON } from 'constants/translations';
import { CANCEL_OPTIONS, CANCEL_REASON } from 'constants/cancel';
import OrderStatus from '../OrderStatus';

const OrderDetailsStatusHistory = ({ order, selected }) => {
  const { lang, translate } = useContext(localeContext);
  const { stateHistories, cancellationReason, otherReason } = order || {};
  const isArabic = lang === 'ar';

  const cancelTypeCheck = Number.isInteger(cancellationReason);

  const cancelledFor = () => {
    if (!cancelTypeCheck) {
      return '';
    }
    if (cancellationReason !== CANCEL_REASON.OTHER) {
      const reason = CANCEL_OPTIONS.filter(option => option.value === cancellationReason)[0];
      return reason.title;
    }
    return otherReason;
  };

  const reasonToCancel = cancelledFor();
  return (
    <>
      <div className="w-full border-t max-h-72 overflow-y-scroll border-gray-200 mt-2">
        {stateHistories.map((item, i) => {
          const { state, createdAt, actionBy } = item || {};
          const stateItemIndex = `${state},${createdAt},${actionBy},${i}`;
          return (
            <div
              key={stateItemIndex}
              className={cx(
                'flex justify-between items-center w-full pt-4 pb-3 border-b border-gray-200 last:border-0',
              )}
            >
              <OrderStatus status={item} />
              <span className="block text-zyda-grey-500 text-xs">
                {moment(createdAt)
                  .tz(selected.timeZone)
                  .locale(isArabic ? 'ar' : 'en-gb')
                  .format('h:mm A, DD MMM YYYY')}
              </span>
            </div>
          );
        })}
      </div>
      {cancelTypeCheck && (
        <div className="pt-2 mt-2 border-t border-gray-200">
          <Typography variant="heading16">{translate(CANCELLATION_REASON)}</Typography>
          <Typography variant="body14">{reasonToCancel}</Typography>
        </div>
      )}
    </>
  );
};

OrderDetailsStatusHistory.propTypes = {
  order: PropTypes.shape({
    stateHistories: PropTypes.arrayOf(
      PropTypes.shape({
        actionBy: PropTypes.string,
        createdAt: PropTypes.string,
        state: PropTypes.string,
      }),
    ),
    status: PropTypes.string,
    isScheduled: PropTypes.bool,
    firingTime: PropTypes.string,
    cancellationReason: PropTypes.string,
    otherReason: PropTypes.string,
  }),
  selected: PropTypes.shape({ timeZone: PropTypes.string }),
};
export default OrderDetailsStatusHistory;
