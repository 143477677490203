import { useContext, useState } from 'react';

import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { useAssignDriverToOrder } from 'service/hooks';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import updateDeliveryCourierCache from 'utils/updatingCache/updateDeliveryCourierCache';
import { useSelectedStore } from 'hooks/index';

const useHandleAssignUnAssignCourierToOrder = ({ order, mutateFetchVerdDrivers }) => {
  const { user } = useContext(userContext);
  const notifications = useContext(notificationsContext);

  const [isCourierInfoLoading, setIsCourierInfoLoading] = useState(false);

  const selectedStoreId = useSelectedStore();
  const assignDriverToOrder = useAssignDriverToOrder();

  const handleUpdateCourierInCache = assignedCourier => {
    const updatedOrder = {
      ...order,
      driverId: null,
      deliveryCourierId: assignedCourier ? assignedCourier?.courierId : null,
      deliveryStatus: assignedCourier ? ORDER_RIDER_STATUS.PENDING : ORDER_RIDER_STATUS.CANCELED,
      deliveryCourier: {
        ...order.deliveryCourier,
        courierId: assignedCourier ? Number(assignedCourier?.courierId) : '',
        isInternalDelivery: assignedCourier && null,
      },
    };
    const orderVariables = {
      orderId: order.id,
      storeId: selectedStoreId,
    };
    updateDeliveryCourierCache(orderVariables, updatedOrder);
  };

  const handleAssignCourierToOrder = async (assignedCourier, notifyUser, newExpectedAt) => {
    setIsCourierInfoLoading(true);
    const response = await assignDriverToOrder({
      driverId: -1,
      courierId: assignedCourier.courierId,
      orderNumber: order.number,
      userId: user?.id,
      ...(notifyUser && { notifyUser }),
      ...(newExpectedAt && { scheduledDeliveryAt: newExpectedAt }),
    });
    if (response.errors?.length) {
      response.errors.map(error => notifications.show(error.message, 'error'));
    } else {
      await mutateFetchVerdDrivers();
      handleUpdateCourierInCache(assignedCourier);
    }
    setIsCourierInfoLoading(false);
  };

  const handleUnAssignCourierToOrder = async () => {
    setIsCourierInfoLoading(true);
    const response = await assignDriverToOrder({ courierId: -1, orderNumber: order.number, userId: user?.id });
    if (response.errors?.length) {
      response.errors.map(error => notifications.show(error.message, 'error'));
    } else {
      await mutateFetchVerdDrivers();
      handleUpdateCourierInCache();
    }
    setIsCourierInfoLoading(false);
  };

  return { handleAssignCourierToOrder, handleUnAssignCourierToOrder, isCourierInfoLoading };
};

export default useHandleAssignUnAssignCourierToOrder;
